const CardData21 = [
  {
    title: 'Guns & Roses',
    description:
      "Guns N' Roses es una banda estadounidense de hard rock formada en Hollywood en la zona de Sunset Strip, alrededor de Santa Mónica, en el área metropolitana de Los Ángeles, California en 1985. El grupo fue fundado por el vocalista Axl Rose y el guitarrista Izzy Stradlin.",
    videourl:
      'https://youtube.com/playlist?list=PLOTK54q5K4INNXaHKtmXYr6J7CajWjqeJ',
    price: 36,
    coverImg: 'gnr.gif',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.gunsnroses.com/',
    youtube: 'https://www.youtube.com/channel/UCIak6JLVOjqhStxrL1Lcytw',
    facebook: 'https://www.facebook.com/gunsnroses/',
    instagram: 'https://www.instagram.com/gunsnroses/',
    twitter: 'https://twitter.com/gunsnroses',
    spotify: 'https://open.spotify.com/artist/3qm84nBOXUEQ2vnTfUTTFC',
    deezer: 'https://www.deezer.com/mx/artist/663',
    apple: 'https://music.apple.com/mx/artist/guns-n-roses/106621',
    soundcloud: 'https://soundcloud.com/guns-n-roses-official',
    tidal: 'https://listen.tidal.com/artist/32944',
    wiki: 'https://es.wikipedia.org/wiki/Guns_N%27_Roses',
    ticketmaster:
      'https://www.ticketmaster.com/guns-n-roses-tickets/artist/735218',
    tiktok: 'https://www.tiktok.com/@gunsnroses?lang=es',
    napster: 'https://music.amazon.com/artists/B000V63WIA/guns-n-roses',
  },
  { 
    title: 'Metallica',
    description:
      'Metallica es una banda estadounidense de thrash metal originaria de Los Ángeles, pero con base en San Francisco desde febrero de 1983. Fue fundada en 1981 en Los Ángeles por Lars Ulrich y James Hetfield, a los que se les unirían Dave Mustaine y Ron McGovney. Estos dos músicos fueron después sustituidos por el guitarrista Kirk Hammett y el bajista Cliff Burton respectivamente, Dave Mustaine fue despedido un año después de ingresar en la banda debido a su excesiva adicción al alcohol y su actitud violenta, siendo sustituido por Kirk Hammett.',
    videourl: 'https://youtube.com/playlist?list=PL2D4A44B959D87893',
    price: 36,
    coverImg: 'Metallica.gif',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://metallica.com/',
    youtube: 'https://www.youtube.com/channel/UCbulh9WdLtEXiooRcYK7SWw',
    facebook: 'https://www.facebook.com/Metallica/',
    instagram: 'https://www.instagram.com/metallica/',
    twitter: 'https://twitter.com/metallica',
    spotify: 'https://open.spotify.com/search/Metallica',
    deezer: 'https://www.deezer.com/mx/artist/119',
    apple: 'https://music.apple.com/mx/artist/metallica/3996865',
    soundcloud: 'https://soundcloud.com/officialmetallica',
    tidal: 'https://listen.tidal.com/artist/8405',
    wiki: 'https://es.wikipedia.org/wiki/Metallica',
    ticketmaster:
      'https://www.ticketmaster.com/metallica-tickets/artist/735647',
    tiktok: 'https://www.tiktok.com/@metallica?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJPIHU/metallica',
  },
  {
    title: 'Throwback Classic Rock Mix',
    videourl:
      'https://youtube.com/playlist?list=PLLSIJvEXKz1wvLsCG0Yrvh6vu_Mf8c34K&si=_w8t0tTG7q82AJ4x',
    price: 80,
    coverImg: 'classicrock.jpeg',
    category: 'AI',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    
  },
  {
    title: 'Mötley Crüe',
    description:
      'Mötley Crüe es una banda estadounidense de Glam metal/Heavy metal formada en Los Ángeles, California, en 1981 por el bajista Nikki Sixx y el baterista Tommy Lee a los que más tarde se les unirían el guitarrista Mick Mars y el vocalista Vince Neil. Con 25 millones de copias vendidas sólo en Estados Unidos y más de 100 millones en todo el mundo, es considerada como una de las bandas más importantes de la escena «glam» tanto en los años 1980 como en la actualidad y como una de las más influyentes en la escena mundial. La formación de la banda ha tenido varios cambios durante su carrera. Vince Neil salió en 1992 y fue reemplazado por John Corabi, aunque volvió en 1997. Tommy Lee salió en 1999 y fue reemplazado por Randy Castillo, quien murió en 2002 y fue sustituido por Samantha Maloney hasta 2004, cuando volvió Tommy Lee. Solo grabaron dos álbumes en que faltara alguno de los miembros originales.',
    videourl: 'https://youtube.com/playlist?list=UUq2g_Vcu3J1OwNRmdTNoxYA',
    price: 36,
    coverImg: 'motleycrue.gif',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.motley.com/',
    youtube: 'https://www.youtube.com/channel/UCq2g_Vcu3J1OwNRmdTNoxYA',
    facebook: 'https://www.facebook.com/MotleyCrue',
    instagram: 'https://www.instagram.com/motleycrue/',
    twitter: 'https://twitter.com/MotleyCrue',
    spotify: 'https://open.spotify.com/artist/0cc6vw3VN8YlIcvr1v7tBL',
    deezer: 'https://www.deezer.com/mx/artist/531',
    apple: 'https://music.apple.com/mx/artist/m%C3%B6tley-cr%C3%BCe/284113',
    soundcloud: 'https://soundcloud.com/motley-crue',
    tidal: 'https://listen.tidal.com/artist/3555613',
    wiki: 'https://es.wikipedia.org/wiki/M%C3%B6tley_Cr%C3%BCe',
    ticketmaster:
      'https://www.ticketmaster.com/motley-crue-tickets/artist/910417',
    tiktok: 'https://www.tiktok.com/@motleycrue?lang=es',
    napster: 'https://music.amazon.com/artists/B00137CPYI/m%C3%B6tley-cr%C3%BCe',
  },
  {
    title: 'Def Leppard',
    description:
      "Def Leppard es una banda británica de rock originaria de Sheffield, Reino Unido, que dio inicio a su carrera a finales de los años setenta, alcanzando gran éxito mundial en la década de los ochenta, acercando al heavy metal a las emisoras de radio y al gran público en general, gracias a una mezcla rara de hard rock melódico con un gran trabajo vocal. Junto a otras bandas como Bon Jovi, Van Halen, Mötley Crüe, Scorpions, Metallica, Guns N' Roses, Iron Maiden, etcétera, es reconocida como una de las bandas de heavy metal superventas de los años 1980.​Junto a grupos como Iron Maiden o Saxon fueron una de las bandas de cabecera de la New Wave of British Heavy Metal. Def Leppard ha vendido más de 100 millones de álbumes en todo el mundo",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lbYSJCrRycROy6EBefq9Bbaafi2XFSPBc',
    price: 36,
    coverImg: 'defleppard.gif',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.defleppard.com/',
    merchsiteurl: 'https://www.leppardvault.com/',
    youtube: 'https://www.youtube.com/channel/UCZjBqZjGsmI5OAVsLSroaWQ',
    facebook: 'https://www.facebook.com/defleppard/',
    instagram: 'https://www.instagram.com/defleppard/',
    twitter: 'https://twitter.com/defleppard',
    spotify: 'https://open.spotify.com/artist/6H1RjVyNruCmrBEWRbD0VZ',
    deezer: 'https://www.deezer.com/mx/artist/2557',
    apple: 'https://music.apple.com/mx/artist/def-leppard/117554',
    soundcloud: 'https://soundcloud.com/def-leppard-official',
    tidal: 'https://listen.tidal.com/artist/948',
    wiki: 'https://es.wikipedia.org/wiki/Def_Leppard',
    ticketmaster:
      'https://www.ticketmaster.com/def-leppard-tickets/artist/734898',
    tiktok: 'https://www.tiktok.com/@defleppard?lang=es',
    napster: 'https://music.amazon.com/artists/B001368ZNY/def-leppard',
  },
  {
    title: 'Van Halen',
    description:
      'Van Halen fue una banda de hard rock estadounidense, inicialmente nombrada Mammoth por los fundadores de la banda, formada en 1972 en Pasadena, California y disuelta en 2020. Luego se unirían al grupo el cantante David Lee Roth y el bajista Michael Anthony. Van Halen alcanzó la fama rápidamente con su álbum debut homónimo en 1978, ampliamente considerado como un hit del rock, alcanzando ventas en Estados Unidos superiores a las 10 millones de copias y siendo calificado en agosto de 1996 como disco de diamante.',
    videourl: 'https://youtube.com/playlist?list=PL69E7CB8F976BD5DE',
    price: 36,
    coverImg: 'vanhalen.gif',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://van-halen.com/',
    youtube: 'https://www.youtube.com/channel/UCfOTTeREY6_1LSW5M0bdG7g',
    facebook: 'https://www.facebook.com/VanHalen',
    instagram: 'https://www.instagram.com/vanhalen/',
    twitter: 'https://twitter.com/VanHalen',
    spotify: 'https://open.spotify.com/artist/2cnMpRsOVqtPMfq7YiFE6K',
    deezer: 'https://www.deezer.com/mx/artist/1168',
    apple: 'https://music.apple.com/mx/artist/van-halen/166529',
    soundcloud: 'https://soundcloud.com/vanhalenofficial',
    tidal: 'https://listen.tidal.com/artist/13462',
    wiki: 'https://es.wikipedia.org/wiki/Van_Halen',
    ticketmaster: 'https://listen.tidal.com/artist/13462',
    tiktok: 'https://es.wikipedia.org/wiki/Van_Halen',
    napster: 'https://music.amazon.com/artists/B000UQPR7A/van-halen',
  },
  {
    title: 'Iron Maiden',
    description:
      'Iron Maiden es una banda británica de heavy metal fundada en 1975 por el bajista Steve Harris. Es considerada una de las bandas de metal más importantes de todos los tiempos. Ha vendido más de 100 millones de discos en todo el mundo, a pesar de haber contado con poco apoyo de la radio y la televisión comercial durante la mayor parte de su carrera. Sin embargo, la banda basó su éxito en llegar directamente a los aficionados, grabando discos de alta calidad y realizando destacadas actuaciones en vivo..',
    videourl: 'https://youtube.com/playlist?list=PL171A6536C5FDFD95',
    price: 36,
    coverImg: 'ironmaiden.gif',
    category: 'Rock Metal',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.ironmaiden.com/',
    youtube: 'https://www.youtube.com/user/ironmaiden',
    facebook: 'https://www.facebook.com/ironmaiden/',
    instagram: 'https://www.instagram.com/ironmaiden/',
    twitter: 'https://twitter.com/IronMaiden',
    spotify: 'https://open.spotify.com/artist/6mdiAmATAx73kdxrNrnlao',
    deezer: 'https://www.deezer.com/en/artist/931',
    apple: 'https://music.apple.com/us/artist/iron-maiden/546381',
    soundcloud: 'https://soundcloud.com/guns-n-roses-official',
    tidal: 'https://listen.tidal.com/artist/32944',
    wiki: 'https://es.wikipedia.org/wiki/Iron_Maiden',
    ticketmaster:
      'https://www.ticketmaster.com/iron-maiden-tickets/artist/735341',
    tiktok: 'https://www.tiktok.com/@ironmaiden',
    napster: 'https://music.amazon.com.mx/artists/B000QK9EZQ/iron-maiden',
  },
  {
    title: 'Kiss',
    description:
      'Kiss (estilizado KISS) es una banda estadounidense de rock formada en Nueva York en enero de 1973 por el bajista Gene Simmons y el guitarrista Paul Stanley, a los que más tarde se unirían el batería Peter Criss y el guitarrista Ace Frehley.2​ Conocido por su maquillaje facial y su extravagante vestuario, el grupo se dio a conocer al público a mediados de los años 1970 gracias a sus actuaciones en directo, en las que incluían pirotecnia, llamaradas, cañones, baterías elevadoras, guitarras con humo y sangre falsa. Si se tienen en cuenta los álbumes en solitario de 1978, Kiss ha conseguido treinta discos de oro de la RIAA y es la banda estadounidense que ha recibido un mayor número de estas certificaciones..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_koz9_upHnaNQGikkDAE4p1eiSt34WY9-0',
    price: '$50',
    coverImg: 'kiss.gif',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.kissonline.com/end-of-the-road-world-tour',
    youtube: 'https://www.youtube.com/%20rush',
    facebook: 'https://www.facebook.com/KISS',
    instagram: 'https://www.instagram.com/kissonline/',
    twitter: 'https://twitter.com/kiss',
    spotify: 'https://open.spotify.com/album/0NjpmoajQlllfKH9FaNliD',
    deezer: 'https://www.deezer.com/mx/artist/67',
    apple: 'https://music.apple.com/mx/artist/kiss/118108',
    soundcloud: 'https://soundcloud.com/kiss-official',
    tidal: 'https://listen.tidal.com/artist/27478',
    wiki: 'https://en.wikipedia.org/wiki/Kiss_(band)',
    ticketmaster: 'https://www.ticketmaster.com/kiss-tickets/artist/735455',
    tiktok: 'https://www.tiktok.com/@officialkiss?lang=es',
    napster: 'https://music.amazon.com/artists/B000VZLIOQ/kiss',
  },
  {
    title: 'Ratt',
    description:
      'Ratt es una banda estadounidense de Heavy metal/Glam metal/Thrash metal, formada en San Diego en el año 1978. Tuvo un gran éxito comercial durante la década de 1980. Los temas más notables de este grupo son sus sencillos «Round and Round», «Wanted Man», «Lay it Down», «You re in Love», «Back for More», «Shame, Shame, Shame», «Im Insane», «Symphaty With The Devil», «Imvasion Your Privacity», «Face Pollution», «Demonic Refusal», «Trail Of Tears», «Strife», «Im a Rat», «Slip of The Lip», «What you Give is What You Get», «Detonator», «Kill City to City», «Top Secret», «Necrhofhilia», «Infestation», «Reach in The Sky» «Between the Eyes» entre otros.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lh1oVxDTtD8bjzVI5-BKYYvHdl1zdzE5w&si=aiBVZfKiVpy5-9wd',
    price: '$50',
    coverImg: 'ratt.jpg',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.therattpack.com/',
    youtube: 'https://www.youtube.com/channel/UCEe4U0yIOGi-N2PrHUtnf3g',
    facebook: 'https://www.facebook.com/therattpack/',
    instagram: 'https://www.instagram.com/ratt/',
    twitter: 'https://x.com/theRATTpack',
    spotify: 'https://open.spotify.com/artist/3tufWJzpCiAGleBt5TkmTn',
    deezer: 'https://www.deezer.com/mx/artist/7738',
    apple: 'https://music.apple.com/mx/artist/ratt/562965',
    soundcloud: 'https://soundcloud.com/ratt',
    tidal: 'https://listen.tidal.com/artist/13369',
    wiki: 'https://es.wikipedia.org/wiki/Ratt',
    ticketmaster:'https://www.ticketmaster.com/ratt-tickets/artist/735941',
    tiktok: 'https://www.tiktok.com/search/video?lang=es&q=Ratt&t=1726114036619',
    napster: 'https://music.amazon.com/artists/B000QJIET4/ratt',
  },
  {
    title: 'Alice Cooper',
    description:
      'Alice Cooper (nacido Vincent Damon Furnier; Detroit, Míchigan; 4 de febrero de 1948) es un cantante de hard rock y heavy metal estadounidense. Alice Cooper fue inicialmente el nombre de una banda de rock and roll en la que Furnier era el vocalista,​ pionero del género conocido como shock rock. Surgido en 1964 en Arizona, Estados Unidos, logró su mayor auge durante los años setenta y alcanzó el éxito comercial con el álbum Billion Dollar Babies de 1973. Posteriormente, Furnier adoptó el nombre de su anterior banda e inició una carrera como solista, convirtiéndose en un importante icono del rock que influiría en diferentes géneros, como el heavy metal,​ el hard rock, el punk​ y el rock gótico.',
    videourl: 'https://youtube.com/playlist?list=OLAK5uy_l9bKx5u5T4Ywz0P3mOhkuhhJN9-YgD-lM&si=rka39GCpuFzw-KEl',
    price: 36,
    coverImg: 'alicecooper.jpg',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://alicecooper.com/',
    youtube: 'https://www.youtube.com/@AliceCooper',
    facebook: 'https://www.facebook.com/AliceCooper',
    instagram: 'https://www.instagram.com/alicecooper',
    twitter: 'https://x.com/alicecooper',
    spotify: 'https://open.spotify.com/artist/2cnMpRsOVqtPMfq7YiFE6K',
    deezer: 'https://www.deezer.com/mx/artist/1168',
    apple: 'https://music.apple.com/mx/artist/van-halen/166529',
    soundcloud: 'https://soundcloud.com/vanhalenofficial',
    tidal: 'https://listen.tidal.com/artist/13462',
    wiki: 'https://es.wikipedia.org/wiki/Alice_Cooper',
    ticketmaster: 'https://listen.tidal.com/artist/13462',
    tiktok: 'tiktok.com/@alicecooperofficial',
    napster: 'https://music.amazon.com/artists/B000QJNR1O/alice-cooper',
  },
  {
    title: 'Aerosmith',
    description:
      'Aerosmith es una banda estadounidense de rock ​ formada en Boston en 1970. Está integrada por Steven Tyler (cantante), Joe Perry (guitarra), Tom Hamilton (bajo), Joey Kramer (batería) y Brad Whitford (guitarra). Conocidos también como Los chicos malos de Boston o Toxic Twins apelativo por el que se conoce a sus dos líderes por su histórica adicción a las drogas,​ su sonido, agresivamente rítmico, tiene raíces en el blues, y contribuyó a establecer el sonido del hard rock y pop rock entre los años 1981 y 1990.',
    videourl: 'https://youtube.com/playlist?list=PLEGyvPj66TeqE8zI1JGV6rxtDFxf0qWYq&si=WyklZY3HoSjxjJJp',
    price: 36,
    coverImg: 'aerosmith.jpg',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'hhttps://www.aerosmith.com/',
    youtube: 'https://www.youtube.com/channel/UCBxdHQVOaZhUOIj_3gt2FYw',
    facebook: 'https://www.aerosmith.com/',
    instagram: 'https://www.instagram.com/Aerosmith',
    twitter: 'https://x.com/Aerosmith',
    spotify: 'https://open.spotify.com/artist/7Ey4PD4MYsKc5I2dolUwbH?si=nwsj3Nu1T0-NQk9WZt8a9',
    deezer: '',
    apple: 'https://music.apple.com/mx/artist/van-halen/166529',
    soundcloud: 'https://soundcloud.com/aerosmith',
    tidal: 'https://listen.tidal.com/artist/950',
    wiki: 'https://es.wikipedia.org/wiki/Aerosmith',
    ticketmaster: 'ticketmaster.com/aerosmith-tickets/artist/734396',
    tiktok: 'https://www.tiktok.com/@aerosmith',
    napster: 'https://music.amazon.com/artists/B000VRIQO4/aerosmith',
  },
  {
    title: 'AC-DC',
    description:
      'AC/DC es una banda de hard rock británica-australiana, formada en 1973 en Australia por los hermanos escoceses Malcolm Young y Angus Young.Sus álbumes se han vendido en un total estimado de 200 millones de copias, embarcándose en giras multitudinarias por todo el mundo y sus éxitos han musicalizado varias producciones cinematográficas sobresalientes. Son famosas sus actuaciones en vivo, resultando vibrantes y exultantes espectáculos de primer orden. Mucho de ello se debe al extravagante estilo de su guitarrista principal y símbolo visual, Angus Young, quien asume el rol de guitarrista principal durante los conciertos, gracias a sus dinámicos y adrenalínicos despliegues escénicos uniformado de colegial callejero.',
    videourl:
      'http://youtube.com/playlist?list=PLx1MDbsLNfVReIJ-ljqWW4vACm6alJgbF',
    price: 80,
    coverImg: 'acdc.gif',
    category: "Rock Metal",
    location: 'USA',
    locationImg: 'australia.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.acdc.com/',
    youtube: 'https://www.youtube.com/channel/UCB0JSO6d5ysH2Mmqz5I9rIw',
    facebook: 'https://www.facebook.com/acdc/',
    instagram: 'https://www.instagram.com/acdc/',
    twitter: 'https://twitter.com/acdc',
    spotify: 'https://open.spotify.com/artist/711MCceyCBcFnzjGY4Q7Un',
    deezer: 'https://www.deezer.com/mx/artist/115',
    apple: 'https://music.apple.com/mx/artist/ac-dc/5040714',
    soundcloud: 'https://soundcloud.com/acdcofficial',
    tidal: 'https://listen.tidal.com/artist/945',
    wiki: 'https://es.wikipedia.org/wiki/AC/DC',
    ticketmaster: 'https://www.ticketmaster.com/acdc-tickets/artist/1170951',
    tiktok: 'https://www.tiktok.com/@acdc?lang=es',
    napster: 'https://music.amazon.com/artists/B00136B4M8/ac-dc',
  },
  {
    title: 'Megadeth',
    description:
      'Megadeth es un grupo musical estadounidense de heavy metal y thrash metal, formado en Los Ángeles, California. Fue creada en 1983 por Dave Mustaine (vocalista, guitarrista y compositor principal), después de que fuera expulsado de Metallica, donde ocupaba el puesto de guitarrista principal. Es comúnmente mencionada como una de los cuatro grandes del thrash metal, junto a Metallica, Slayer y Anthrax.Megadeth ha tenido varios cambios en sus componentes, siendo Mustaine el único miembro permanente y el principal compositor; le sigue en duración el bajista y cofundador David Ellefson, que ha participado en la mayoría de sus álbumes y giras musicales.',
    videourl:
      'https://youtube.com/playlist?list=PLFJaod-oManE5gpqkzyhbspshu6mL7drM',
    price: '$50',
    coverImg: 'megadeth.gif',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://megadeth.com/',
    youtube: 'https://www.youtube.com/channel/UCLVz1B001PIbq9LliJenV2w',
    facebook: 'https://www.facebook.com/Megadeth/',
    instagram: 'https://www.instagram.com/megadeth/',
    twitter: 'https://twitter.com/megadeth',
    spotify: 'https://open.spotify.com/artist/1Yox196W7bzVNZI7RBaPnf',
    deezer: 'https://www.deezer.com/en/artist/3487',
    apple: 'https://music.apple.com/mx/artist/megadeth/488289',
    soundcloud: 'https://soundcloud.com/megadethofficial',
    tidal: 'https://listen.tidal.com/artist/946',
    wiki: 'https://es.wikipedia.org/wiki/Megadeth',
    ticketmaster:
      'https://www.ticketmaster.com/black-sabbath-tickets/artist/734569',
    tiktok: 'https://www.tiktok.com/@blacksabbath999?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJPK78/megadeth',
  },
  {
    title: 'Black Sabbath',
    description:
      'Black Sabbath fue una banda británica de heavy metal y hard rock formada en 1968 en Birmingham por Tony Iommi, Ozzy Osbourne, Geezer Butler y Bill Ward. Influenciados por la música de Blue Cheer, Cream o Vanilla Fudge, la agrupación incorporó desde sus inicios letras sobre ocultismo y terror con guitarras afinadas de modo más grave y consiguió varios discos de oro y platino en la década de 1970. Son los pioneros del heavy metal junto a otros grupos contemporáneos como Deep Purple, Budgie, Pentagram, Sir Lord Baltimore y Led Zeppelin. Al ser una de las primeras y más influyentes bandas de heavy metal de todos los tiempos, Black Sabbath ayudó a desarrollar el género con publicaciones tales como Paranoid, álbum que logró cuatro certificaciones de platino de la RIAA. Han vendido más de 75 millones de discos en todo el mundo, incluidos 15 millones de copias solo en los Estados Unidos.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mdjj_zKQklQQvWts6sRqcza3CtvkyzjkI',
    price: '$50',
    coverImg: 'blacksabath.gif',
    category: 'Rock Metal',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.blacksabbath.com/',
    youtube: 'https://www.youtube.com/channel/UCrx-X329UKv0Y06VhfpFVvw',
    facebook: 'https://www.facebook.com/BlackSabbath',
    instagram: 'https://www.instagram.com/officialsabbath/',
    twitter: 'https://twitter.com/OfficialSabbath',
    spotify: 'https://open.spotify.com/artist/5M52tdBnJaKSvOpJGz8mfZ',
    deezer: 'https://www.deezer.com/mx/artist/647',
    apple: 'https://music.apple.com/mx/artist/black-sabbath/165907',
    soundcloud: 'https://soundcloud.com/blacksabbath',
    tidal: 'https://listen.tidal.com/artist/86',
    wiki: 'https://es.wikipedia.org/wiki/Black_Sabbath',
    ticketmaster:
      'https://www.ticketmaster.com/black-sabbath-tickets/artist/734569',
    tiktok: 'https://www.tiktok.com/@blacksabbath999?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000V8GO24/black-sabbath',
  },
  
  {
    title: 'Marylin Manson',
    description:
      'Brian Hugh Warner (Canton, 5 de enero de 1969) más conocido por su nombre artístico Marilyn Manson, es un cantante, compositor, actor, escritor y director de cine estadounidense, conocido por ser el vocalista y líder de la banda de metal industrial «Marilyn Manson». Su nombre artístico se formó a partir del nombre de la actriz Marilyn Monroe y el apellido del criminal Charles Manson.',
    videourl:
      'https://youtube.com/playlist?list=PLQrnR8RDYWbstejBNOa0BPlv6j5V2PWIj&si=0ePwiHACVo1qDCHy',
    price: '$50',
    coverImg: 'marylinmanson.jpg',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.marilynmanson.com/',
    youtube: 'https://www.youtube.com/channel/UCbirjI1K3MGu0-Y1gTBNR5w',
    facebook: 'https://www.facebook.com/MarilynManson',
    instagram: 'https://www.instagram.com/marilynmanson',
    twitter: 'https://x.com/marilynmanson',
    spotify: 'https://open.spotify.com/artist/2VYQTNDsvvKN9wmU5W7xpj',
    deezer: 'https://www.deezer.com/mx/artist/482',
    apple: 'https://music.apple.com/mx/artist/marilyn-manson/251375',
    soundcloud: 'https://soundcloud.com/marilynmanson',
    tidal: 'https://listen.tidal.com/artist/27472',
    wiki: 'https://es.wikipedia.org/wiki/Marilyn_Manson',
    ticketmaster:'https://www.ticketmaster.com/marilyn-manson-tickets/artist/766825',
    tiktok: 'https://www.tiktok.com/@marilynmanson?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSFJII/marilyn-manson',
  },
  {
    title: 'White Zombie',
    description:
      'White Zombie fue una banda estadounidense de heavy metal, fundada en 1985 por el compositor Rob Zombie y la bajista Sean Yseult. Su origen tiene lugar en Nueva York como una banda de noise rock en la misma categoría de Sonic Youth. El nombre de la misma fue extraído de la película de 1932 protagonizada por Béla Lugosi. White Zombie fue conocido por combinar poderosos riffs de guitarra (notable en la canción Super-Charger Heaven) con líricas fuertemente influidas por películas de terror. Las canciones de la banda no hablaban de la vida real, sino de fantasías de horror sobrenaturales. La música de White Zombie está plagada de influencias y referencias al cine de serie B, a las películas de Roger Corman o Russ Meyer, a los cómics, a la ciencia ficción o a la cultura decadente estadounidense. Su auge a mediados de la década de 1990 coincidió con el de otros grupos con similares influencias, como Monster Magnet o Marilyn Manson.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mR8yaB8X591I4h1RecrvLMPxGCXDj5vZs&si=xWhZwL3IRY3UP14U',
    price: '$50',
    coverImg: 'whitezombie.png',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://whitezombieofficial.com/',
    youtube: 'https://www.youtube.com/channel/UChSu4bOA2ZCxGwG9g2-uYlg',
    facebook: 'https://www.facebook.com/profile.php?id=100050194252107',
    instagram: 'https://www.instagram.com/whitezombieofficial/',
    twitter: 'https://x.com/bnbwhitezombie',
    spotify: 'https://open.spotify.com/artist/0CF71zaDOJWCynIkW9bSK8',
    deezer: 'https://www.deezer.com/mx/artist/3006',
    apple: 'https://music.apple.com/mx/artist/white-zombie/115954',
    soundcloud: 'https://soundcloud.com/white-zombie-official',
    tidal: 'https://listen.tidal.com/artist/34536',
    wiki: 'https://es.wikipedia.org/wiki/White_Zombie_(banda)',
    ticketmaster:'https://www.ticketmaster.com/white-zombie-tickets/artist/742389',
    tiktok: 'https://www.tiktok.com/@whitezommbie?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B001378IOO/white-zombie',
  },
  
  {
    title: 'Yngwie Malmsteem',
    description:
      'Yngwie Johann Malmsteen (Estocolmo, 30 de junio de 1963), de nombre real Lars Johan Yngve Lannerbäck;1​ es un guitarrista de heavy metal y compositor sueco, que participó activamente en grupos como Steeler o Alcatrazz; y al que se le atribuye ser la mayor influencia en la creación del subgénero conocido como metal neoclásico, siendo uno de los grandes exponentes de las técnicas de shred en la guitarra eléctrica durante las décadas de 1970 - 1980,​',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kilw_t8MjzO63RIb-cEKSMW4A_e7V1dQY&si=lz9xwWFGCNc74uT5',
    price: 36,
    coverImg: 'yngwiemalsteem.png',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.yngwiemalmsteen.com/',
    youtube: 'https://www.youtube.com/@officialyngwiemalmsteen622',
    facebook: 'https://www.facebook.com/officialyngwiemalmsteen',
    instagram: 'https://www.instagram.com/yngwiemalmsteen_official/',
    twitter: 'https://x.com/OfficialYJM',
    spotify:
      'https://open.spotify.com/artist/5DpSoH5zCXNRqYai7pmcGG',
    deezer: 'https://www.deezer.com/mx/artist/2089',
    apple: 'https://music.apple.com/mx/artist/yngwie-malmsteen/41769',
    soundcloud: 'https://soundcloud.com/yngwiemalmsteen',
    tidal: 'https://listen.tidal.com/search?q=yngwie%20malmsteen',
    wiki: 'https://es.wikipedia.org/wiki/Yngwie_Malmsteen',
    ticketmaster:
      'https://www.ticketmaster.com/yngwie-malmsteen-tickets/artist/735576',
    tiktok: 'https://www.tiktok.com/@yngwiemalmsteen_official?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKKNA6/yngwie-malmsteen',
  },
  {
    title: 'Twisted Sister',
    description:
      'Twisted Sister, (Español: La Hermana Malvada) fue un grupo estadounidense de shock rock, heavy y hair metal, formado en la ciudad de Nueva York en 1973. El grupo fusiona las tácticas impactantes de Alice Cooper, el humor rebelde de la nueva ola del metal británico (NWOBHM) y el extravagante maquillaje de Kiss.',
    videourl:
      'https://youtube.com/playlist?list=PLf9r4MG644Qrx-Havvb-sR-xw20af9ovR&si=xzgjPZI4lYTiDGfj',
    price: '$50',
    coverImg: 'twistedsister.jpg',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.twistedsister.com/',
    youtube: 'https://www.youtube.com/channel/UChSu4bOA2ZCxGwG9g2-uYlg',
    facebook: 'https://www.facebook.com/profile.php?id=100044230189475',
    instagram: 'https://www.instagram.com/twistedsisterofficial/',
    twitter: 'https://x.com/TwistedSisterNY',
    spotify: 'https://open.spotify.com/artist/7b85ve82Sh36a3UAx74wut',
    deezer: 'https://www.deezer.com/mx/artist/3004',
    apple: 'https://music.apple.com/us/artist/twisted-sister/149786',
    soundcloud: 'https://soundcloud.com/twistedsister',
    tidal: 'https://listen.tidal.com/artist/14800',
    wiki: 'https://es.wikipedia.org/wiki/Twisted_Sister',
    ticketmaster:'https://www.ticketmaster.com/twisted-sister-tickets/artist/874674',
    tiktok: 'https://www.tiktok.com/@officialtwistedsister?lang=es',
    napster: 'https://music.amazon.com/artists/B000TCYO0Q/twisted-sister',
  }, {
    title: 'Ozzy Osbourne',
    description:
      'John Michael Osbourne (Marston Green, Birmingham; 3 de diciembre de 1948),4​ más conocido como Ozzy Osbourne, es un músico y compositor británico, reconocido por haber sido el cantante de la banda de heavy metal Black Sabbath y por su carrera como solista. Participó en la grabación de nueve álbumes de estudio con Black Sabbath,​ de los cuales Paranoid, Master of Reality y Sabbath Bloody Sabbath lograron ventas millonarias y son considerados por la crítica especializada como pioneros y referentes del heavy metal.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mjRqwh-uiA_wVKaZy25ALh4sMOHuD3MRQ&si=dp03zlwRUgoqEzI_',
    price: '$50',
    coverImg: 'ozzyosbourne.jpg',
    category: 'Rock Metal',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.ozzy.com/es',
    youtube: 'https://www.youtube.com/channel/UCe3JCin4Gnv9azlWnAs5keg',
    facebook: 'https://www.facebook.com/ozzyosbourne',
    instagram: 'https://www.instagram.com/ozzyosbourne/',
    twitter: 'https://x.com/OzzyOsbourne',
    spotify: 'https://open.spotify.com/artist/6ZLTlhejhndI4Rh53vYhrY',
    deezer: 'https://www.deezer.com/mx/artist/823',
    apple: 'https://music.apple.com/us/artist/ozzy-osbourne/886834',
    soundcloud: 'https://soundcloud.com/ozzy-osbourne-official',
    tidal: 'https://listen.tidal.com/artist/13369',
    wiki: 'https://es.wikipedia.org/wiki/Ozzy_Osbourne',
    ticketmaster:'https://www.ticketmaster.com/ozzy-osbourne-tickets/artist/735800',
    tiktok: 'https://www.tiktok.com/@officialozzzyosbourne?lang=es',
    napster: 'https://music.amazon.com/artists/B000QKQHAQ/ozzy-osbourne',
  },
  {
    title: 'Bon Jovi',
    description:
      'Bon Jovi es una banda estadounidense de rock formada en 1983 en Nueva Jersey por su líder y vocalista, Jon Bon Jovi. La formación actual la completan el teclista David Bryan, el batería Tico Torres, el bajista Hugh McDonald, los guitarristas Phil X y John Shanks, y el percusionista Everett Bradley. El guitarrista Richie Sambora abandonó la formación en 2013 tras haber permanecido treinta años en ella. Es uno de los grupos de rock más exitosos de todos los tiempos, ha vendido más de 130 millones de discos y ofrecido más de 2800 conciertos en cincuenta países.​ Además está considerado como un icono global de la música y forma parte del Salón de la Fama del Rock and Roll.Bon Jovi es una banda estadounidense de rock formada en 1983 en Nueva Jersey por su líder y vocalista, Jon Bon Jovi. La formación actual la completan el teclista David Bryan, el batería Tico Torres, el bajista Hugh McDonald, los guitarristas Phil X y John Shanks, y el percusionista Everett Bradley. El guitarrista Richie Sambora abandonó la formación en 2013 tras haber permanecido treinta años en ella. Es uno de los grupos de rock más exitosos de todos los tiempos, ha vendido más de 130 millones de discos y ofrecido más de 2800 conciertos en cincuenta países.​ Además está considerado como un icono global de la música y forma parte del Salón de la Fama del Rock and Roll..​',
    videourl:
      'https://youtube.com/playlist?list=PLFaewo-QNq7MPj8uYHlUnr_pGm_PB4zyN&si=O5a9erC9gePax6we',
    price: 36,
    coverImg: 'bonjovi.jpg',
    category: ' Progressive Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.bonjovi.com/',
    youtube: 'https://www.youtube.com/channel/UCkBwnm7GOfYHsacwUjriC-w',
    facebook: 'https://www.facebook.com/BonJovi',
    instagram: 'https://www.instagram.com/bonjovi/',
    twitter: 'https://twitter.com/GGIANTMUSIC',
    spotify: 'https://open.spotify.com/artist/58lV9VcRSjABbAbfWS6skp',
    deezer: 'https://www.deezer.com/mx/artist/1679',
    apple: 'https://music.apple.com/us/artist/bon-jovi/122782',
    soundcloud: 'https://soundcloud.com/bonjovi',
    tidal: 'https://listen.tidal.com/artist/27418',
    wiki: 'https://es.wikipedia.org/wiki/Bon_Jovi',
    ticketmaster: 'https://www.ticketmaster.com/bon-jovi-tickets/artist/734608',
    tiktok: 'https://www.tiktok.com/@bonjovi',
    napster: 'https://music.amazon.com.mx/artists/B000QKPMD4/bon-jovi',
  },
  {
    title: 'Kid Rock',
    description:
      'Robert James Ritchie (Romeo, Míchigan; 17 de enero de 1971), más conocido por su nombre artístico Kid Rock, es un cantante, compositor y actor estadounidense, 5 veces nominado a los Premios Grammy. Kid Rock es conocido por su estilo musical, que fusiona rap con rock, blues, heavy metal, funk y country. Aunque su carrera artística comenzó en 1988, se hizo mundialmente conocido con su álbum Devil Without a Cause (Demonio sin causa) lanzado en 1998 por Atlantic Records, sobre todo con sus sencillos Bawitdaba, Cowboy y Only God Knows Why, llegando a vender 11 millones de copias; Esto hizo que el 17 de abril de 2003, su disco fuera certificado 11 veces platino por la RIAA.1​ En el 2000 lanzó una compilación de canciones remezcladas y remasterizadas bajo el nombre de The History of Rock, todas pertenecientes a álbumes previos, a excepción American Bad Ass (el tema más exitoso del disco), y Abortion, ambos editados por primera vez.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mOTsTMJcskklfevWbytSPSsg-kr1QoSow&si=EgXDEIrVXRJBI22F',
    price: '$50',
    coverImg: 'kidrock.jpg',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://kidrock.com/',
    youtube: 'https://www.youtube.com/channel/UCjwhEPNX5rsU6gl_8qZMQZg',
    facebook: 'https://www.facebook.com/kidrock',
    instagram: 'https://www.instagram.com/kidrock/',
    twitter: 'https://x.com/KidRock',
    spotify: 'https://open.spotify.com/artist/7dOBabd5O4CvKrg4iriHTM',
    deezer: 'https://www.deezer.com/mx/artist/1534',
    apple: 'https://music.apple.com/us/artist/kid-rock/252399',
    soundcloud: 'https://soundcloud.com/kidrockofficial',
    tidal: 'https://listen.tidal.com/artist/26906',
    wiki: 'https://es.wikipedia.org/wiki/Kid_Rock',
    ticketmaster:'https://www.ticketmaster.com/kid-rock-tickets/artist/742673',
    tiktok: 'https://www.tiktok.com/@officiailkidrock?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJOLM8/kid-rock',
  },
  {
    title: 'Papa Roach',
    description:
      'Papa Roach es una banda estadounidense de rock fundada en 1993 en Vacaville, California. Se dio a conocer internacionalmente en el año 2000, con su segundo álbum, Infest, certificado tres veces como disco de platino. Los tres álbumes siguientes, continuaron su éxito: Lovehatetragedy en el año 2002, Getting Away With Murder en 2004 y The Paramour Sessions en 2006. A estos trabajos, les siguieron Metamorphosis en 2009, The Connection en 2012, F.E.A.R. en 2015, Crooked Teeth en 2017 y, cinco años después, el último álbum de Papa Roach hasta la fecha, Ego Trip.',
    videourl:
      'https://youtube.com/playlist?list=UULFeekxg1vju_sjIK9KjJJLYg&si=5sHRdqig5ZhwqkyP',
    price: '$50',
    coverImg: 'paparoach.jpg',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://paparoach.com/',
    youtube: 'https://www.youtube.com/channel/UCeekxg1vju_sjIK9KjJJLYg',
    facebook: 'https://www.facebook.com/paparoach',
    instagram: 'https://www.instagram.com/paparoach',
    twitter: 'https://x.com/paparoach',
    spotify: 'https://open.spotify.com/artist/4RddZ3iHvSpGV4dvATac9X',
    deezer: 'https://www.deezer.com/mx/artist/89',
    apple: 'https://music.apple.com/us/artist/papa-roach/3445763',
    soundcloud: 'https://soundcloud.com/paparoach',
    tidal: 'https://listen.tidal.com/artist/26906',
    wiki: 'https://es.wikipedia.org/wiki/Papa_Roach',
    ticketmaster:'https://www.ticketmaster.com/papa-roach-tickets/artist/708795',
    tiktok: 'https://tiktok.com/@paparoach',
    napster: 'https://music.amazon.com.mx/artists/B000S9ZLSY/papa-roach',
  },  {
    title: 'Tool',
    description:
      'Tool es una banda estadounidense de metal progresivo surgida en 1990 en Los Ángeles, California. Ha vendido más de trece millones de álbumes solo en Estados Unidos y ha ganado tres premios Grammy y ha sido nominado a 6 de estos hasta la fecha; ha encabezado giras y prestigiosos festivales y ha producido exitosos álbumes que alcanzaron grandes niveles de ventas gracias a su estatus de culto en el mundo de la música. Está formada por el baterista Danny Carey, el guitarrista Adam Jones, el vocalista Maynard James Keenan y el bajista Justin Chancellor, que sustituyó a Paul D Amour en 1995.',
    videourl:
      'https://youtube.com/playlist?list=PLESF6Vbm19P2wpGKnhaIoivBvjmuTBZJp',
    price: 36,
    coverImg: 'tool.gif',
    category: 'Rock ',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://toolband.com/',
    youtube: 'https://www.youtube.com/channel/UC1wUo-29zS7m_Jp-U_xYcFQ',
    facebook: 'https://www.facebook.com/ToolMusic',
    instagram: 'https://www.instagram.com/toolmusic/',
    twitter: 'https://twitter.com/tool',
    spotify: 'https://open.spotify.com/artist/2yEwvVSSSUkcLeSTNyHKh8',
    deezer: 'https://www.deezer.com/mx/artist/689',
    apple: 'https://music.apple.com/mx/artist/tool/140870416',
    soundcloud: 'https://soundcloud.com/search?q=Tool',
    tidal: 'https://listen.tidal.com/artist/3850668',
    wiki: 'https://es.wikipedia.org/wiki/Tool',
    ticketmaster: 'https://www.ticketmaster.com/tool-tickets/artist/720703',
    tiktok: 'https://www.tiktok.com/@tool..band?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B003X7KSU4/tool',
  },
  {
    title: 'Pantera',
    description:
      'Pantera es una banda estadounidense de groove metal fundada en 1981 por los hermanos Abbott, Darrell y Vinnie Paul, en Arlington, Texas. El bajista Tommy Bradford se uniría a inicios de 1981 con el vocalista Donnie Hart hasta finales del mismo año en el que a Tommy se le sustituiría por Rex Brown y a Donnie por Terry Glaze, quien además de vocalista era teclista.',
    videourl:
      'https://youtube.com/playlist?list=PLxYReOMcJaFfGz1daUgyTUh9SerOLl3Vz&si=vIJr3TZ2_iWeTDdI',
    price: '$50',
    coverImg: 'pantera.jpg',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://pantera.com/',
    youtube: 'https://www.youtube.com/@pantera',
    facebook: 'https://www.facebook.com/Pantera',
    instagram: 'https://www.instagram.com/panteraofficial/',
    twitter: 'https://x.com/pantera',
    spotify: 'https://open.spotify.com/artist/14pVkFUHDL207LzLHtSA18',
    deezer: 'https://www.deezer.com/mx/artist/2969',
    apple: 'https://music.apple.com/us/artist/pantera/155209',
    soundcloud: 'https://soundcloud.com/pantera',
    tidal: 'https://listen.tidal.com/artist/6373',
    wiki: 'https://es.wikipedia.org/wiki/Pantera_(banda)',
    ticketmaster:'https://www.ticketmaster.com/pantera-tickets/artist/735820',
    tiktok: 'https://www.tiktok.com/@pantera',
    napster: 'https://music.amazon.com.mx/artists/B0011ZU73I/pantera',
  },
  {
    title: 'Slipknot',
    description:
      'Slipknot es una banda estadounidense de metal alternativo formada en 1995 en Des Moines, Iowa, Estados Unidos. Sus integrantes en la actualidad son Corey Taylor, Jim Root, Mick Thomson, Shawn Crahan y Sid Wilson. Slipknot es conocida por las máscaras características de cada uno de sus miembros, que cambian conforme han sacado más discografía.',
    videourl:
      'https://www.youtube.com/playlist?list=PLvXUqfNLb-tnpZXR0jz0_pbfOJ75Y3m9M',
    price: '$50',
    coverImg: 'slipknot.jpg',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://pantera.com/',
    youtube: 'https://www.youtube.com/@slipknot',
    facebook: 'https://www.facebook.com/slipknot',
    instagram: 'https://www.instagram.com/slipknot',
    twitter: 'https://x.com/slipknot',
    spotify: 'https://open.spotify.com/artist/05fG473iIaoy82BF1aGhL8',
    deezer: 'https://www.deezer.com/mx/artist/117',
    apple: 'https://music.apple.com/us/artist/slipknot/6907568',
    soundcloud: 'https://soundcloud.com/slipknot',
    tidal: 'https://listen.tidal.com/artist/37277',
    wiki: 'https://es.wikipedia.org/wiki/Slipknot',
    ticketmaster:'https://www.ticketmaster.com/slipknot-tickets/artist/763913',
    tiktok: 'https://www.tiktok.com/@slipknot',
    napster: 'https://music.amazon.com.mx/artists/B000TDUH2O/slipknot',
  },
  {
    title: 'Slayer',
    description:
      'Slayer es una banda estadounidense de thrash metal proveniente de Huntington Park, California. Fundada en 1981 por los guitarristas Kerry King y Jeff Hanneman, el baterista de origen cubano Dave Lombardo y el bajista y vocalista de origen chileno Tom Araya. El agresivo y rápido estilo de Slayer les haría parte de «los cuatro grandes del thrash metal», junto con Metallica, Megadeth y Anthrax.',
    videourl:
      'https://youtube.com/playlist?list=PLdWTSFhxsOZkCKWndNjuBVXsvXckVEXC0&si=AaH00guDI3rZ50vS',
    price: '$50',
    coverImg: 'slayer.jpg',
    category: 'Rock Metal',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.slayer.net/',
    youtube: 'https://www.youtube.com/channel/UCA7S5M8w5X4P1NRzaJ4lsrg',
    facebook: 'https://www.facebook.com/slayer',
    instagram: 'https://www.instagram.com/slayerbandofficial',
    twitter: 'https://x.com/slayer',
    spotify: 'https://open.spotify.com/artist/1IQ2e1buppatiN1bxUVkrk',
    deezer: 'https://www.deezer.com/mx/artist/3048',
    apple: 'https://music.apple.com/us/artist/slayer/414425',
    soundcloud: 'https://soundcloud.com/slayerofficial',
    tidal: 'https://listen.tidal.com/artist/16386',
    wiki: 'https://es.wikipedia.org/wiki/Slayer',
    ticketmaster:'https://www.ticketmaster.com/slayer-tickets/artist/736135',
    tiktok: 'https://www.tiktok.com/tag/slayerband?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0011ZU73I/pantera',
  },
]

export default CardData21

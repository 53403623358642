const CardData33 
 = [
  {
    title: 'Joan Manuel Serrat',
    description:
      'Joan Manuel Serrat Teresa​ (Barcelona; 27 de diciembre de 1943), conocido como Joan Manuel Serrat, es un cantautor, compositor, actor, escritor, poeta y guitarrista español, premiado con la Gran Cruz de la Orden Civil de Alfonso X el Sabio «por su brillante carrera y su contribución a la cultura y el arte españoles»​ y el Premio Princesa de Asturias de las Artes 2024, por un "trabajo (que), de honda raíz mediterránea, se aúna el arte de la poesía y la música al servicio de la tolerancia, los valores compartidos, la riqueza de la diversidad de lenguas y culturas, así como un necesario afán de libertad.',
    category: 'Trova',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mbCNJwbFM-XfK9t4POOH3x-KUDo4h3PI8&si=ZEVODxPoEz-0vasN',
    price: 80,
    coverImg: 'joanmanuelserrat.jpg',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://jmserrat.com/',
    youtube: 'https://www.youtube.com/channel/UC9twmdozj_iXRhX_FRZMwzw',
    facebook: 'https://www.facebook.com/joanmanuelserrat.oficial',
    instagram: 'https://www.instagram.com/joanmanuelserrat/',
    twitter: 'https://x.com/SerratEscribe',
    spotify:'https://open.spotify.com/artist/1t7t8q4zoYHp22JLIx3FM7',
    deezer: 'https://www.deezer.com/mx/artist/3289',
    apple: 'https://music.apple.com/mx/artist/joan-manuel-serrat/6604936',
    soundcloud: 'https://soundcloud.com/joan-manuel-serrat-official',
    tidal: 'https://listen.tidal.com/artist/4588',
    wiki: 'https://es.wikipedia.org/wiki/Joan_Manuel_Serrat',
    ticketmaster:
      'https://www.ticketmaster.com/joan-manuel-serrat-tickets/artist/1171021',
    tiktok: 'https://www.tiktok.com/@joan.mauelserrat',
    napster: 'https://music.amazon.com.mx/artists/B000QKP4BE/joan-manuel-serrat',
  },
  {
    title: 'Fernando Delgadillo',
    description:
      'Fernando Delgadillo González (Tlalnepantla, 11 de noviembre de 1965) es un cantautor mexicano del género trova o nueva canción. Estudió en la Escuela de Música Libre José F. Vázquez y en la escuela de la Sociedad de Autores y Compositores de México. A los 16 años fue percusionista en una agrupación de música andina llamada Huancayo, con los cuales se presentaba en la peña El Sapo Cancionero;4​ e integró el colectivo SEYMUS.1​ Comenzó actividad artística en 1985. En 1997, Delgadillo fue invitado a China para participar en el Beijing International Film Festival. Después de dicho festival, algunas de sus canciones fueron traducidas al chino. Más tarde, en agosto, lo invitaron al Festival Internacional de la Juventud, con sede en La Habana, Cuba',
    category: 'Trova',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_nzInDmkRTLzFkkj-lLHjOvQj9YpR9rKug',
    price: 80,
    coverImg: 'fernandodelgadillo.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.fdelgadillo.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCVeaNTYY8SGCMqiY31Kc-tA',
    facebook: 'https://www.facebook.com/FernandoDelgadilloOficial/',
    instagram: 'https://www.instagram.com/fernandodelgadillooficial/?hl=es-la',
    twitter: 'https://x.com/DelgadilloFer',
    spotify:
      'https://open.spotify.com/artist/12GqGscKJx3aE4t07u7eVZ?si=u4gDkNCrR_-gnhy-aFtWJQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/14801',
    apple: 'https://music.apple.com/mx/artist/fernando-delgadillo/145982983',
    soundcloud: 'https://soundcloud.com/search?q=Fernando%20Delgadillo',
    tidal: 'https://listen.tidal.com/artist/4159503',
    wiki: 'https://es.wikipedia.org/wiki/Fernando_Delgadillo',
    ticketmaster:
      'https://www.ticketmaster.com/fernando-delgadillo-tickets/artist/1174042',
    tiktok: 'https://www.tiktok.com/search?q=Fernando%20Delgadillo&t=1726170971501',
    napster: 'https://music.amazon.com.mx/artists/B000QKD32G/fernando-delgadillo',
  },
{
  title: 'Silvio Rodriguez',
  description:
    'Silvio Rodríguez Domínguez (San Antonio de los Baños, Cuba; 29 de noviembre de 1946), conocido como Silvio Rodríguez, El Aprendiz o El Escaramujo, es un cantautor, guitarrista, poeta y político cubano, exponente característico de la música de su país surgida con la Revolución cubana, conocida como la Nueva Trova, que comparte con otros reconocidos cantautores tales como Pablo Milanés, Noel Nicola y Vicente Feliú.',

  videourl:
    'https://youtube.com/playlist?list=OLAK5uy_nkSWrqjiIW2Mpw5SUNiDlPF9Llu9nx7TY&si=EuoZqgCVG25ZJgDO',
  price: '$50',
  coverImg: 'sylvio.jpg',
  category: 'Trova',
  location: 'Cuba',
  locationImg: 'cubaflag.jpg',
  openSpots: null,
  likes: null,
  websiteurl: 'https://www.zurrondelaprendiz.com/',
  youtube: 'https://www.youtube.com/channel/UC16aF70kysMIJdz51HdMx9Q',
  facebook: 'https://www.facebook.com/zurrondelaprendiz',
  instagram: 'https://www.instagram.com/zurron_del_aprendiz',
  twitter: 'https://x.com/zurron_aprendiz',
  spotify: 'https://open.spotify.com/artist/4rUyBlggM5tZUH5QZn9ZuO',
  deezer: 'https://www.deezer.com/mx/artist/4340',
  apple: 'https://music.apple.com/mx/artist/silvio-rodr%C3%ADguez/303508',
  soundcloud: 'https://soundcloud.com/silviorodriguezofficial',
  tidal: 'https://listen.tidal.com/artist/3521820',
  wiki: 'https://es.wikipedia.org/wiki/Silvio_Rodr%C3%ADguez',
  ticketmaster:'https://www.ticketmaster.com/silvio-rodriguez-tickets/artist/1171066',
  tiktok: 'https://www.tiktok.com/@silviorodriguesmusic',
  napster: 'https://music.amazon.com.mx/artists/B000QKFHVQ/silvio-rodr%C3%ADguez',
},
{
  title: 'Pablo Milanes',
  description:
    'Pablo Milanés Arias (Bayamo, 24 de febrero de 1943-Madrid, 22 de noviembre de 2022) Nació en Bayamo, provincia de Oriente, actual provincia de Granma, Cuba. Estudió música en el Conservatorio Municipal de La Habana. En sus comienzos estuvo muy influido por la música tradicional cubana y por el feeling (sentimiento, en inglés). El feeling es un estilo musical que se inició en Cuba en los años 1940 y suponía una nueva manera de afrontar la canción, donde el sentimiento definía la interpretación y estaba influido por las corrientes estadounidenses de la canción romántica y del jazz. El feeling se acompañaba de una guitarra, al estilo de los viejos trovadores pero enriquecido por armonizaciones jazzísticas. Así se establecía esta nueva forma de comunicación o "feeling" con el público.',

  videourl:
    'https://youtube.com/playlist?list=OLAK5uy_l47ADXdJJJQvnrxx6LkL9dNH30KN1aa84&si=A10mr6MWXZqDMWjO',
  price: '$50',
  coverImg: 'pablomilanes.jpg',
  category: 'Trova',
  location: 'Cuba',
  locationImg: 'cubaflag.jpg',
  openSpots: null,
  likes: null,
  websiteurl: 'https://www.milanespablo.com/',
  youtube: 'https://www.youtube.com/channel/UCg1l44T5Irwm5K849uO9Hiw',
  facebook: 'https://www.facebook.com/pmilanesoficial',
  instagram: 'https://www.instagram.com/pablo_milanes_oficial/',
  twitter: 'https://x.com/pmilanesoficial',
  spotify: 'https://open.spotify.com/artist/4vOfKh5wz7lTcdqB3EwsC5',
  deezer: 'https://www.deezer.com/mx/artist/75087',
  apple: 'https://music.apple.com/mx/artist/pablo-milan%C3%A9s/132280',
  soundcloud: 'https://soundcloud.com/pablomilanes',
  tidal: 'https://listen.tidal.com/artist/35825',
  wiki: 'https://es.wikipedia.org/wiki/Pablo_Milan%C3%A9s',
  ticketmaster:'https://www.ticketmaster.com/pablo-milanes-tickets/artist/762879',
  tiktok: 'https://www.tiktok.com/search/video?q=Pablo%20Milanes&t=1726169450082',
  napster: 'https://music.amazon.com.mx/artists/B000QKA3M4/pablo-milan%C3%A9s',
},
  {
    title: 'Neto Bernal',
    description:
      'Ernesto Bernal Contreras, mejor conocido como Neto Bernal, es un cantante de música regional, procedente de Mexicali, Baja California, México. El joven, que nació el 1 de octubre de 1997, comparte junto con su madre el origen en la ciudad fronteriza, Mexicali, mientras que su padre viene de Sonora. El artista, de tan sólo 19 años, creció allí junto con su hermano menor, Alexis Bernal.',
    videourl:
      'http://youtube.com/playlist?list=PLaSnKw4FFePPPOXaDVDk3SObo2PbAeqbp',
    price: 50,
    coverImg: 'NetoBernal.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UC0tWKR_TBob21vJTEO67UfA',
    facebook: 'https://www.facebook.com/NetoBernalOficial',
    instagram: 'https://www.instagram.com/netobernaloficial/?hl=en',
    twitter: 'https://twitter.com/elnetillobernal',
    spotify:
      'https://open.spotify.com/artist/0X8PwlFMDK85fIadwq3cA5?si=wQEx4ri9Q_yhkyqLdv1t5w&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/10693157',
    apple: 'https://music.apple.com/mx/artist/neto-bernal/1133791209',
    soundcloud: 'https://soundcloud.com/neto-bernal',
    tidal: 'https://listen.tidal.com/artist/7990895',
    wiki:
      'https://laletrade.com/biografias/regional-mexicano/18635-neto-bernal',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@netobernal?lang=es',
    napster: 'https://web.napster.com/artist/neto-bernal',
  },
  {
    title: 'Camilo',
    description:
      'Camilo Andrés Echeverry Correa, conocido simplemente como Camilo, es un cantautor y músico colombiano. Debutó en 2008, después de ganar el concurso de talentos Factor X de su país en 2007.',
    videourl:
      'http://youtube.com/playlist?list=PLl9yuNCZfI6pQFNQ90lEAdv8VoCOUvE8i',
    price: '$50',
    coverImg: 'Camilo.jpg',
    category: 'Balada',
    location: 'Camilo',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCw0aKHSGFGyrnMeVnHeYMyw',
    facebook: 'https://www.facebook.com/camilomusica',
    instagram: 'https://www.instagram.com/camilo/',
    twitter: 'https://twitter.com/CamiloMusica',
    spotify: 'https://open.spotify.com/artist/28gNT5KBp7IjEOQoevXf9N',
    deezer: 'https://www.deezer.com/mx/artist/58568762',
    apple: 'https://music.apple.com/mx/artist/camilo/1457386767',
    soundcloud: 'https://soundcloud.com/camiloofficial',
    tidal: 'https://listen.tidal.com/artist/3824470',
    wiki: 'https://es.wikipedia.org/wiki/Camilo',
    ticketmaster: 'https://www.ticketmaster.com./camilo-tickets/artist/2773173',
    tiktok: 'https://www.tiktok.com/@camilo?lang=es',
    napster: 'https://app.napster.com/artist/camilo',
  },
  {
    title: 'Rauw Alejandro',
    description:
      'Raúl Alejandro Ocasio Ruiz (born January 10, 1993), known professionally as Rauw Alejandro, is a Puerto Rican singer and songwriter. Referred to as the King of Modern Reggaeton, he belongs to the new generation of Puerto Rican urban musicians. His debut studio album, Afrodisíaco, was released in November 2020. His second studio album, Vice Versa, released in June 2021, featured the lead single Todo de Ti. He has won one Latin Grammy Award from four nominations..',
    videourl:
      'https://youtube.com/playlist?list=PLhK8A9K_cnZS4LXKUgFVIU8ogoASU3FKO',
    price: 80,
    coverImg: 'Rauw.png',
    category: 'Reggaeton',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://rauwalejandro.com/en/',
    youtube: 'https://www.youtube.com/channel/UC_Av98lDjf5KvFib5elhpYg',
    facebook: 'https://www.facebook.com/rauwalejandro',
    instagram: 'https://www.instagram.com/rauwalejandro/',
    twitter: 'https://twitter.com/rauwalejandro',
    spotify: 'https://open.spotify.com/artist/1mcTU81TzQhprhouKaTkpq',
    deezer: 'https://www.deezer.com/en/artist/11289472',
    apple: 'https://music.apple.com/mx/artist/rauw-alejandro/1107186883',
    soundcloud: 'https://soundcloud.com/rauwalejandro',
    tidal: 'https://listen.tidal.com/artist/8239477',
    wiki: 'https://en.wikipedia.org/wiki/Rauw_Alejandro',
    ticketmaster:
      'https://www.ticketmaster.com/rauw-alejandro-tickets/artist/2628019',
    tiktok: 'https://www.tiktok.com/@rauwalejandro?lang=es',
    napster: 'https://us.napster.com/artist/rauw-alejandro',
  },
  {
    title: 'Farruko',
    description:
      'Debutó en 2007 con «Bla, bla, bla», la cual tuvo la producción de Phantom y Villa y con el cual logró posicionarse dentro del ámbito musical, por lo que gracias a esto, lanzó canciones como «El Penthouse», «El paseo por el bloque» con Ñengo Flow y «No me atrevo» en 2007, «Escala a mi cama» con Galante & Killatonez y Ñengo Flow, «Sata es» con Galante & Killatonez y «Sexo fuera del planeta» con Fifer en 2008, con las cuales logró consolidarse como unos los talentos nuevos del reguetón.',
    videourl: 'http://youtube.com/playlist?list=PL98809762BD444BAB',
    price: 80,
    coverImg: 'Farruko.jpg',
    category: 'Reggaeton',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://farruko.com/',
    youtube: 'https://www.youtube.com/c/farruko',
    facebook: 'https://www.facebook.com/farrukoofficial/',
    instagram: 'https://www.instagram.com/farruko/',
    twitter: 'https://twitter.com/FarrukoOfficial',
    spotify: 'https://open.spotify.com/artist/329e4yvIujISKGKz1BZZbO',
    deezer: 'https://www.deezer.com/mx/artist/614223',
    apple: 'https://music.apple.com/mx/artist/farruko/364377482',
    soundcloud: 'https://soundcloud.com/farrukooficial',
    tidal: 'https://listen.tidal.com/artist/4016402',
    wiki: 'https://es.wikipedia.org/wiki/Farruko',
    ticketmaster:
      'https://www.ticketmaster.com./farruko-tickets/artist/1936418',
    tiktok: 'https://www.tiktok.com/@farruko.fans?lang=es',
    napster: 'https://app.napster.com/artist/farruko',
  },
  {
    title: 'Maluma',
    description:
      'Maluma has a number of singles that have charted within the top ten on Billboard Hot Latin Songs, including Felices los 4, Borró Cassette, and Corazón. His collaborative efforts Chantaje with Shakira and Medellín with Madonna have reached the top of the Hot Latin Songs and the Dance Club Songs chart, respectively. He has worked with other international artists, such as Ricky Martin, J Balvin, and The Weeknd. Maluma has won a Latin Grammy Award, an MTV Video Music Award, two Latin American Music Awards, and been nominated for a Grammy Award for Best Latin Pop Album.',
    videourl:
      'http://youtube.com/playlist?list=PLI2uE28GO_poIPrHuSGt2Ru6Er5AzCyk5',
    price: 36,
    coverImg: 'maluma.jpg',
    category: 'Bachatta',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl:
      'https://maluma.online/?fbclid=IwAR2kZKmUfqJ3sRMh-jl3eHSM0apR2-lEeKxsGC9d_VT1XsfmqCbVgyzZ5eY',
    youtube: 'https://www.youtube.com/c/MalumaOfficialChannel',
    facebook: 'https://www.facebook.com/MALUMAMUSIK',
    instagram: 'https://www.instagram.com/maluma/?hl=en',
    twitter:
      'https://twitter.com/maluma?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
    spotify: 'https://open.spotify.com/artist/1r4hJ1h58CWwUQe3MxPuau',
    deezer: 'https://www.deezer.com/mx/artist/1424602',
    apple: 'https://music.apple.com/mx/artist/maluma/448916501',
    soundcloud: 'https://soundcloud.com/malumaofficial',
    tidal: 'https://listen.tidal.com/artist/4098182',
    wiki: 'https://es.wikipedia.org/wiki/Maluma',
    ticketmaster: 'https://www.ticketmaster.com./maluma-tickets/artist/2095829',
    tiktok: 'https://www.tiktok.com/@maluma1472?lang=es',
    napster: 'https://app.napster.com/artist/maluma',
  },
  {
    title: 'J Balvin',
    description:
      'José Álvaro Osorio Balvín, conocido artísticamente como J Balvin, es un cantante, compositor y productor discográfico colombiano. Ha logrado posicionarse tanto en el mercado musical hispano como en el mercado musical de habla inglesa, llegando a colocar sus temas en número uno en varias listas musicales, entre ellas Billboard. También es conocido como el Príncipe del Reguetón.',
    videourl:
      'http://youtube.com/playlist?list=PLWgVwtnp9KujXASQJ5gPUqHlEeLx-A4IE',
    price: '$50',
    coverImg: 'jBalvin.jpg',
    category: 'Reggaeton',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://jbalvin.com/',
    youtube: 'https://www.youtube.com/channel/UCt-k6JwNWHMXDBGm9IYHdsg',
    facebook: 'https://www.facebook.com/JBalvinReal',
    instagram: 'https://www.instagram.com/jbalvin.man.0/',
    twitter: 'https://twitter.com/jbalvin',
    spotify: 'https://open.spotify.com/artist/1vyhD5VmyZ7KMfW5gqLgo5',
    deezer: 'https://www.deezer.com/mx/artist/4860761',
    apple: 'https://music.apple.com/mx/artist/j-balvin/444520760',
    soundcloud: 'https://soundcloud.com/j-balvin-official',
    tidal: 'https://listen.tidal.com/artist/5054713',
    wiki: 'https://es.wikipedia.org/wiki/J_Balvin',
    ticketmaster:
      'https://www.ticketmaster.com./j-balvin-tickets/artist/1907948',
    tiktok: 'https://www.tiktok.com/@jbalvin2794?lang=es',
    napster: 'https://app.napster.com/artist/j-balvin',
  },
  {
    title: 'Romeo Santos',
    description:
      'Anthony Santos (Bronx, Nueva York, 21 de julio de 1981),1​ conocido artísticamente como Romeo Santos, es un cantante estadounidense. Es considerado en la mayor parte de América como el Rey de la Bachata,2​3​ por ser el líder, vocalista y compositor principal de la agrupación Aventura.Como miembro de Aventura, Santos ha sido una figura clave en la popularización de la bachata a nivel internacional, llevando temas al top de las listas de Billboard Latino y a listas de Europa. Tras varios discos con la agrupación Aventura, Romeo anunció su separación de la agrupación para lanzarse como solista en abril de 2011. Como solista ha lanzado 5 álbumes de estudio: Fórmula, vol. 1 (2011), Fórmula, vol. 2 (2014), Golden (2017), Utopía (2019), Fórmula, vol. 3 (2022)..',
    videourl:
      'https://youtube.com/playlist?list=PLZhMg9qj7KFJH4VgsAAwf1ib7qoOZkj_G',
    price: 50,
    coverImg: 'romeosantos.jpg',
    category: 'Bachatta',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.formulavol3.com/',
    youtube: 'https://www.youtube.com/channel/UCyxbZF7_PK4nLiexj0kkCNg',
    facebook: 'https://www.facebook.com/RomeoSantosOfficial/',
    instagram: 'https://www.instagram.com/romeosantos/',
    twitter: 'https://twitter.com/RomeoSantosPage',
    spotify:
      'https://open.spotify.com/artist/5lwmRuXgjX8xIwlnauTZIP?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/1272674',
    apple: 'https://music.apple.com/mx/artist/romeo-santos/434832774',
    soundcloud: 'https://soundcloud.com/romeo-santos',
    tidal: 'https://listen.tidal.com/artist/3969346',
    wiki: 'https://es.wikipedia.org/wiki/Romeo_Santos',
    ticketmaster:
      'https://www.ticketmaster.com./romeo-santos-tickets/artist/1670057',
    tiktok: 'https://www.tiktok.com/@romeosantos?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B004YXX1T6/romeo-santos',
  },

  {
    title: 'Justin Timberlake',
    description:
      "Justin Randall Timberlake (Memphis; 31 de enero de 1981) es un cantante, compositor, productor discográfico, actor, bailarín y empresario estadounidense. Criado en la comunidad de Shelby Forest, ubicada entre Memphis y Millington, comenzó su carrera como cantante en los programas de televisión Star Search y Mickey Mouse Club. A finales de los años 1990 adquirió fama internacional al pertenecer a la boy band 'N Sync, cuyos dos primeros álbumes fueron certificados con disco de diamante tras vender más de 10 millones de copias solo en los Estados Unidos, además de haber alcanzado la cima de numerosos listados alrededor del mundo",
    videourl:
      'https://youtube.com/playlist?list=PLOivmTxGQl85Wfn2671PhKUbNWLYqBW14',
    price: '$50',
    coverImg: 'justintimberlake.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://justintimberlake.com/',
    youtube: 'https://www.youtube.com/channel/UC-y8ci7xfsu4L3zkSuHae0A',
    facebook: 'https://www.facebook.com/justintimberlake',
    instagram: 'https://www.instagram.com/justintimberlake/',
    twitter: 'https://twitter.com/jtimberlake',
    spotify: 'https://open.spotify.com/artist/31TPClRtHm23RisEBtV3X7',
    deezer: 'https://www.deezer.com/mx/artist/1147',
    apple: 'https://music.apple.com/mx/artist/justin-timberlake/398128',
    soundcloud: 'https://soundcloud.com/justintimberlake',
    tidal: 'https://listen.tidal.com/artist/1569',
    wiki: 'https://es.wikipedia.org/wiki/Justin_Timberlake',
    ticketmaster:
      'https://www.ticketmaster.com/justin-timberlake-tickets/artist/847841',
    napster: 'https://web.napster.com/artist/art.56237',
    tiktok: 'https://www.tiktok.com/@justintimberlake?lang=es',
  },
  {
    title: 'Bruno Mars',
    description:
      'Peter Gene Hernandez, conocido artísticamente como Bruno Mars, es un cantante, compositor, productor musical y bailarín estadounidense. Mars comenzó a crear música desde una edad muy temprana y actuó en muchos escenarios de su pueblo natal a lo largo de su niñez realizando imitaciones. Se graduó de la escuela secundaria y se mudó a Los Ángeles para proseguir con su carrera musical..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_m0ApIZblcRk9r_9DXSyf69qfT6RhNmy0U',
    price: '$50',
    coverImg: 'BrunoMars.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.brunomars.com/',
    youtube: 'https://www.youtube.com/channel/UCoUM-UJ7rirJYP8CQ0EIaHA',
    facebook: 'https://www.facebook.com/brunomars',
    instagram: 'https://www.instagram.com/brunomars/?hl=en',
    twitter:
      'https://twitter.com/brunomars?fbclid=IwAR0dFGgCSmr_rVTyHvrSn3-9vjjrsSYJUFXsulRL2ssMjipNopi-eZNOMY0',
    spotify: 'https://open.spotify.com/artist/0du5cEVh5yTK9QJze8zA0C',
    deezer: 'https://www.deezer.com/mx/artist/429675',
    apple: 'https://music.apple.com/mx/artist/bruno-mars/278873078',
    soundcloud: 'https://soundcloud.com/brunomars',
    tidal: 'https://listen.tidal.com/artist/3658521',
    wiki: 'https://www.tiktok.com/@brunomars?lang=es',
    ticketmaster:
      'https://www.ticketmaster.com/bruno-mars-tickets/artist/1466801',
    tiktok: 'https://www.tiktok.com/@brunomars?lang=es',
    napster: 'https://web.napster.com/artist/art.26871501',
  },
  {
    title: 'Bad Bunny',
    description:
      'Benito Antonio Martínez Ocasio (Almirante Sur, Vega Baja, 10 de marzo de 1994), conocido artísticamente como Bad Bunny, es un rapero, cantante, compositor y actor puertorriqueño.Su estilo de música es generalmente definido como trap latino y reguetón, pero también ha interpretado otros géneros como rock, kizomba y soul. Se caracteriza por su entonación grave y rasposa y su estilo de vestir, considerado uno de los artistas urbanos latinos más importantes de la historia',
    videourl:
      'https://www.youtube.com/playlist?list=PLRW7iEDD9RDStpKHAckdbGs3xaCChAL7Z',
    price: 50,
    coverImg: 'BadBunny.jpeg',
    category: 'Trap ',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.badbunnytour.com/',
    youtube: 'https://www.youtube.com/channel/UCmBA_wu8xGg1OfOkfW13Q0Q',
    facebook: 'https://www.facebook.com/BadBunnyOfficial',
    instagram: 'https://www.instagram.com/badbunnyofficiials/',
    twitter: 'https://twitter.com/badbunnyinfope',
    spotify: 'https://open.spotify.com/artist/4q3ewBCX7sLwd24euuV69X',
    deezer: 'https://www.deezer.com/mx/artist/10583405',
    apple: 'https://music.apple.com/mx/artist/bad-bunny/1126808565',
    soundcloud: 'https://soundcloud.com/badbunny15',
    tidal: 'https://listen.tidal.com/artist/8027279',
    wiki: 'https://es.wikipedia.org/wiki/Bad_Bunny',
    ticketmaster:
      'https://www.ticketmaster.com/bad-bunny-tickets/artist/2317395',
    tiktok: 'https://www.tiktok.com/@badbunny?lang=es',
    napster: 'https://web.napster.com/artist/art.231403288',
  },
  
  {
    title: 'Gera MX',
    description:
      'Gerardo Daniel Torres Montante (San Nicolás de los Garza, Nuevo León; 15 de julio de 1994 ), mejor conocido por su nombr MX (anteriormente Gera MXM), es un rapero y compositor mexicano. Su nombre artístico proviene de las primeras cuatro letras de su nombre real, Gera (Gerardo) y la abreviatura del sello Mexamafia ( MXM ), la abreviatura MX proviene de México.e artístico Gera.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nKCRl-R8dhnNTcmpHzJ5XiKzLseTEaij4',
    price: '$50',
    coverImg: 'GeraMX.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://richvagosent.com/',
    youtube: 'https://www.youtube.com/watch?v=ZaJZ9GJwz30',
    facebook: 'https://www.facebook.com/GERAMX444/',
    instagram: 'https://www.instagram.com/geramx1/',
    twitter: 'https://twitter.com/mxgeramx',
    spotify: 'https://open.spotify.com/artist/2hejA1Dkf8v8R0koF44FvW',
    deezer: 'https://www.deezer.com/mx/artist/6661704',
    apple: 'https://music.apple.com/mx/artist/gera-mx/863348914',
    soundcloud: 'https://soundcloud.com/geramx-music',
    tidal: 'https://listen.tidal.com/artist/8511790',
    wiki: 'https://es.wikipedia.org/wiki/Gera_MX',
    ticketmaster:
      'https://www.ticketmaster.com./gera-mx-tickets/artist/2773162',
    tiktok: 'https://www.tiktok.com/@geramxfans.official?lang=es',
    napster: 'https://app.napster.com/artist/gera-mx',
  },
  {
    title: 'El Fantasma',
    description:
      "Alexander García was born in Las Canas, Durango. He worked as a gardener for six years before becoming a singer.He performs regional Mexican music[2] and corrido songs about hardworking people. On social media, fans nickname him the King from the Underground.His nickname began because his friends and family call him El Fanta.[3] He was finalist at the 2019 Billboard Latin Music Awards.In 2019, El Fantasma's single Encantadora charted number 1 on Regional Mexican Airplay. On August 14, 2021, his single Soy Buen Amigo charted number 1 on Regional Mexican Airplay. It also charted 27 on Hot Latin Songs",
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLaKik8hFaPZGymkNFbeU8zvZVj0QvugDV',
    price: 80,
    coverImg: 'elfantasma.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC9kHcAu8pGPY7PyKOnK7pfg',
    facebook: 'https://www.facebook.com/elfantasmaoficial/',
    instagram: 'https://www.instagram.com/elfantasmaoficial/',
    twitter: 'https://twitter.com/El_Fanta45',
    spotify: 'https://open.spotify.com/artist/0my6Pg4I28dVcZLSpAkqhv',
    deezer: 'https://www.deezer.com/mx/artist/5230088',
    apple: 'https://music.apple.com/mx/artist/el-fantasma/1245916228',
    soundcloud: 'https://soundcloud.com/elfantasma-music',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/El_Fantasma_(singer)',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@elfantasma.oficial',
    napster: 'https://music.amazon.com.mx/artists/B003DHLCDQ/el-fantasma',
  },
  {
    title: 'Natanael Cano',
    description: ' Auditorio Telmex - Jueves 01 Junio 21:00 hrs ',
    category: 'Sierreño',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lomYN5ttmEGiAiXBpCviVwoe5toNC2YM8',
    price: 80,
    coverImg: 'natan.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC7tJh7b0ledI9fe1A_-dzRw',
    facebook: 'https://www.facebook.com/profile.php?id=null77546590950',
    instagram: 'https://www.instagram.com/Natanael_Cano/',
    twitter: 'https://twitter.com/NatanaelCanoOf2',
    spotify: 'https://open.spotify.com/artist/0elWFr7TW8piilVRYJUe4P',
    deezer: 'https://www.deezer.com/mx/artist/15304117',
    apple: 'https://music.apple.com/mx/artist/natanael-cano/1406984792',
    soundcloud: 'https://soundcloud.com/natanaelcano',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/Natanael_Cano',
    ticketmaster:
      'https://www.ticketmaster.com/natanael-cano-tickets/artist/2797745',
    tiktok: 'https://www.tiktok.com/@natanaelcano_official?lang=es',
    napster: 'https://app.napster.com/artist/natanael-cano',
  },

  {
    title: 'C Kan',
    description:
      'José Luis Maldonado Ramos (Guadalajara, Jalisco, 26 de julio de 1987),1​ más conocido por su nombre artístico C-Kan, es un rapero, compositor, productor y actor mexicano.2​ Su nombre artístico proviene de la palabra Can, (Perro)3​ y la letra C, de cancha: por el lugar en el que vivía La Cancha 98 C, Perro», estilizándose como C-Kan).4​ Ganó gran fama en 2012 con su álbum de estudio Voy por el sueño de muchos, alcanzando el número 1 de ventas en iTunes México​',
    videourl: 'https://youtube.com/playlist?list=UUkD89TEFWsTZluY6tk84yqg',
    price: 50,
    coverImg: 'ckan.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.masteredtrax.com/',
    youtube: 'https://www.youtube.com/channel/UCkD89TEFWsTZluY6tk84yqg',
    facebook: 'https://www.facebook.com/CKanMEX/',
    instagram: 'https://www.instagram.com/ckan98/',
    twitter: 'https://twitter.com/ckan98',
    spotify:
      'https://open.spotify.com/artist/1QhaqxeqF9sipS2gwbEKpu?si=qt0Wjxi4SiuG0poydNjOxA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/4252576',
    apple: 'https://music.apple.com/mx/artist/c-kan/305830705',
    soundcloud: 'https://soundcloud.com/ckan98',
    tidal: 'https://listen.tidal.com/artist/4893770',
    wiki: 'https://es.wikipedia.org/wiki/C-Kan',
    ticketmaster: 'https://www.ticketmaster.com./ckan-tickets/artist/2258547',
    tiktok: 'https://www.tiktok.com/@c.kan98?lang=es',
    napster: 'https://app.napster.com/artist/c-kan',
  },
  {
    title: 'Eden Muñoz',
    description:
      'Rodolfo Edén Muñoz Cantú (Los Mochis, Sinaloa, 25 de septiembre de 1990), es un cantante, compositor y productor mexicano de música regional mexicana. Muñoz es el menor de 4 hermanos del matrimonio entre Rodolfo Muñoz e Isidora Cantú quienes por azares del destino la vida los traslada a la ciudad de Mazatlán, Sinaloa, donde más adelante su crianza y entorno lo llevaría de lleno a la música.Desde muy pequeño Edén comenzó a dar muestra que había nacido para esto, ya que sin ninguna pena interpretaba canciones y le cambiaba la letra a estas sin saber que más adelante serviría de plataforma para lograr grandes composiciones que han marcado el rumbo de la música regional mexicana en los últimos tiempos..',
    videourl:
      'https://youtube.com/playlist?list=PLLlCI9n44muADESckd8b6oOL1cmfGLz35',
    coverImg: 'edenmunoz.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCJm7UPWbVhuNe3achnRi8iA',
    facebook: 'https://www.facebook.com/EdenMunozOficial/',
    instagram: 'https://www.facebook.com/EdenMunozOficial/',
    twitter: 'https://twitter.com/edenmunozof',
    spotify: 'https://open.spotify.com/artist/66ihevNkSYNzRAl44dx6jJ',
    deezer: 'https://www.deezer.com/mx/artist/4414006',
    apple: 'https://music.apple.com/mx/artist/eden-mu%C3%B1oz/383984059',
    soundcloud: 'https://soundcloud.com/edenmunoz-music',
    tidal: 'https://listen.tidal.com/artist/12062930',
    wiki: 'https://es.wikipedia.org/wiki/Ed%C3%A9n_Mu%C3%B1oz',
    ticketmaster:
      'https://www.ticketmaster.com/eden-munoz-tickets/artist/Z7r9jZa8yb',
    tiktok: 'https://www.tiktok.com/@edenmunoz1?lang=es',
    napster: 'https://web.napster.com/artist/eden-munoz-4',
  },
  {
    title: 'The Weeknd',
    description:
      'Abel Makkonen Tesfaye (Toronto, Ontario; 16 de febrero de 1990), conocido por su nombre artístico The Weeknd, es un cantante, compositor y productor canadiense, conocido por éxitos como «Save Your Tears», «Blinding Lights», «The Hills», «Starboy», «Earned It», entre otros.Empezó a ganar reconocimiento a finales de 2010, cuando subió de forma anónima varias canciones a YouTube bajo el nombre de The Weeknd. Durante el 2011 lanzó tres mixtapes de nueve pistas, titulados House of Balloons, Thursday y Echoes of Silence, que fueron muy aclamados por la crítica.1​ En el siguiente año, lanzó su primer trabajo recopilatorio titulado Trilogy, con treinta pistas remasterizadas de sus mixtapes y tres canciones más. Fue lanzado al mercado por la compañías discográficas Republic Records y XO..',
    videourl:
      'https://youtube.com/playlist?list=PLK2zhq9oy0K6rjySCH1nARTssbv8m2Kfm',
    price: '$50',
    coverImg: 'theweeknd.jpg',
    category: 'Pop Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.postmalone.com/',
    youtube: 'https://www.youtube.com/channel/UC0WP5P-ufpRfjbNrmOWwLBQ',
    facebook: 'https://www.facebook.com/theweeknd',
    instagram: 'https://www.instagram.com/theweeknd/',
    twitter: 'https://twitter.com/theweeknd',
    spotify: 'https://open.spotify.com/artist/1Xyo4u8uXC1ZmMpatF05PJ',
    deezer: 'https://www.deezer.com/mx/artist/4050205',
    apple: 'https://music.apple.com/mx/artist/the-weeknd/479756766',
    soundcloud: 'https://soundcloud.com/theweeknd',
    tidal: 'https://listen.tidal.com/artist/4761957',
    wiki: 'https://es.wikipedia.org/wiki/The_Weeknd',
    ticketmaster:
      'https://www.ticketmaster.com/the-weeknd-tickets/artist/1697014',
    tiktok: 'https://www.tiktok.com/@theweeknd?lang=es',
    napster: 'https://us.napster.com/artist/the-weeknd',
  },{
    title: 'Rihanna',
    description:
      'Robyn Rihanna Fenty, conocida simplemente como Rihanna, es una cantante, empresaria, diseñadora de moda, actriz, diplomática, escritora y bailarina barbadense. Es conocida por fusionar algunos géneros caribeños con música pop y por reinventar su imagen a través de los años. Su impacto en la cultura popular la ha llevado a convertirse en un icono de la música y de la moda, por lo que se refieren a ella como la «Princesa del R&B» y «Reina de la Moda». Rihanna es además considerada la artista musical más influyente y exitosa del siglo XXI.',
    videourl:
      'https://youtube.com/playlist?list=PL-8M5stZkYjoIoESZb5jRfOs1mWHS4LPH',
    price: '$50',
    coverImg: 'Rihanna.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.rihannanow.com/',
    youtube: 'https://www.youtube.com/channel/UCcgqSM4YEo5vVQpqwN-MaNw',
    facebook: 'https://www.facebook.com/rihannaitalia',
    instagram: 'https://www.instagram.com/BadGalRiRi/',
    twitter: 'https://twitter.com/rihanna',
    spotify: 'https://open.spotify.com/artist/5pKCCKE2ajJHZ9KAiaK11H',
    deezer: 'https://www.deezer.com/mx/artist/564',
    apple: 'https://music.apple.com/mx/artist/rihanna/63346553',
    soundcloud: 'https://soundcloud.com/rihanna',
    tidal: 'https://listen.tidal.com/artist/10665',
    wiki: 'https://es.wikipedia.org/wiki/Rihanna',
    ticketmaster: 'https://www.ticketmaster.com/rihanna-tickets/artist/1013826',
    tiktok: 'https://www.tiktok.com/@rihanna?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000TDU6TS/rihanna',
  },

  {
    title: 'Lady Gaga',
    description:
      'Stefani Joanne Angelina Germanotta, más conocida por su nombre artístico Lady Gaga, es una cantante, compositora, productora, bailarina, actriz, activista y diseñadora de moda estadounidense. Nacida y criada en la ciudad de Nueva York, estudió en la escuela Convent of the Sacred Heart y asistió por un tiempo breve a la Tisch School of the Arts, perteneciente a la Universidad de Nueva York, hasta que abandonó sus estudios para enfocarse en su carrera musical. Fue así como irrumpió en la escena del rock en el Lower East Side de Manhattan y firmó un contrato con Streamline Records hacia fines de 2007. En la época en que trabajaba como compositora para dicha discográfica, su voz llamó la atención del artista Akon, quien la hizo firmar un contrato con Kon Live Distribution',
    videourl:
      'https://youtube.com/playlist?list=PLf5BnbCRqFj5c1wwBIIOU5ir7VVAgiAEO',
    price: '$50',
    coverImg: 'LadyGaga.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.ladygaga.com/',
    youtube: 'https://www.youtube.com/channel/UCNL1ZadSjHpjm4q9j2sVtOA',
    facebook: 'https://www.facebook.com/ladygaga',
    instagram: 'https://www.instagram.com/ladygaga/',
    twitter: 'https://twitter.com/ladygaga',
    spotify: 'https://open.spotify.com/artist/1HY2Jd0NmPuamShAr6KMms',
    deezer: 'https://www.deezer.com/mx/artist/75491',
    apple: 'https://music.apple.com/mx/artist/lady-gaga/277293880',
    soundcloud: 'https://soundcloud.com/ladygaga',
    tidal: 'https://listen.tidal.com/artist/3534754',
    wiki: 'https://es.wikipedia.org/wiki/Lady_Gaga',
    ticketmaster:
      'https://www.ticketmaster.com/lady-gaga-tickets/artist/1249444',
    tiktok: 'https://www.tiktok.com/@ladygaga?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0016SK9OW/lady-gaga',
  },
  {
    title: 'Kylie Minogue',
    description:
      'Kylie Ann Minogue (/ˈkaɪliː mɨˈnoʊɡ/; Melbourne, 28 de mayo de 1968) es una cantante, compositora y actriz australiana. Es ampliamente reconocida por su octavo álbum de estudio, Fever (2001), y su exitoso sencillo «Cant Get You Out of My Head», conocida como la canción más sonada del año, la cual le valió dos distinciones en los Premios Brit en 2002.​ Posteriormente, en 2023, escala de nuevo a los conteos musicales con «Padam Padam». Con un total de ochenta millones de producciones musicales vendidas en el mundo a lo largo de su carrera,​ Minogue es una de los artistas australianos de mayores ventas..',
    videourl:
      'https://youtube.com/playlist?list=PLzOGAM1vNU1qgpBKtjZwpGJu1FWBQtAVP&si=XoEAvHQS7eosFqlZ',
    price: '$50',
    coverImg: 'kylieminogue.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://music.kylie.com/',
    youtube: 'https://www.youtube.com/@kylieminogue',
    facebook: 'https://www.facebook.com/kylieminogue',
    instagram: 'https://www.instagram.com/kylieminogue',
    twitter:
      'https://x.com/kylieminogue',
    spotify: 'https://open.spotify.com/artist/4RVnAU35WRWra6OZ3CbbMA',
    deezer: 'https://www.deezer.com/mx/artist/479',
    apple: 'https://music.apple.com/mx/artist/kylie-minogue/465031',
    soundcloud: 'https://soundcloud.com/kylie-minogue-official',
    tidal: 'https://listen.tidal.com/artist/2573',
    wiki: 'https://es.wikipedia.org/wiki/Kylie_Minogue',
    ticketmaster:
      'https://www.ticketmaster.com/kylie-minogue-tickets/artist/735661',
    tiktok: 'https://www.tiktok.com/@kylieminogue?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSDP0C/kylie-minogue',
  },
  {
    title: 'Carrie Underwood',
    description:
      'Carrie Marie Underwood (Muskogee, Oklahoma; 10 de marzo de 1983)2​ es una cantante de música country y actriz estadounidense. Underwood saltó a la fama tras ganar la 4° temporada de American Idol en 2005. Su álbum debut, Some Hearts, fue lanzado en 2005 y cuenta con los éxitos "Jesus, Take The Wheel", "Before He Cheats" y "Wasted". Es el álbum debut de un artista country que más rápido se ha vendido en la historia de Nielsen SoundScan.3​ También se convirtió en el álbum debut más vendido por una artista country en la historia de RIAA, también el álbum debut más vendido por una ex-American Idol.',
    videourl:
      'https://youtube.com/playlist?list=PLzOGAM1vNU1qgpBKtjZwpGJu1FWBQtAVP&si=XoEAvHQS7eosFqlZ',
    price: '$50',
    coverImg: 'carrieunderwood.jpg',
    category: 'Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.carrieunderwoodofficial.com/',
    youtube: 'https://www.youtube.com/@carrieunderwood',
    facebook: 'https://www.facebook.com/carrieunderwood',
    instagram: 'https://www.instagram.com/carrieunderwood/',
    twitter:
      'https://x.com/carrieunderwood',
    spotify: 'https://open.spotify.com/artist/4xFUf1FHVy696Q1JQZMTRj',
    deezer: 'https://www.deezer.com/mx/artist/4388',
    apple: 'https://music.apple.com/mx/artist/carrie-underwood/63399334',
    soundcloud: 'https://soundcloud.com/carrieunderwoodofficial',
    tidal: 'https://listen.tidal.com/artist/27941',
    wiki: 'https://es.wikipedia.org/wiki/Carrie_Underwood',
    ticketmaster:
      'https://www.ticketmaster.com/kylie-minogue-tickets/artist/735661',
    tiktok: 'https://www.tiktok.com/@carrieunderwood?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00137J9VK/carrie-underwood',
  },
  {
    title: 'Demi Lovato',
    description:
      'Demetria Devonne Lovato (Albuquerque, Nuevo México; 20 de agosto de 1992), conocida como Demi Lovato, es una actriz, cantante y compositora estadounidense. Después de aparecer en la serie de televisión infantil Barney y sus amigos (2002-2004), Lovato saltó a la fama por interpretar a Mitchie Torres en la película musical para televisión de Disney Channel Camp Rock (2008) y su secuela Camp Rock 2: The Final Jam (2010). La banda sonora de la película anterior contenía «This Is Me», el sencillo debut de Lovato y el dúo con Joe Jonas, que alcanzó el puesto número nueve en el Billboard Hot 100 de Estados Unidos.Después de firmar con Hollywood Records, Lovato lanzó su álbum debut de pop rock, Dont Forget (2008), que debutó en el número dos en el Billboard 200 de Estados Unidos. Su seguimiento, Here We Go Again (2009), debutó en el número uno en los Estados Unidos, mientras que su canción principal alcanzó el número 15 en el Billboard Hot 100. Su tercer álbum de estudio, Unbroken (2011), experimentó con pop y R&B y generó el sencillo «Skyscraper», certificado platino en Estados Unidos. Lanzó su cuarto álbum homónimo en 2013, que debutó en el número tres en el Billboard 200 y contenía la canción internacional «Heart Attack». Los álbumes quinto y sexto de Lovato, Confident (2015) y Tell Me You Love Me (2017), infundieron soul y temas maduros. Obtuvo una nominación al premio Grammy por Confident, mientras que «Sorry Not Sorry», el sencillo principal de Tell Me You Love Me, se convirtió en su sencillo con las listas más altas en los Estados Unidos, alcanzando el número seis. Después de una pausa, siguió su séptimo álbum Dancing with the Devil... The Art of Starting Over en 2021, que alcanzó el número dos en los Estados Unidos, y su octavo álbum, Holy Fvck, lanzado en 2022, en el cual regresó a su sonido rock característico.',
    videourl:
      'https://youtube.com/playlist?list=PLy4Kg0J0TkebIr4dkt4rEgDns_ZwP0rzh',
    price: '$50',
    coverImg: 'demiLovato.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.demilovato.com/',
    youtube: 'https://www.youtube.com/channel/UCZkURf9tDolFOeuw_4RD7XQ',
    facebook: 'https://www.facebook.com/DemiLovato',
    instagram: 'https://www.instagram.com/ddlovato/',
    twitter:
      'https://twitter.com/ddlovato?fbclid=IwAR0O4EFAwe-J9akxjfltKN7XRDEwy5wAi1_pjdm_cbFTQCY4KB-twhabAms',
    spotify: 'https://open.spotify.com/artist/6S2OmqARrzebs0tKUEyXyp',
    deezer: 'https://www.deezer.com/mx/artist/193875',
    apple: 'https://music.apple.com/mx/artist/demi-lovato/280215821',
    soundcloud: 'https://soundcloud.com/ddlovato',
    tidal: 'https://listen.tidal.com/artist/3544816',
    wiki: 'https://es.wikipedia.org/wiki/Demi_Lovato',
    ticketmaster:
      'https://www.ticketmaster.com/demi-lovato-tickets/artist/1224909',
    tiktok: 'https://www.tiktok.com/@ddlovato?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B001940DS0/demi-lovato',
  },
  {
    title: 'Adele',
    description:
      'Adele Laurie Blue Adkins (Londres, 5 de mayo de 1988), conocida simplemente como Adele, es una cantautora y multinstrumentista británica. Es una de las artistas musicales con mayores ventas del mundo, con más de 120 millones de ventas entre discos y sencillos.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_n5Ls1CYg63QxBxeuJf4ICzSY-kvAMLQu8',
    price: '$50',
    coverImg: 'adele.jpg',
    category: 'Soul-Pop',
    location: 'Inglaterra',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    merchsiteurl: 'https://shop.adele.com/',
    websiteurl: 'https://www.adele.com/',
    youtube: 'https://www.youtube.com/channel/UCsRM0YB_dabtEPGPTKo-gcw',
    facebook: 'https://www.facebook.com/adele?fref=ts',
    instagram: 'https://www.instagram.com/adele/',
    twitter: 'https://twitter.com/Adele',
    spotify: 'https://open.spotify.com/artist/4dpARuHxo51G3z768sgnrY',
    deezer: 'https://www.deezer.com/mx/artist/75798',
    apple: 'https://music.apple.com/mx/artist/adele/262836961',
    soundcloud: 'https://soundcloud.com/adelemusic',
    tidal: 'https://listen.tidal.com/artist/3521920',
    wiki: 'https://es.wikipedia.org/wiki/Adele',
    ticketmaster: 'https://www.ticketmaster.com/adele-tickets/artist/1159272',
    tiktok: 'https://www.tiktok.com/@adele.offi?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0014DEFTE/adele',
  },
  {
    title: 'Avril Lavigne',
    description:
      'Avril Ramona Lavigne es una cantante, compositora, diseñadora de moda, productora musical, actriz y filántropa franco-canadiense. Algunos críticos de revistas como Billboard la llaman la princesa del pop punk. Comenzó su carrera musical en diciembre de 2001, cuando después de una presentación en una feria country, despertó el interés del productor L.A. Reid y firmó para Arista Records.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lXBMcqqDcHMW9fNqwE6UNC05-8DORHzbI',
    price: '$50',
    coverImg: 'AvrilLavigne.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://lovesux.avrillavigne.com/?ref=https://www.bing.com/',
    youtube: 'https://www.youtube.com/channel/UC_k8KFBzEf0uQ8LRFSAgSUA',
    facebook: 'https://www.facebook.com/avrillavigne',
    instagram: 'https://www.instagram.com/AvrilLavigne/',
    twitter: 'https://twitter.com/AvrilLavigne',
    spotify: 'https://open.spotify.com/artist/0p4nmQO2msCgU4IF37Wi3j',
    deezer: 'https://www.deezer.com/mx/artist/35',
    apple: 'https://music.apple.com/mx/artist/avril-lavigne/459885',
    soundcloud: 'https://soundcloud.com/avrillavigne',
    tidal: 'https://listen.tidal.com/artist/1567',
    wiki: 'https://es.wikipedia.org/wiki/Avril_Lavigne',
    ticketmaster:
      'https://www.ticketmaster.com/avril-lavigne-tickets/artist/833634',
    tiktok: 'https://www.tiktok.com/@avrillavigne?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSH7L0/avril-lavigne',
  },
  {
    title: 'Grimes',
    description:
      'Claire Elise Boucher ; nacida el 18 de marzo de 1988, conocida profesionalmente como Grimes, es una músico, cantante, compositora y productora discográfica canadiense. Su trabajo temprano ha sido descrito como que abarca desde "R&B lo-fi" hasta dance-pop futurista, e ha incorporado influencias de la música electrónica, hip hop y rock. Sus letras a menudo tratan temas de ciencia ficción y feminismo. Ha lanzado cinco álbumes de estudio.Nacida y criada en Vancouver, Grimes comenzó a lanzar música de manera independiente después de mudarse a Montreal en 2006. Luego lanzó dos álbumes, "Geidi Primes" y "Halfaxa", en 2010 con Arbutus Records y posteriormente firmó con 4AD y se destacó con el lanzamiento de su álbum de estudio de 2012, "Visions". "Visions" incluye los sencillos "Genesis" y "Oblivion" y recibió el premio Juno de la industria musical canadiense al Mejor Álbum Electrónico del Año..',
    videourl:
      'https://youtube.com/playlist?list=UULFRXaOr2-9CJTMqQH5A5GYZQ&si=o0tyNa7Pt_Aj4xCJ',
    price: '$50',
    coverImg: 'grimes.jpg',
    category: 'Lo-Fi',
    location: 'Canada',
    locationImg: 'canada.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://elf.tech/connect',
    youtube: 'https://www.youtube.com/@grimes',
    facebook: 'https://www.facebook.com/Grimes',
    instagram: 'https://www.instagram.com/grimes/',
    twitter: 'https://twitter.com/GRIMES_V1',
    spotify: 'https://open.spotify.com/artist/053q0ukIDRgzwTr4vNSwab',
    deezer: 'https://www.deezer.com/mx/artist/807493',
    apple: 'https://music.apple.com/mx/artist/grimes/2756920',
    soundcloud: 'https://soundcloud.com/actuallygrimes',
    tidal: 'https://listen.tidal.com/artist/3819688',
    wiki: 'https://en.wikipedia.org/wiki/Grimes',
    ticketmaster: 'https://www.ticketmaster.com/grimes-tickets/artist/1731174',
    tiktok: 'https://www.tiktok.com/@grimes',
    napster: 'https://music.amazon.com.mx/artists/B0010X3K6W/grimes',
  },
  {
    title: 'Taylor Swift',
    description:
      'Taylor Alison Swift (Reading, Pensilvania; 13 de diciembre de 1989) es una cantautora, productora, actriz, directora, diseñadora y empresaria estadounidense.Criada en Wyomissing (Pensilvania), se mudó a Nashville (Tennessee) a los 14 años para realizar una carrera de música country. Firmó con la compañía discográfica independiente Big Machine Records y se convirtió en la artista más joven contratada por Sony/ATV Music Publishing House. En 2006 lanzó su álbum debut homónimo Taylor Swift, el cual la estableció como una estrella en la música country. «Our Song», su tercer sencillo, la convirtió en la persona más joven en escribir temas sin ayuda de nadie e interpretar una canción número uno en la lista de Hot Country Songs. Recibió una nominación a mejor artista nuevo en los Premios Grammy de 2008.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kOjcMYd-zFhGK7iPzOb1m2-3ituAZSNe0',
    price: '$50',
    coverImg: 'TaylorSwift.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.taylorswift.com/',
    youtube: 'https://www.youtube.com/channel/UCqECaJ8Gagnn7YCbPEzWH6g',
    facebook: 'https://www.facebook.com/TaylorSwift',
    instagram: 'https://www.instagram.com/taylorswift/',
    twitter: 'https://twitter.com/taylorswift13',
    spotify: 'https://open.spotify.com/artist/06HL4z0CvFAxyc27GXpf02',
    deezer: 'https://www.deezer.com/mx/artist/12246',
    apple: 'https://music.apple.com/mx/artist/taylor-swift/159260351',
    soundcloud: 'https://soundcloud.com/taylorswiftofficial',
    tidal: 'https://listen.tidal.com/artist/3557299',
    wiki: 'https://es.wikipedia.org/wiki/Taylor_Swift',
    ticketmaster:
      'https://www.ticketmaster.com/taylor-swift-tickets/artist/1094215',
    tiktok: 'https://www.tiktok.com/@taylorswift',
    napster: 'https://music.amazon.com.mx/artists/B00157GJ20/taylor-swift',
  },
  { "title": "Ed Sheeran",
    "description": "Edward Christopher Sheeran, conocido como Ed Sheeran, nació el 17 de febrero de 1991 en Halifax, Inglaterra, y es un cantautor, músico y empresario británico. Desde niño comenzó a cantar en la iglesia y aprendió a tocar la guitarra, dejando la escuela secundaria a los 16 años para mudarse a Londres y perseguir una carrera artística. En 2011 lanzó de forma independiente un EP que llamó la atención de Elton John, quien lo ayudó a firmar con el sello Asylum Records, y ese mismo año publicó su álbum debut '+' que fue un éxito en las listas del Reino Unido con el sencillo 'The A Team', nominado a un Grammy. En 2012 ganó los Brit Awards como artista revelación y solista masculino británico y fue telonero de Taylor Swift en su gira Red Tour. Su segundo álbum 'x', lanzado en 2014, superó las ventas de su debut, alcanzando el número uno en Estados Unidos y Reino Unido, con diez millones de copias vendidas para 2016, mientras que el sencillo 'Thinking Out Loud' le valió dos premios Grammy. Su tercer álbum '÷' en 2017 debutó como el más vendido por un artista masculino en el Reino Unido y dominó las listas de éxitos en 14 países, con todos sus temas posicionados entre los primeros 20 sencillos en la semana de su lanzamiento, destacándose 'Castle on the Hill' y 'Shape of You'. Este álbum recibió el premio Grammy a Mejor Interpretación Vocal Pop. En 2021 lanzó su cuarto álbum '=' respaldado por los sencillos 'Bad Habits', 'Shivers' y 'Overpass Graffiti', aunque recibió críticas mixtas. Desde 2024 es accionista del Ipswich Town Football Club.",
    "videourl": "https://youtube.com/playlist?list=PLjp0AEEJ0-fGKG_3skl0e1FQlJfnx-TJz&si=ZdVKSWiNxe0idif1",
    "price": "$50",
    "coverImg": "edsheeran.jpg",
    "category": "Rock-Pop",
    "location": "UK",
    "locationImg": "england.jpg",
    "openSpots": null,
    "likes": 1000,
    "websiteurl": "https://www.edsheeran.com/",
     "youtube": "https://www.youtube.com/channel/UC0C-w0YjGpqDXGB8IHb662A",
      "facebook": "https://www.facebook.com/EdSheeranMusic",
      "instagram": "https://www.instagram.com/teddysphotos/",
      "twitter": "https://x.com/edsheeran",
      "spotify": "https://open.spotify.com/artist/6eUKZXaKkcviH0Ku9w2n3V",
      "deezer": "https://www.deezer.com/mx/artist/384236",
      "apple": "https://music.apple.com/mx/artist/ed-sheeran/183313439",
      "soundcloud": "https://soundcloud.com/edsheeran",
      "tidal": "https://listen.tidal.com/artist/3995478",
      "wiki": "https://es.wikipedia.org/wiki/Ed_Sheeran",
      "ticketmaster": "https://www.ticketmaster.com/ed-sheeran-tickets/artist/1560779",
      "tiktok": "https://www.tiktok.com/@edsheeran",
      "napster": "https://music.amazon.com.mx/artists/B0015H24HO/ed-sheeran"
    
  },
  {
    title: 'Ariana Grande',
    description:
      'Ariana Grande-Butera (Boca Ratón, Florida, 26 de junio de 1993)2​ es una cantante, empresaria y actriz estadounidense.3​ Comenzó su carrera musical en Brodway a los 15 años en el musical 13 (2008). Saltó a la fama por interpretar a Cat Valentine en las series de televisión de Nickelodeon Victorious (2010-2013) y Sam & Cat (2013-2014). Grande firmó con Republic Records en 2011 después de que los ejecutivos del sello vieran vídeos de YouTube de sus versiones de canciones. Su álbum debut de pop y R&B influenciado por el doo wop de la década de 1950,4​ Yours Truly (2013), encabezó el Billboard 200 de Estados Unidos, mientras que su sencillo principal, «The Way», llegó al top 10 del Billboard Hot 100 de Estados Unidos. La voz y el registro de silbidos de Grande en el álbum generaron comparaciones inmediatas con Mariah Carey...',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mj1bt9ZXZPsL_92-NUh6nNESsw0w1QoAg&si=BkpanS4tOiwY-I6o',
    price: '$50',
    coverImg: 'arianagrande.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.arianagrande.com/',
    youtube: 'https://www.youtube.com/c/arianagrande',
    facebook: 'https://www.facebook.com/arianagrande',
    instagram: 'https://www.instagram.com/arianagrande/',
    twitter: 'https://twitter.com/ArianaGrande',
    spotify: 'https://open.spotify.com/artist/66CXWjxzNUsdJxJ2JdwvnR',
    deezer: 'https://www.deezer.com/search/Ariana%20Grande',
    apple: 'https://music.apple.com/mx/artist/ariana-grande/412778295',
    soundcloud: 'https://soundcloud.com/arianagrande',
    tidal: 'https://listen.tidal.com/artist/4332277',
    wiki: 'https://es.wikipedia.org/wiki/Ariana_Grande',
    ticketmaster:
      'https://www.ticketmaster.com/ariana-grande-tickets/artist/1688071',
    tiktok: 'https://www.tiktok.com/@arianagrande',
    napster: 'https://music.amazon.com.mx/artists/B004IUAQ9S/ariana-grande',
  },
  {
    title: 'Billie Eilish',
    description:
      'Billie Eilish Pirate Baird O Connell (Los Ángeles, 18 de diciembre de 2001), conocida simplemente como Billie Eilish, es una cantante y actriz estadounidense. Adquirió fama como artista cuando tenía 13 años, a raíz del sencillo «Ocean Eyes» que se publicó en 2015 en SoundCloud y volvió a lanzarse con un vídeo musical en YouTube en 2016, a la edad de 14 años, lo que la convirtió en un fenómeno viral. En 2017, publicó su EP Dont Smile at Me, producido por su hermano Finneas O Connell..',
    videourl:
      'https://youtube.com/playlist?list=PLsCPTY_MPoPbCftVtuvquFs9ayLDwD237&si=G_KtiHN0fRU8EeDC',
    price: '$50',
    coverImg: 'billieeilish.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billieeilish.com/#/',
    youtube: 'https://www.youtube.com/channel/UCiGm_E4ZwYSHV3bcW1pnSeQ',
    facebook: 'https://www.facebook.com/TaylorSwift',
    instagram: 'https://www.instagram.com/taylorswift/',
    twitter: 'https://twitter.com/taylorswift13',
    spotify:
      'https://open.spotify.com/artist/6qqNVTkY8uBg9cP3Jd7DAH?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/9635624',
    apple: 'https://music.apple.com/mx/artist/billie-eilish/1065981054',
    soundcloud: 'https://soundcloud.com/billieeilish',
    tidal: 'https://listen.tidal.com/album/107563608/track/107563609',
    wiki: 'https://es.wikipedia.org/wiki/Billie_Eilish',
    ticketmaster:
      'https://www.ticketmaster.com/billie-eilish-tickets/artist/2257710',
    tiktok: 'https://www.tiktok.com/@billieeilish',
    napster: 'https://music.amazon.com.mx/artists/B01A7VBHJ4/billie-eilish',
  },
  {
    title: 'Quevedo',
    description:
      'Pedro Luis Domínguez Quevedo nació en Madrid .A la edad de un año, se trasladó a Brasil y regresó a España a la edad de cinco, instalándose en Las Palmas , Islas Canarias.Después de incursionar en el rap de estilo libre , Quevedo lanzó su carrera musical en 2020 junto al productor musical Linton. Su sencillo Ahora y Siempre (2021) alcanzó el decimonoveno y segundo puesto en las listas Global Viral 50 de Spotify y España Viral 50, respectivamente.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUd88g-4QLth1IBBRt38xvX',
    price: 50,
    coverImg: 'quevedo.jpg',
    category: 'Rap Latino',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: null,
    websiteurl:
      'https://genius.com/Bizarrap-and-quevedo-quevedo-bzrp-music-sessions-vol-52-lyrics',
    youtube: 'https://www.youtube.com/channel/UCYEmGiiM21tGuCEtDJTfP1w',
    facebook: 'https://www.facebook.com/bizarrap',
    instagram: 'https://www.instagram.com/quevedo.pd/',
    twitter: 'https://twitter.com/pedrodquevedo',
    spotify:
      'https://open.spotify.com/artist/52iwsT98xCoGgiGntTiR7K?si=woV5b46PT7aKn3z1nmc7fg&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/12487862',
    apple: 'https://music.apple.com/mx/artist/quevedo/377761784',
    soundcloud: 'https://soundcloud.com/quevedo-music',
    tidal: 'https://listen.tidal.com/artist/8997487',
    wiki: 'https://en.wikipedia.org/wiki/Quevedo_(rapper)',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Musica-Electronica/Bizarrap-Boletos',
    tiktok: 'https://www.tiktok.com/@bizarrap?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0029L8HTY/quevedo',
  },
  {
    title: 'Post Malone',
    description:
      'Austin Richard Post (Siracusa, Nueva York, Estados Unidos; 4 de julio de 1995), más conocido por su nombre artístico Post Malone, es un cantante y compositor estadounidense.1​ Ganó un gran reconocimiento en agosto de 2015 gracias al sencillo debut «White Iverson». Más adelante pasó a lanzar «Congratulations» y «Rockstar», que, respectivamente, alcanzaron los números 8 y 1 en el Billboard Hot 100, de los Estados Unidos. Posteriormente consiguió un contrato discográfico con Republic Records.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUKA-troAfBM-xSZzJAz5Uw',
    price: '$50',
    coverImg: 'postmalone.jpg',
    category: 'Pop Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.postmalone.com/',
    youtube: 'https://www.youtube.com/channel/UCeLHszkByNZtPKcaVXOCOQQ',
    facebook: 'https://www.facebook.com/postmalone',
    instagram: 'https://www.instagram.com/postmalone/',
    twitter: 'https://twitter.com/postmalone',
    spotify: 'https://open.spotify.com/artist/246dkjvS1zLTtiykXe5h60',
    deezer: 'https://www.deezer.com/mx/artist/7543848',
    apple: 'https://music.apple.com/mx/artist/post-malone/966309175',
    soundcloud: 'https://soundcloud.com/postmalone',
    tidal: 'https://listen.tidal.com/artist/6521080',
    wiki: 'https://es.wikipedia.org/wiki/Post_Malone',
    ticketmaster:
      'https://www.ticketmaster.com/post-malone-tickets/artist/2119390',
    tiktok: 'https://www.tiktok.com/@postmalone?lang=es',
    napster: 'https://us.napster.com/artist/post-malone',
  },
  {
    title: 'Peso Pluma',
    description:
      'Hassan Emilio Kabande Laija (Zapopan, Jalisco; 15 de junio de 1999), conocido como Peso Pluma, es un cantante mexicano. Se especializa en el subgénero de corridos tumbados.Su fama internacional comenzó en 2022, después de colaborar con cantantes como Luis R. Conriquez y Natanael Cano.3​ Algunos de sus éxitos son «El Belicón», «Siempre pendientes», «PRC», «Por las noches», «AMG», «Ella baila sola» y «La bebé (Remix)». Debido a su fama ha logrado colaborar también con artistas de éxito mundial como Becky G,5​ Nicki Nicole6​, Marshmello,7​y Ovy on the Drums.Su estilo musical se caracteriza por un toque acústico con influencias de la música urbana como el trap mezclados con sonido de los corridos',
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlXbLwh-AdLa1iHtuW_y_J9b',
    price: 80,
    coverImg: 'pesopluma.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.rioranchoeventscenter.com/event/peso-pluma/',
    youtube: 'https://www.youtube.com/channel/UCzrM_068Odho89mTRrrxqbA',
    facebook: 'https://www.facebook.com/PesoPlumaOficial',
    instagram: 'https://www.instagram.com/pesopluma/',
    twitter: 'https://twitter.com/ElPesoPluma',
    spotify:
      'https://open.spotify.com/artist/12GqGscKJx3aE4t07u7eVZ?si=u4gDkNCrR_-gnhy-aFtWJQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/80365122',
    apple: 'https://music.apple.com/mx/artist/peso-pluma/1500139475?l=en',
    soundcloud: 'https://soundcloud.com/pesopluma',
    tidal: 'https://listen.tidal.com/artist/17614478',
    wiki: 'https://es.wikipedia.org/wiki/Peso_Pluma_(cantante)',
    ticketmaster:
      'https://www.ticketmaster.com/peso-pluma-tickets/artist/2979041',
    tiktok: 'https://www.tiktok.com/@therealpesopluma',
    napster: 'https://music.amazon.com.mx/artists/B082C6RXYG',
  },
  {
    title: 'Junior H',
    description:
      'Antonio Herrera Pérez (Cerano, Guanajuato, México; 23 de abril de 2000) mejor conocido como Junior H, es un cantante y compositor mexicano. Se especializa en el subgénero de corridos tumbados..',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVQKFIhIcWGLTCXsFlB7b5K',
    price: 80,
    coverImg: 'juniorh.jpeg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl:
      'https://elpais.com/mexico/2023-05-17/junior-h-el-chico-triste-que-triunfa-a-base-de-corridos-tumbados.html',
    youtube: 'https://www.youtube.com/channel/UCwA5HHGsAlpU1UlhwWD52uA',
    facebook: 'https://www.facebook.com/juniorhoficial/photos',
    instagram: 'https://www.instagram.com/juniorh/',
    twitter: 'https://twitter.com/juniorh_oficial',
    spotify: 'https://open.spotify.com/artist/7Gi6gjaWy3DxyilpF1a8Is',
    deezer: 'https://www.deezer.com/mx/artist/54047652',
    apple: 'https://music.apple.com/mx/artist/junior-h/1442059565',
    soundcloud: 'https://soundcloud.com/juniorh-music',
    tidal: 'https://listen.tidal.com/artist/10590337',
    wiki: 'https://es.wikipedia.org/wiki/Junior_H',
    ticketmaster:
      'https://www.ticketmaster.com/junior-h-tickets/artist/2820826',

    tiktok: 'https://www.tiktok.com/@juniorh_h',
    napster: 'https://music.amazon.com.mx/artists/B07KFMJCTH/junior-h',
  },
  {
    title: 'Luis R Conriquez',
    description:
      'Luis R Conriquez es un compositor que se hizo conocido entre el público gracias a su estilo interpretativo, la honestidad de sus letras, sus mensajes de superación personal y un talento innato para componer corridos modernos',
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVhgiYg1lFWJMnQ2A_3COmX',
    price: 80,
    coverImg: 'luisrconriquez.jpeg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://kartelmusic.com/',
    youtube: 'https://www.youtube.com/@kartelmusicoficial',
    facebook: 'https://www.facebook.com/profile.php?id=null50488239777',
    instagram: 'https://www.instagram.com/luisrconriquez.fan.page/',
    twitter: 'https://twitter.com/LuisRConriquez',
    spotify: 'https://open.spotify.com/artist/0pePYDrJGk8gqMRbXrLJC8',
    deezer: 'https://www.deezer.com/en/artist/14004075',
    apple: 'https://music.apple.com/mx/artist/luis-r-conriquez/1252271456',
    soundcloud: 'https://soundcloud.com/luisrconriquez',
    tidal: 'https://listen.tidal.com/artist/9471448',
    wiki: 'https://en.wikipedia.org/wiki/Luis_R_Conriquez',
    ticketmaster:
      'https://www.ticketmaster.com/luis-r-conriquez-tickets/artist/2832072',
    tiktok: 'https://www.tiktok.com/@luisrconriquezoficial?lang=es',
    napster: 'https://us.napster.com/artist/luis-r-conriquez',
  },
  {
    title: 'Pedro Infante',
    description:
      'Pedro Infante Cruz (Mazatlán, Sinaloa, 18 de noviembre de 1917-Mérida, Yucatán, 15 de abril de 1957) fue un actor y cantante mexicano. Es considerado como uno de los actores más recordados del cine en México.Por su actuación en la película Tizoc (1956), fue ganador de un Oso de Plata​ del Festival Internacional de Cine de Berlín en la categoría a «mejor actor principal», y también obtuvo un premio Globo de Oro​ por mejor película extranjera, el cual se le fue otorgado por la prensa extranjera acreditada en Hollywood..',

    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVODBGJurCWQdYWZ7VH_Nhd',
    price: '$50',
    coverImg: 'pedroinfante.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl:
      'https://www.biografiasyvidas.com/biografia/i/infante_pedro.htm#:~:text=Pedro%20Infante%20%28Mazatl%C3%A1n%2C%20Sinaloa%2C%201917%20-%20M%C3%A9rida%2C%20Yucat%C3%A1n%2C,musical%20que%20actuaba%20en%20la%20localidad%20de%20Guasave.',
    youtube: 'https://www.youtube.com/channel/UCCZRmXAChQJF1P6xsX28TTQ',
    facebook: 'https://www.facebook.com/profile.php?id=null52259945722',
    instagram: 'https://www.instagram.com/pedro_infante_official/',
    twitter: 'https://twitter.com/Pedro_Infante1',
    spotify: 'https://open.spotify.com/artist/7y33enVLfDvft6HGNmcxdV',
    deezer: 'https://www.deezer.com/mx/artist/264106',
    apple: 'https://music.apple.com/mx/artist/pedro-infante/2137889',
    soundcloud: 'https://soundcloud.com/pedro-infante-official',
    tidal: 'https://listen.tidal.com/artist/13256',
    wiki: 'https://es.wikipedia.org/wiki/Pedro_Infante',
    ticketmaster:
      'https://www.noroeste.com.mx/entretenimiento/espectaculos/llenaran-de-tributos-a-pedro-infante-PVNO1078632',
    napster: 'https://web.napster.com/artist/pedro-infante',
    tiktok: 'https://www.tiktok.com/@pedroinfanteidolo',
  },
  {
    title: 'Jorge Negrete',
    description:
      'Jorge Alberto Negrete Moreno (Guanajuato, 30 de noviembre de 1911-Los Ángeles, California, 5 de diciembre de 1953), conocido como El Charro Cantor, fue un actor y cantante mexicano. Fundó el Sindicato de Trabajadores de la Producción Cinematográfica de la República Mexicana1​ y reorganizó, junto con un grupo selecto de actores, la Asociación Nacional de Actores.',

    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVngSBUUO1Yyb7E9KNPUvjl',
    price: '$50',
    coverImg: 'jorgenegrete2.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: '.',
    youtube: 'https://www.youtube.com/c/jorgenegretefans',
    facebook: 'https://www.facebook.com/profile.php?id=null59758101482',
    instagram: 'https://www.instagram.com/jorgenegreteforever/',
    twitter: 'https://twitter.com/DonJorgeNegrete',
    spotify: 'https://open.spotify.com/artist/4W5RbLZybsLfAzE7QqdibE',
    deezer: 'https://www.deezer.com/mx/artist/299515',
    soundcloud: 'https://soundcloud.com/jorgenegreteofficial',
    tidal: 'https://listen.tidal.com/artist/3661015',
    wiki: 'https://es.wikipedia.org/wiki/Jorge_Negrete',
    ticketmaster:
      'https://www.ticketmaster.com.mx/teatro-jorge-negrete-boletos-mexico/venue/163990',
    napster: 'https://web.napster.com/artist/art.50312',
    tiktok: 'https://www.tiktok.com/search?q=Jorge%20Negrete&t=1667333198086',
  },{
    title: 'Angela Aguilar',
    description:
      'Ángela Aguilar Álvarez (Los Ángeles, California; 8 de octubre de 2003), es una cantante mexicana-estadounidense de música regional mexicana. Obtuvo un notable reconocimiento después de interpretar La Llorona en la 19.ª Entrega Anual de los Premios Grammy Latinos en 2018. Es nieta de El Charro de México, Antonio Aguilar.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kSBDJlpXRi2Cy2v5fYI8ag4M1ku2STWAE',
    price: '$50',
    coverImg: 'AngelaAguilar.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.angelaaguilaroficial.com/',
    youtube: 'https://www.youtube.com/channel/UCugX5B6n1jtQq2ihzhRk-Ag',
    facebook: 'https://www.facebook.com/AngelaAguilarOficial/',
    instagram: 'https://www.instagram.com/angela_aguilar_/?hl=en',
    twitter: 'https://twitter.com/AngelaAguilar__',
    spotify: 'https://open.spotify.com/artist/3abT87tqQ4Q5PA5nw6CYyH',
    deezer: 'https://www.deezer.com/mx/artist/4028765',
    apple: 'https://music.apple.com/mx/artist/%C3%A1ngela-aguilar/565880444',
    soundcloud: 'https://soundcloud.com/angelaaguilarofficial',
    tidal: 'https://listen.tidal.com/artist/4734467',
    wiki: 'https://en.wikipedia.org/wiki/%C3%81ngela_Aguilar',
    ticketmaster:
      'https://www.ticketmaster.com/angela-aguilar-tickets/artist/2584974',

    tiktok: 'https://www.tiktok.com/@angelaaguilar676',
    napster: 'https://music.amazon.com.mx/artists/B009IAAD46/angela-aguilar',
  },
  {
    title: 'Mon LaFerte',
    description:
      'Norma Monserrat Bustamante Laferte (Viña del Mar; 2 de mayo de 1983), conocida como Mon Laferte, es una cantautora chilenomexicana.1​ Toca diferentes instrumentos y se caracteriza por la confluencia de numerosas formas y géneros como la balada, el pop, el heavy metal —principalmente a raíz de su actividad como cantante entre 2012 y 2014 de las bandas Mystica Girls y Abaddon—,7​ el bolero, el vals, la cumbia, el reggae o el ska, entre otros.8Ha sido la artista chilena con más nominaciones en una sola edición de los Grammy Latinos (cinco en 2017),9​ así como la primera en conseguir más de estas en los mencionados premios (quince en seis ocasiones).9​10​11​ Mon ha vendido más de 1,5 millones de grabaciones en todo el mundo, entre álbumes y sencillos, lo que la convierte en la artista chilena con más ventas de la era digital.',

    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_nQQXiY63k7z5AIcVXsvMnEVoWPBPRB4Js',
    price: '$50',
    coverImg: 'MonLaFerte.jpg',
    category: 'Baladas',
    location: 'Chile',
    locationImg: 'chileflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.cmtv.com.ar/biografia/show.php?bnid=2474&banda=Mon_Laferte',
    youtube: 'https://www.youtube.com/channel/UCn51E6eKnnLklWOcdFT3w5A',
    facebook: 'https://www.facebook.com/monlaferte/',
    instagram: 'https://www.instagram.com/monlaferte/',
    twitter: 'https://twitter.com/monlaferte',
    spotify:
      'https://open.spotify.com/artist/4boI7bJtmB1L3b1cuL75Zr?si=DMp24bVrT361wHaANVSQhg&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/5284515',
    apple: 'https://music.apple.com/mx/artist/mon-laferte/447436990',
    soundcloud: 'https://soundcloud.com/monlaferte',
    tidal: 'https://listen.tidal.com/artist/5635583',
    wiki: 'https://es.wikipedia.org/wiki/Mon_Laferte',
    ticketmaster:
      'https://www.ticketmaster.com/mon-laferte-tickets/artist/2144574',
    tiktok: 'https://www.tiktok.com/@mon.laferte',
    napster: 'https://music.amazon.com.mx/artists/B0059FCKVS/mon-laferte',
  },

  {
    title: 'Julieta Venegas',
    description:
      'Julieta Venegas Percevault9​(Long Beach, California;10​11​ 24 de noviembre de 1970) es una cantante, compositora, multinstrumentista, productora, actriz y activista mexicana catalogada como una «leyenda» de la música en español. Es una de las artistas más reconocidas a nivel mundial.Fue integrante de la banda mexicana de ska y reggae Tijuana No!, en la que compuso la canción más exitosa de la agrupación: «Pobre de ti», junto a Álex Zúñiga. En 1996 comenzó su carrera como solista cuando viajó a la Ciudad de México apoyada por la banda Café Tacvba.19​ Consiguió un contrato con el sello discográfico BMG/Ariola, lanzando su álbum debut Aquí (1997) al igual que su siguiente producción Bueninvento (2000) discos producidos por Gustavo Santaolalla, los cuales fueron alabados por los críticos y propagándose en el público del rock en México, Centroamérica y América del Sur.',
    videourl: 'https://youtube.com/playlist?list=PL9783B3ED0003A1B3',
    price: '$50',
    coverImg: 'julietavenegas.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.julietavenegas.net/',
    youtube: 'https://www.youtube.com/channel/UCwWwGhSVOjbhZSBh-Wa3xTw',
    facebook: 'https://www.facebook.com/julietavenegas.oficial',
    instagram: 'https://www.instagram.com/julietavenegasp/',
    twitter: 'https://twitter.com/julietav',
    spotify: 'https://open.spotify.com/artist/2QWIScpFDNxmS6ZEMIUvgm',
    deezer: 'https://www.deezer.com/mx/artist/4477',
    apple: 'https://music.apple.com/mx/artist/julieta-venegas/302960',
    soundcloud: 'https://soundcloud.com/julietav',
    tidal: 'https://listen.tidal.com/artist/4322',
    wiki: 'https://es.wikipedia.org/wiki/Julieta_Venegas',
    ticketmaster:
      'https://www.ticketmaster.com/julieta-venegas-tickets/artist/821476',
    tiktok: 'https://www.tiktok.com/@julietavenegasoficial',
    napster: 'https://music.amazon.com.mx/artists/B000QJQM0C/julieta-venegas',
  },
  {
    title: 'Alejandra Guzman',
    description:
      'Gabriela Alejandra Guzmán Pinal (Ciudad de México; 9 de febrero de 1968), es una cantante mexicana. Ha incursionado en la actuación de telenovelas, series y programas de televisión.Hija del cantante de rock and roll y actor venezolano Enrique Guzmán y de la actriz mexicana Silvia Pinal, es reconocida en Latinoamérica, Estados Unidos y Europa. Se estima que Alejandra Guzmán ha vendido más de treinta millones de discos desde su debut, lo que la convierte en una de las cantantes latinas con mayores ventas..',
    videourl: 'https://youtube.com/playlist?list=UULFuHEt7VkfYYY0_ucAuMJ3dg',
    price: 50,
    coverImg: 'alejandraguzman.jpg',
    category: 'Pop',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.aleguzman.com/',
    youtube: 'https://www.youtube.com/channel/UCuHEt7VkfYYY0_ucAuMJ3dg',
    facebook: 'https://www.facebook.com/laguzmanoficial',
    instagram: 'https://www.instagram.com/laguzmanmx/',
    twitter: 'https://twitter.com/Al3jandraGuzman',
    spotify: 'https://open.spotify.com/artist/0EmeFodog0BfCgMzAIvKQp',
    deezer: 'https://www.deezer.com/mx/artist/9033',
    apple: 'https://music.apple.com/mx/artist/alejandra-guzm%C3%A1n/248791',
    soundcloud: 'https://soundcloud.com/alejandraguzman-music',
    tidal: 'https://listen.tidal.com/artist/5463',
    wiki: 'https://es.wikipedia.org/wiki/Alejandra_Guzm%C3%A1n',
    ticketmaster:
      'https://www.ticketmaster.com./alejandra-guzman-tickets/artist/761876',
    tiktok: 'https://www.tiktok.com/@laguzmanoficial?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B000YPV0T6/alejandra-guzm%C3%A1n',
  },
  {
    title: 'Danna Paola',
    description:
      'Danna Paola Rivera Munguía (Ciudad de México, 23 de junio de 1995) es una cantante, actriz, modelo y compositora mexicana. Ha sido ganadora de diferentes premios, entre los que se encuentran 9 Kids Choice Awards, 4 MTV Millennial Awards, 4 MTV Italian Music Awards, 1 MTV Europe Music Awards y 2 premios de la Sociedad de Autores y Compositores de México por «Oye Pablo» y «Sodio». Además fue nominada al Grammy Latino por su sexto álbum de estudio K.O. en 2021..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mGnO3i2McuE6K6O4qSKx4F9shuqnG0bKg',
    price: '$50',
    coverImg: 'dannapaola.png',
    category: 'Bachatta',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://universalmusicmexico.lnk.to/TourXT4S1S',
    youtube: 'https://www.youtube.com/channel/UCDJT6nxGrFEU3vut0nfgVWQ',
    facebook: 'https://www.facebook.com/DannaPaola',
    instagram: 'https://www.instagram.com/dannapaola/',
    twitter: 'https://twitter.com/dannapaola',
    spotify: 'https://open.spotify.com/artist/5xSx2FM8mQnrfgM1QsHniB',
    deezer: 'https://www.deezer.com/mx/artist/318108',
    apple: 'https://music.apple.com/mx/artist/danna-paola/23148284',
    soundcloud: 'https://soundcloud.com/danna-paola-official',
    tidal: 'https://listen.tidal.com/artist/5237820',
    wiki: 'https://en.wikipedia.org/wiki/Danna_Paola',
    ticketmaster:
      'https://www.ticketmaster.com.mx/danna-paola-boletos/artist/2581279',
    tiktok: 'https://www.tiktok.com/@dannapaola?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000V65TKE/danna-paola',
  },
  {
    title: 'Alicia Keys',
    description:
      'Alicia Augello Cook (Nueva York, 25 de enero de 1981) conocida como Alicia Keys, es una cantante compositora, productora musical y actriz estadounidense. Ha vendido más de 75 millones de discos a nivel mundial y ha ganado numerosos premios, incluyendo 16 Grammys, 17 Premios Billboard y tres American Music Awards. Su álbum debut Songs in A Minor (2001) ganó cinco Grammys.',
    videourl:
      'https://youtube.com/playlist?list=PL9_UKVdymCPeiMreTKiHFcKIoXA9_moVN',
    price: '$50',
    coverImg: 'aliciakeys.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.aliciakeys.com/',
    youtube: 'https://www.youtube.com/channel/UCK5X3f0fxO4YnVKVZP8p6hg',
    facebook: 'https://www.facebook.com/aliciakeys/',
    instagram: 'https://www.instagram.com/aliciakeys/',
    twitter: 'https://twitter.com/aliciakeys',
    spotify: 'https://open.spotify.com/artist/3DiDSECUqqY1AuBP8qtaIa',
    deezer: 'https://www.deezer.com/mx/artist/228',
    apple: 'https://music.apple.com/mx/artist/alicia-keys/316069',
    soundcloud: 'https://soundcloud.com/aliciakeys',
    tidal: 'https://listen.tidal.com/artist/1552',
    wiki: 'https://es.wikipedia.org/wiki/Alicia_Keys',
    ticketmaster:
      'https://www.ticketmaster.com/alicia-keys-tickets/artist/807171',
    tiktok: 'https://www.tiktok.com/@aliciakeys',
    napster: 'https://music.amazon.com.mx/artists/B000RHRHPM/alicia-keys',
  },
  {
    title: 'Dido',
    description:
      'Florian Cloud de Bounevialle O Malley Armstrong (nacida el 25 de diciembre de 1971), conocida profesionalmente como Dido (/ˈdaɪdoʊ/ DY-doh), es una cantante y compositora inglesa. Alcanzó el éxito internacional con su álbum debut No Angel (1999). Los sencillos exitosos del álbum incluyen "Here with Me" y "Thank You". Vendió más de 21 millones de copias en todo el mundo, y le valió varios premios, incluidos dos Brit Awards; adicionalmente, ganó el premio al Mejor Álbum Británico y a la Mejor Intérprete Femenina Británica, así como el premio MTV Europe Music Award al Mejor Nuevo Artista. El primer verso de "Thank You" está sampleado en "Stan", una colaboración aclamada con el rapero estadounidense Eminem. Su próximo álbum, Life for Rent (2003), continuó su éxito con los sencillos "White Flag" y "Life for Rent".',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_k_kl9OOsob6Flw1jhuYyy5cxm5kUEwDaY&si=CDEfFO2Xjy0sohu7',
    price: '$50',
    coverImg: 'dido.jpg',
    category: 'Rock-Pop',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.didomusic.com/',
    youtube: 'https://www.youtube.com/user/Dido',
    facebook: 'https://www.facebook.com/dido/',
    instagram: 'https://www.instagram.com/dido',
    twitter: 'https://twitter.com/didoofficial',
    spotify: 'https://open.spotify.com/artist/2mpeljBig2IXLXRAFO9AAs',
    deezer: 'https://www.deezer.com/mx/artist/143',
    apple: 'https://music.apple.com/mx/artist/dido/20646',
    soundcloud: 'https://soundcloud.com/dido',
    tidal: 'https://listen.tidal.com/artist/1736',
    wiki: 'https://en.wikipedia.org/wiki/Dido_(singer)',
    ticketmaster: 'https://www.ticketmaster.com/dido-tickets/artist/768449',
    tiktok: 'https://www.tiktok.com/search?q=Dido&t=1693502040824',
    napster: 'https://music.amazon.com.mx/artists/B000VJUKO6/dido',
  },
  {
    title: 'Dua Lipa',
    description:
      'Dua Lipa (Londres, 22 de agosto de 1995) es una cantante, compositora, modelo y actriz británica de origen albanokosovar. Después de trabajar como modelo, firmó con Warner Bros. Records en 2014 y lanzó su álbum debut homónimo en 2017. El álbum alcanzó el número 3 en la UK Albums Chart y lanzó ocho sencillos, incluidos «Be the One» e «IDGAF», y el sencillo número 1 del Reino Unido «New Rules», que también llegó al puesto número 6 en Estados Unidos. En 2018, ganó dos premios Brit a la artista solista femenina británica y artista revelación británica.',
    videourl:
      'https://youtube.com/playlist?list=PLNrotoZZ8BaoXT_LJuwEyESQlctWNDCwD&si=yyEOmZRxEigJ3bgw',
    price: '$50',
    coverImg: 'dualipa.jpg',
    category: 'Pop Americano',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.dualipa.com/?frontpage=true',
    youtube: 'https://www.youtube.com/channel/UC-J-KZfRV8c13fOCkhXdLiQ',
    facebook: 'https://www.facebook.com/DuaLipa',
    instagram: 'https://www.instagram.com/dualipa/',
    twitter: 'https://twitter.com/DUALIPA',
    spotify: 'https://open.spotify.com/artist/6M2wZ9GZgrQXHCFfjv46we',
    deezer: 'https://www.deezer.com/mx/artist/8706544',
    apple: 'https://music.apple.com/mx/artist/dua-lipa/1031397873',
    soundcloud: 'https://soundcloud.com/dualipa',
    tidal: 'https://listen.tidal.com/artist/7162333',
    wiki: 'https://es.wikipedia.org/wiki/Dua_Lipa',
    ticketmaster:
      'https://www.ticketmaster.com/dua-lipa-tickets/artist/2179476',
    tiktok: 'https://www.tiktok.com/@dualipaofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0143OIF9G/dua-lipa',
  },
  
  {
    title: 'Jose Alfredo Jimenez',
    description:
      'José Alfredo Jiménez Sandoval (Dolores Hidalgo, Guanajuato, 19 de enero de 1926-Ciudad de México, 23 de noviembre de 1973) fue un cantante y compositor mexicano. Jiménez fue el autor de varios temas musicales del género regional mexicano, específicamente de rancheras, sones, corridos, y huapangos a ritmo de mariachi.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_k2kN1d58ypIxZcl6DEtIT_2tsuaZpeWJM',
    price: '$50',
    coverImg: 'JoseAlfredo.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://es.wikipedia.org/wiki/Jos%C3%A9_Alfredo_Jim%C3%A9nez',
    youtube: 'https://www.youtube.com/channel/UC3w7TN1QkWk9ecC0q_ebm7Q',
    facebook: 'https://www.facebook.com/JoseAlfredoJimenezElRey/',
    instagram: 'https://www.instagram.com/josealfredojimenezoficial/',
    twitter: 'https://twitter.com/JoseAlfredoJmz',
    spotify: 'https://open.spotify.com/artist/2T06whb4s6UiufL1j5Qtz9',
    deezer: 'https://www.deezer.com/mx/artist/343074',
    apple:
      'https://music.apple.com/mx/artist/jos%C3%A9-alfredo-jim%C3%A9nez/332254',
    soundcloud: 'https://soundcloud.com/josealfredojimenez',
    tidal: 'https://listen.tidal.com/artist/4029320',
    wiki: 'https://en.wikipedia.org/wiki/Jos%C3%A9_Alfredo_Jim%C3%A9nez',
    ticketmaster:
      'https://www.ticketmaster.com/jose-alfredo-jimenez-medel-tickets/artist/2036602',

    tiktok:
      'https://www.tiktok.com/search?q=Jos%C3%A9%20Alfredo%20Jim%C3%A9nez&t=1658091294171',
    napster: 'https://web.napster.com/artist/jose-alfredo-jimenez',
  },
  {
    title: 'Juan Gabriel',
    description:
      'Alberto Aguilera Valadez, conocido como Juan Gabriel, fue un cantante, compositor, arreglista, productor musical, músico, filántropo y actor mexicano. Son notables sus contribuciones a la música popular en español en diferentes géneros como balada, ranchera, bolero, pop, música norteña, rumba flamenca, huapango, música chicana, salsa, cumbia, tango, polka, samba, vals, son de mariachi, joropo, banda sinaloense, disco, jazz, new age, country, rock and roll, big band y hasta canciones de cuna que escribió a cada uno de sus hijos.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_ldwlb88-oemhNHFY2wJDvWnwu56vjdy2o',
    price: '$50',
    coverImg: 'JuanGabriel.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://juangabriel.com/',
    youtube: 'https://www.youtube.com/channel/UCnLJnKKCxLRsAktAB9iMSeQ',
    facebook: 'https://www.facebook.com/JuanGabrielPorLosSiglos',
    instagram: 'https://www.instagram.com/soyjuangabriel_/?hl=en',
    twitter: 'https://twitter.com/soyjuangabriel',
    spotify: 'https://open.spotify.com/artist/2MRBDr0crHWE5JwPceFncq',
    deezer: 'https://www.deezer.com/mx/artist/71813',
    apple: 'https://music.apple.com/mx/artist/juan-gabriel/295294',
    soundcloud: 'https://soundcloud.com/juan-gabriel-official',
    tidal: 'https://listen.tidal.com/artist/4347',
    wiki: 'https://en.wikipedia.org/wiki/Juan_Gabriel',
    ticketmaster:
      'https://www.ticketmaster.com/juan-gabriel-tickets/artist/761622',

    tiktok: 'https://www.tiktok.com/@soyjuangabriel',
    napster: 'https://web.napster.com/artist/juan-gabriel',
  },
  {
    title: 'Jose Jose',
    description:
      'José Rómulo Sosa Ortiz (Clavería, Azcapotzalco, Ciudad de México, 17 de febrero de 1948-Homestead, Florida, 28 de septiembre de 2019), conocido como José José, fue un cantante y actor mexicano. Se le considera como un icono musical',

    videourl: 'https://youtube.com/playlist?list=PL26274DCDCD4770D8',
    price: '$50',
    coverImg: 'josejose.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCSPwNgHHyAtHanVaGN2LLWA',
    facebook: 'https://www.facebook.com/OficialJoseJose',
    instagram: 'https://www.instagram.com/explore/tags/josejose/?hl=en',
    twitter: 'https://twitter.com/JoseJoseOficial',
    spotify: 'https://open.spotify.com/artist/4mN0qcMxWX8oToqfDPM5yV',
    deezer: 'https://www.deezer.com/mx/artist/71835',
    apple: 'https://music.apple.com/mx/artist/jos%C3%A9-jos%C3%A9/333303',
    soundcloud: 'https://soundcloud.com/jose-jose-official',
    tidal: 'https://listen.tidal.com/artist/4343',
    wiki: 'https://es.wikipedia.org/wiki/Jos%C3%A9_Jos%C3%A9',
    ticketmaster:
      'https://www.ticketmaster.com/jose-jose-tickets/artist/756645',
    tiktok: 'https://www.tiktok.com/@josejoseoficial',
    napster: 'https://web.napster.com/artist/jose-jose',
  },
  {
    title: 'Alex Fernandez',
    description:
      'Saltó a la fama en las redes sociales como hijo del reconocido cantante mexicano Alejandro Fernández. Ha heredado un seguimiento de Instagram de más de 650.000 fanáticos de miembros famosos de su familia. También es cantante y ha grabado dos discos. Nacido el 4 de noviembre de 1993, Alex Fernández Jr. es oriundo de México. Al igual que en 2022, la edad de Alex Fernández Jr. es de 29 años. Consulte a continuación para obtener más detalles sobre Alex Fernández Jr.. Esta página arrojará luz sobre la biografía, wiki, edad, cumpleaños, detalles familiares, aventuras, controversias, casta, altura, peso, rumores y hechos menos conocidos de Alex Fernández Jr. , y más.',
    videourl:
      'https://youtube.com/playlist?list=PLutFSHdaf1CL5TLFaNFDiAuYjsUoOlTKV',
    price: 50,
    coverImg: 'alexfernandez.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl:
      'https://www.seitrack.mx/alexfernandez?fbclid=IwAR0gfNb2bj5mfqgc1tcd88sERPHwgp5OY5qkOxjeBh8NuqCA74x8LkIGqBI',
    youtube: 'https://www.youtube.com/channel/UC5-ZtcsyTQNjrsR_9yRCZJg',
    facebook: 'https://www.facebook.com/AlexFdezMusica/',
    instagram: 'https://www.instagram.com/alexfernandez.g/',
    twitter: 'https://twitter.com/AlexFdezMusica',
    spotify: 'https://open.spotify.com/artist/4Xwvz864z2uP3bwPcjKJjC',
    deezer: 'https://www.deezer.com/mx/artist/9896010',
    apple: 'https://music.apple.com/mx/artist/alex-fern%C3%A1ndez/1453744801',
    soundcloud: 'https://soundcloud.com/alexfernandez-music',
    tidal: 'https://listen.tidal.com/artist/7622411',
    wiki: 'https://celebs.filmifeed.com/wiki/alex-fernandez-jr/',
    ticketmaster:
      'https://www.ticketmaster.com/alex-fernandez-tickets/artist/2729965',
    tiktok: 'https://www.tiktok.com/@alexfernandez.g?lang=es',
    napster: 'https://us.napster.com/artist/alex-fernandez-2',
  },

  {
    title: 'Cristian Nodal',
    description:
      'Christian Jesús González Nodal (Caborca , Sonora; 11 de enero de 1999), conocido como Christian Nodal, es un cantante, compositor mexicano. Su primer sencillo, «Adiós amor», publicado en 2016 bajo el sello discográfico Fonovisa, lo lanzó a la fama en diversos medios de comunicación en México y Estados Unidos.',
    videourl:
      'https://youtube.com/playlist?list=PL2cg72xd9U-ioO2IpH7OZrT43hVgC6fmm',
    price: 80,
    coverImg: 'nodal.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'http://www.christiannodal.com/',
    youtube: 'https://www.youtube.com/channel/UCtqOng3GVhQVu1Gl-3UlyAQ',
    facebook: 'https://www.facebook.com/christiannodaloficialmx/',
    instagram: 'https://www.instagram.com/nodal/',
    twitter: 'https://twitter.com/elnodal?lang=en',
    spotify:
      'https://open.spotify.com/artist/0XwVARXT135rw8lyw1EeWP?si=xMgobcktQD6v1nbtGIxGhQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/9763952',
    apple: 'https://music.apple.com/mx/artist/christian-nodal/1079597775',
    soundcloud: 'https://soundcloud.com/christiannodal',
    tidal: 'https://listen.tidal.com/artist/7745729',
    wiki: 'https://en.wikipedia.org/wiki/Christian_Nodal',
    ticketmaster:
      'https://www.ticketmaster.com/christian-nodal-tickets/artist/2405178',

    tiktok: 'https://www.tiktok.com/@christiannodal',
    napster: 'https://web.napster.com/artist/christian-nodal',
  },
  {
    title: 'Marco Antonio Solis',
    description:
      'Marco Antonio Solís Sosa es un cantautor, músico, compositor, actor y productor musical mexicano. Junto a su primo Joel Solís, fue fundador de la agrupación musical Los Bukis, del cual es vocalista, músico, productor y autor de la mayoría de sus canciones; al inicio tocaba los teclados y posteriormente los timbales y la guitarra. .',
    videourl:
      'http://youtube.com/playlist?list=PLl9yuNCZfI6pQFNQ90lEAdv8VoCOUvE8i',
    price: '$50',
    coverImg: 'MarcoAntonioSolis.jpg',
    category: 'Balada',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'http://www.marcoantoniosolis.com/',
    youtube: 'https://www.youtube.com/channel/UCIKg4QVg91aCWxXWlUkKDLw',
    facebook:
      'https://www.facebook.com/marcoantoniosolis/?eid=ARDZGcP8TaKskxpqO14J-o-2ZdAB_UU65p1ySVV2fFgb2ZsWGTAYo7NAzUnvpDSg9C_KCvXTmUYllfNG',
    instagram: 'https://www.instagram.com/marcoantoniosolis_oficial/',
    twitter: 'https://twitter.com/MarcoASolis',
    spotify: 'https://open.spotify.com/artist/3tJnB0s6c3oXPq1SCCavnd',
    deezer: 'https://www.deezer.com/mx/artist/9032',
    apple: 'https://music.apple.com/mx/artist/marco-antonio-sol%C3%ADs/332900',
    soundcloud: 'https://soundcloud.com/marco-antonio-solis-official',
    tidal: 'https://listen.tidal.com/artist/3675096',
    wiki: 'https://es.wikipedia.org/wiki/Marco_Antonio_Sol%C3%ADs',
    ticketmaster:
      'https://www.ticketmaster.com./marco-antonio-solis-tickets/artist/781016',
    tiktok: 'https://www.tiktok.com/@marcosantoniosolisoff?lang=es',
    napster: 'https://app.napster.com/artist/marco-antonio-solis',
  },

  {
    title: 'Joan Sebastian',
    description:
      'José Manuel Figueroa, conocido artísticamente como Joan Sebastian, fue un cantante, compositor y actor mexicano, que fue conocido por el público bajo los sobrenombres de «El Rey del Jaripeo»,«El Poeta del Pueblo», «El Poeta de Juliantla» y «El Huracán del Sur.',
    videourl:
      'http://youtube.com/playlist?list=PL9CTSVO6wu7LNGcWzK-VheoJ1NavmEFjZ',
    price: '$50',
    coverImg: 'JoanSebastian.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'http://joansebastianoficial.com/',
    youtube: 'https://www.youtube.com/channel/UC9oI0Vxnp-jJRH2StsXaIzQ',
    facebook: 'https://www.facebook.com/oficialjoansebastian',
    instagram: 'https://www.instagram.com/joansebastian/?hl=es-la',
    twitter: 'https://twitter.com/joansebastianof',
    spotify:
      'https://open.spotify.com/artist/7FsRH5bw8iWpSbMX1G7xf1?si=HX11vdfgQXuFFy6h5bdteg&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/12141',
    apple: 'https://music.apple.com/mx/artist/joan-sebastian/295941',
    soundcloud: 'https://soundcloud.com/el-mimoso-luis-antonio-lopez',
    tidal: 'https://listen.tidal.com/artist/4672027',
    wiki: 'https://es.wikipedia.org/wiki/Joan_Sebastian',
    ticketmaster:
      'https://www.ticketmaster.com/joan-sebastian-tickets/artist/755195',
    tiktok: 'https://www.tiktok.com/@joansebastianoficial?lang=es',
    napster: 'https://web.napster.com/artist/joan-sebastian',
  },

  {
    title: 'Reyli',
    description:
      'Reyli Barba Arrocha es un cantautor y actor mexicano. Es conocido por haber sido el primer vocalista de la agrupación Elefante; siendo reemplazado por Jorge Guevara a partir de 2004. Desde su separación del grupo, trabaja como solista..',
    videourl: 'http://youtube.com/playlist?list=PL37D2450D17D43D48',
    price: '$50',
    coverImg: 'Reyli.jpg',
    category: 'Balada',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.reylibarbamusic.com/',
    youtube: 'https://www.youtube.com/channel/UCVk903Ollml-lwINrNo6K6w',
    facebook: 'https://www.facebook.com/ReyliBarbaOficial',
    instagram: 'https://www.instagram.com/reylibarbaoficial/',
    twitter: 'https://twitter.com/Reyli_Barba',
    spotify: 'https://open.spotify.com/artist/69BUYvpG9MbjCyIZfsFdhJ',
    deezer: 'https://www.deezer.com/mx/artist/194042',
    apple: 'https://music.apple.com/mx/artist/reyli-barba/20456368',
    soundcloud: 'https://soundcloud.com/reylibarba',
    tidal: 'https://listen.tidal.com/artist/3602829',
    wiki: 'https://es.wikipedia.org/wiki/Reyli_Barba',
    ticketmaster:
      'https://www.ticketmaster.com.mx/reyli-barba-boletos/artist/1197375',
    tiktok: 'https://www.tiktok.com/@reylibarbaoficial?lang=es',
    napster: 'https://app.napster.com/artist/reyli-barba',
  },

  {
    title: 'Michael Jackson',
    description:
      'Michael Joseph Jackson (Gary, Indiana, 29 de agosto de 1958-Los Ángeles, 25 de junio de 2009),1​ fue un cantante, compositor, productor y bailarín estadounidense.2​3​4​ Apodado como el Rey del Pop,5​ sus contribuciones y reconocimiento en la historia de la música y el baile durante más de cuatro décadas, así como su publicitada vida personal, lo convirtieron en una figura internacional en la cultura popular. Su música incluye una amplia acepción de géneros como el pop, rhythm and blues (soul y funk), rock, disco y dance, y es reconocido como el «artista musical más exitoso de todos los tiempos» por los Guinness World Records.',
    videourl:
      'https://youtube.com/playlist?list=PLUeQUfurHN6bjde_KttpUONL9C0wjJvGF',
    price: '$50',
    coverImg: 'michaelJackson.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.michaeljackson.com/',
    youtube: 'https://www.youtube.com/channel/UC5OrDvL9DscpcAstz7JnQGA',
    facebook: 'https://www.facebook.com/michaeljackson',
    instagram: 'https://www.instagram.com/michaeljackson/',
    twitter: 'https://twitter.com/michaeljackson',
    spotify: 'https://open.spotify.com/artist/3fMbdgg4jU18AjLCKBhRSm',
    deezer: 'https://www.deezer.com/mx/artist/259',
    apple: 'https://music.apple.com/mx/artist/michael-jackson/32940',
    soundcloud: 'https://soundcloud.com/mjimmortal',
    tidal: 'https://listen.tidal.com/artist/606',
    wiki: 'https://es.wikipedia.org/wiki/Michael_Jackson',
    ticketmaster:
      'https://www.ticketmaster.com/michael-jackson-tribute-tickets/artist/1454599',
    tiktok: 'https://www.tiktok.com/@michael_jackson_08?lang=es',
    napster: 'https://us.napster.com/artist/michael-jackson',
  },
  {
    title: 'Prince',
    description:
      'Prince Rogers Nelson (7 de junio de 1958 - 21 de abril de 2016), más comúnmente conocido como Prince , fue un cantautor, músico y productor discográfico estadounidense. Ganador de numerosos premios y nominaciones , es ampliamente considerado como uno de los mejores músicos de su generación. [3] Era conocido por su personalidad extravagante y andrógina ; [4] [5] su amplio rango vocal, que incluía un falsete de largo alcance y gritos agudos; y su habilidad como multiinstrumentista, a menudo prefiriendo tocar todos o la mayoría de los instrumentos en sus grabaciones. [6] Prince produjo sus propios álbumes, siendo pionero en laSonido de Mineápolis . Su música incorporó una amplia variedad de estilos, incluyendo funk , R&B , rock , new wave , soul , synth-pop , pop , jazz y hip hop ..',
    videourl:
      'https://youtube.com/playlist?list=PLrwXzbX3SWntQDX7LNhvTSVuXU_bujnLw',
    price: '$50',
    coverImg: 'prince.jpeg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl:
      'https://www.prince.com/?fbclid=IwAR0rvAyV-OG2baN0CTL4YwCp9LJe4mKeKFKkKohULZ1Mw2QviE41O0pGOWg',
    youtube: 'https://www.youtube.com/channel/UCv3mNSNjuWldihk1DUdnGtw',
    facebook: 'https://www.facebook.com/prince',
    instagram: 'https://store.prince.com/store',
    twitter: 'https://twitter.com/prince',
    spotify: 'https://open.spotify.com/artist/5a2EaR3hamoenG9rDuVn8j',
    deezer: 'https://www.deezer.com/mx/artist/1878',
    apple: 'https://music.apple.com/us/artist/prince/155814',
    soundcloud: 'https://soundcloud.com/prince',
    tidal: 'https://listen.tidal.com/artist/4847',
    wiki: 'https://es.wikipedia.org/wiki/Prince',
    ticketmaster:
      'https://www.ticketmaster.com/the-prince-experience-tickets/artist/1466441',
    tiktok: 'https://www.tiktok.com/@prince.4.ever?lang=es',
    napster: 'https://us.napster.com/artist/prince',
  },
  {
    title: 'Rod Stewart',
    description:
      'Roderick David Rod Stewart es un músico, compositor y productor británico de rock, conocido mundialmente por haber sido vocalista en las bandas The Jeff Beck Group y Faces, como también por su exitosa y extensa carrera como solista. Con su distinguida voz rasposa inició su carrera como músico callejero para luego participar en The Dimensions y en el supergrupo The Steampacket durante los primeros años de la década de los sesenta. Sin embargo alcanzó el éxito después de ingresar a The Jeff Beck Group, donde solo participó en sus dos primeros álbumes, y luego en Faces hasta 1975 cuando la agrupación inglesa se separó..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nDMPXRotjxJ2k77CTaUXdq_NVPTMv9gwc',
    price: '$50',
    coverImg: 'RodStewart.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://rodstewart.com/',
    youtube: 'https://www.youtube.com/user/RodStewart',
    facebook: 'https://www.facebook.com/rodstewart',
    instagram: 'https://www.instagram.com/sirrodstewart/',
    twitter: 'https://twitter.com/rodstewart',
    spotify: 'https://open.spotify.com/artist/2y8Jo9CKhJvtfeKOsYzRdT',
    deezer: 'https://www.deezer.com/mx/artist/1440',
    apple: 'https://music.apple.com/mx/artist/rod-stewart/57471',
    soundcloud: 'https://soundcloud.com/rodstewartofficial',
    tidal: 'https://listen.tidal.com/artist/1639',
    wiki: 'https://es.wikipedia.org/wiki/Rod_Stewart',
    ticketmaster: 'https://www.tiktok.com/@sirrodstewart?lang=es',
    tiktok: 'https://listen.tidal.com/artist/1639',
    napster: 'https://music.amazon.com/artists/B000RSHJUE/rod-stewart',
  },
  {
    title: 'Joe Walsh',
    description:
      'Fidler Joseph «Joe» Walsh (Wichita, Kansas, 20 de noviembre de 1947) es un guitarrista, cantante y compositor estadounidense de hard rock, country rock y blues rock. Conocido por su maestría con la guitarra eléctrica, ha sido miembro de tres bandas de éxito: James Gang, Barnstorm y The Eagles. Además, ha experimentado como artista en solitario obteniendo un gran éxito. Con James Gang compuso temas reconocidos como "Walk Away" y "Funk #49". En solitario compuso su éxito cómico "Lifes Been Good" y "Rocky Mountain Way", siendo con este último tema que se utilizó por primera vez el talk box (inventado por un amigo de Joe, Bob Hulzon, para tocar el tema en vivo). Con The Eagles, ayudó a Don Henley, Glenn Frey y Don Felder con el tema "Hotel California", como guitarrista principal...',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kO-C0vdcx2Eqb83ShUUNQgSQ5famxrtBQ&si=FGhZ-BuoBp_CyFOB',
    price: '$50',
    coverImg: 'joewalsh.jpg',
    category: 'Classic Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://joewalsh.com/',
    youtube: 'https://www.youtube.com/user/JoeWalshOfficial',
    facebook: 'https://www.facebook.com/JoeWalshOfficial/',
    instagram: 'https://www.instagram.com/joewalshofficial//',
    twitter: 'https://twitter.com/JoeWalsh',
    spotify: 'https://open.spotify.com/artist/5bDxAyJiTYBat1YnFJhvEK',
    deezer: 'https://www.deezer.com/mx/artist/688',
    apple: 'https://music.apple.com/mx/artist/joe-walsh/34507',
    soundcloud: 'https://soundcloud.com/joewalshmusic',
    tidal: 'https://listen.tidal.com/artist/4063',
    wiki: 'https://es.wikipedia.org/wiki/Joe_Walsh',
    ticketmaster: 'https://www.tiktok.com/@joewalshofficial?lang=es',
    tiktok: 'https://listen.tidal.com/artist/4063',
    napster: 'https://music.amazon.com/artists/B000QJPE1K/joe-walsh',
  },
  {
    title: 'Lenny Kravitz',
    description:
      'Leonard Albert "Lenny" Kravitz (Nueva York, 26 de mayo de 1964) es un cantante, actor, fotógrafo, compositor, multiinstrumentista y productor estadounidense, cuyo estilo incorpora elementos del blues, rock, soul, funk, reggae, folk y balada. Lenny ganó el premio Grammy al "Best Male Rock Vocal Performance", cuatro años seguidos, de 1999 a 2002; y está situado en la 93.ª posición de los "100 mejores artistas de Hard Rock" de VH1. Lenny Kravitz ha vendido 40 millones de discos en todo el mundo. Pero además tiene en su poder uno de los más amplios conjuntos de hits musicales que lo han alzado al apogeo desde su primera aparición en 1989.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_k-V1J_WESQbQj0jILxECmHXmnhXI3QQes&si=JaH-2ZGf1XNOEs_t',
    price: '$50',
    coverImg: 'lennykravitz.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://lennykravitz.com/',
    youtube: 'https://www.youtube.com/channel/UCdIs5dRqgZ1IWOdLZimHL_w',
    facebook: 'https://www.facebook.com/lennykravitz',
    instagram: 'https://www.instagram.com/lennykravitz/?hl=en',
    twitter: 'https://x.com/LennyKravitz',
    spotify: 'https://open.spotify.com/artist/5gznATMVO85ZcLTkE9ULU7',
    deezer: 'https://www.deezer.com/mx/artist/189',
    apple: 'https://music.apple.com/mx/artist/lenny-kravitz/160410',
    soundcloud: 'https://soundcloud.com/lennykravitz',
    tidal: 'https://listen.tidal.com/artist/10926',
    wiki: 'https://es.wikipedia.org/wiki/Lenny_Kravitz',
    ticketmaster:
      'https://www.ticketmaster.com.mx/lenny-kravitz-cdmx-14-12-2024/event/140060CE8F380D94',
    napster: 'https://music.amazon.com.mx/artists/B000RSC800/lenny-kravitz',
    tiktok: 'https://www.tiktok.com/@lennykravitz?lang=en',
  },

  {
    title: 'Harry Styles',
    description:
      'Harry Edward Styles es un cantante, compositor y actor británico. Inició su carrera como cantante en 2010 como integrante de la boy band One Direction, con la que participó en el programa The X Factor y quedó en tercer lugar. Pese a no ganar, la agrupación firmó un contrato discográfico con el sello Syco, con el que publicaron los álbumes Up All Night, Take Me Home, Midnight Memories, Four y Made in the A.M., los cuales alcanzaron la primera posición en las listas de los más vendidos en numerosos países, entre estos los Estados Unidos y el Reino Unido, además de registrar altas ventas.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kzrF1uoBy1nqk215mLiPzzhUnc_hfFs40',
    price: '$50',
    coverImg: 'harrystyles.jpg',
    category: 'Pop',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.hstyles.co.uk/',
    youtube: 'https://www.youtube.com/c/HarryStyles',
    facebook: 'https://www.facebook.com/harrystyles',
    instagram: 'https://www.instagram.com/harrystyles/',
    twitter: 'https://twitter.com/Harry_Styles',
    spotify: 'https://open.spotify.com/artist/6KImCVD70vtIoJWnq6nGn3',
    deezer: 'https://www.deezer.com/mx/artist/5313805',
    apple: 'https://music.apple.com/mx/artist/harry-styles/471260289',
    soundcloud: 'https://soundcloud.com/harrystyles',
    tidal: 'https://listen.tidal.com/artist/8669861',
    wiki: 'https://es.wikipedia.org/wiki/Harry_Styles',
    ticketmaster:
      'https://www.ticketmaster.com/harry-styles-tickets/artist/2366444',
    napster: 'https://web.napster.com/artist/art.146985808',
    tiktok: 'https://www.tiktok.com/@officialharry?lang=es',
  },
  {
    title: 'Sam Smith',
    description:
      'Samuel Frederick Smith, cuyo nombre artístico es Sam Smith, es una celebridad británica profesional en el canto y la composición. En 2019, Smith se declaró de género no binario y tener los pronombres they/them en inglés.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nrECM3EEps3lyUkyO8L3uQTJsclWhcYlM',
    price: '$50',
    coverImg: 'samsmith.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://samsmithworld.com/',
    youtube: 'https://www.youtube.com/channel/UCvpDeGlR5wLP9Z3Tb6K0Xfg',
    facebook: 'https://www.facebook.com/samsmithworld',
    instagram: 'https://www.instagram.com/samsmith/',
    twitter: 'https://twitter.com/samsmith',
    spotify: 'https://open.spotify.com/artist/2wY79sveU1sp5g7SokKOiI',
    deezer: 'https://www.deezer.com/mx/artist/1097709',
    apple: 'https://music.apple.com/mx/artist/sam-smith/156488786',
    soundcloud: 'https://soundcloud.com/samsmithworld',
    tidal: 'https://listen.tidal.com/artist/4306587',
    wiki: 'https://es.wikipedia.org/wiki/Sam_Smith',
    ticketmaster:
      'https://www.ticketmaster.com/sam-smith-tickets/artist/1785301',
    napster: 'https://web.napster.com/artist/art.21025078',
    tiktok: 'https://www.tiktok.com/@samsmith?lang=es',
  },
  {
    title: 'John Legend',
    description:
      "John Roger Stephens, conocido como John Legend, es un cantante, compositor, pianista y actor estadounidense. Ha ganado once premios Grammy, un Premio Globo de Oro y un Oscar. En 2007, Legend recibió el Premio 'Hal David Starlight'  del Salón de la Fama de los Compositores.",
    videourl:
      'https://youtube.com/playlist?list=PL5odlUFMuUzzKmkiEuqFk3YFBossORfYB',
    price: '$50',
    coverImg: 'johnlegend.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.johnlegend.com/',
    youtube: 'https://www.youtube.com/channel/UCEa-JnNdYCIFn3HMhjGEWpQ',
    facebook: 'https://www.facebook.com/johnlegend',
    instagram: 'https://www.instagram.com/johnlegend_fc/',
    twitter: 'https://twitter.com/johnlegend',
    spotify: 'https://open.spotify.com/artist/5y2Xq6xcjJb2jVM54GHK3t',
    deezer: 'https://www.deezer.com/mx/artist/313',
    apple: 'https://music.apple.com/mx/artist/john-legend/16586443',
    soundcloud: 'https://soundcloud.com/johnlegend',
    tidal: 'https://listen.tidal.com/artist/490',
    wiki: 'https://es.wikipedia.org/wiki/John_Legend',
    ticketmaster:
      'https://www.ticketmaster.com/john-legend-tickets/artist/936461',
    napster: 'https://web.napster.com/artist/art.6432383',
    tiktok: 'https://www.tiktok.com/@johnlegend570?lang=es',
  },
  {
    title: 'Chris Issak',
    description:
      "Christopher Joseph Isaak (nacido el 26 de junio de 1956) es un cantante, compositor, guitarrista y ocasional actor estadounidense. Conocido por su estilo revivalista de rockabilly cargado de reverberación y su amplio rango vocal, es popularmente conocido por su éxito revelador y canción distintiva Wicked Game, así como por éxitos internacionales como Blue Hotel, Baby Did a Bad Bad Thing y Somebody's Crying.Con una carrera que abarca cuatro décadas, Isaak ha lanzado un total de 13 álbumes de estudio, ha realizado extensas giras con su banda Silvertone y ha recibido numerosas nominaciones a premios. Su sonido e imagen a menudo se comparan con los de Roy Orbison, Elvis Presley, Ricky Nelson y Duane Eddy.",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_m-ZNDwDmQf8YHK94j1TVvOu93sjC4iEJU&si=znHlrATfAG-waIzW',
    price: '$50',
    coverImg: 'chrisissak.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://chrisisaak.com/',
    youtube: 'https://www.youtube.com/channel/UCSCS5v9K-fNc9oS0AZx-OqA',
    facebook: 'https://www.facebook.com/chrisisaak/',
    instagram: 'https://www.instagram.com/chrisisaak/',
    twitter: 'https://x.com/ChrisIsaak',
    spotify: 'https://open.spotify.com/artist/7290H8m1Dwt8G7jm1y9CQx?autoplay=true',
    deezer: 'https://www.deezer.com/mx/playlist/13048441063',
    apple: 'https://music.apple.com/mx/artist/chris-isaak/151329',
    soundcloud: 'https://soundcloud.com/chrisisaak',
    tidal: 'https://listen.tidal.com/artist/6891',
    wiki: 'https://es.wikipedia.org/wiki/John_Legend',
    ticketmaster:
      'https://www.ticketmaster.com/chris-isaak-tickets/artist/735342',
    napster: 'https://music.amazon.com/artists/B000QJHLZM/chris-isaak',
    tiktok: 'https://www.tiktok.com/@chrisisaakfanpage?lang=es',
  },
  {
    title: 'Elton John',
    description:
      'Sir Elton John es un cantante, pianista, compositor y músico británico. Con una carrera de más de 50 años, ha lanzado más de 30 álbumes de estudio y ha vendido más de 300 millones de copias en todo el mundo, siendo uno de los artistas musicales más exitosos de la historia.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mr6JQDWNhkKYRCe0LUbmROXYR8XUD0N6A',
    price: 36,
    coverImg: 'Elton John.jpg',
    category: 'Soft Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.eltonjohn.com/',
    youtube: 'https://www.youtube.com/channel/UCcd0tBtip8YzdTCUw3OVv_Q',
    facebook: 'https://www.facebook.com/EltonJohn/',
    instagram: 'https://www.instagram.com/eltonjohn/',
    twitter: 'https://twitter.com/eltonofficial',
    spotify: 'https://open.spotify.com/artist/3PhoLpVuITZKcymswpck5b',
    deezer: 'https://www.deezer.com/mx/artist/413',
    apple: 'https://music.apple.com/mx/artist/elton-john/54657',
    soundcloud: 'https://soundcloud.com/elton-john',
    tidal: 'https://listen.tidal.com/artist/6890',
    wiki: 'https://es.wikipedia.org/wiki/Elton_John',
    ticketmaster:
      'https://www.ticketmaster.com/elton-john-tickets/artist/735394',
    tiktok: 'https://www.tiktok.com/@eltonjohn?lang=es',
    napster: 'https://us.napster.com/artist/elton-john',
  },
  {
    title: 'Sting',
    description:
      'Gordon Matthew Thomas Sumner, conocido artísticamente como Sting, es un músico británico que se desempeñó inicialmente como bajista, y más tarde como cantante y bajista del grupo musical The Police, formando luego su propia banda..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kk7sE13ypRgPTNaJTq3yvhZ2CavuO0U8w',
    price: '$50',
    coverImg: 'Sting.jpg',
    category: 'New Age',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.sting.com/splash',
    youtube: 'https://www.youtube.com/channel/UCwERP8ZAI10RtZIAPsZQDDw',
    facebook: 'https://www.facebook.com/sting',
    instagram: 'https://www.instagram.com/theofficialsting/',
    twitter: 'https://twitter.com/OfficialSting',
    spotify: 'https://open.spotify.com/artist/0Ty63ceoRnnJKVEYP0VQpk',
    deezer: 'https://www.deezer.com/mx/artist/368',
    apple: 'https://music.apple.com/mx/artist/sting/94804',
    soundcloud: 'https://soundcloud.com/sting',
    tidal: 'https://listen.tidal.com/artist/17356',
    wiki: 'https://es.wikipedia.org/wiki/Sting',
    ticketmaster: 'https://www.ticketmaster.com/sting-tickets/artist/723578',
    tiktok: 'https://www.tiktok.com/@official_sting?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJNH82/sting',
  },
  {
    title: 'Stevie Ray Vaughan',
    description:
      'Stephen Stevie Ray Vaughan (Dallas, Texas; 3 de octubre de 1954 - East Troy, Wisconsin; 27 de agosto de 1990) fue un guitarrista, cantante, compositor y productor discográfico estadounidense que fusionó los géneros de blues y rock en la década de 1980. Desarrolló un sonido y estilo basado en artistas de blues como B. B. King, Albert King, Freddie King, Buddy Guy, Otis Rush y Jimmi Hendrix. Después formó su propia agrupación, Triple Threat Revue, cambiando su nombre a Double Trouble tras contratar al baterista Chris Layton y al bajista Tommy Shannon. Logró popularidad tras su participación en el Festival de Jazz de Montreux de 1982, y su álbum debut Texas Flood ocupó la posición número 38 en 1983, una exitosa producción que vendió cerca de medio millón de copias. Compartió escenario con Jeff Beck en 1989 y con Joe Cocker en 1990, pero falleció trágicamente en un accidente de helicóptero el 27 de agosto de 1990 a los 35 años...',
    videourl:
      'https://youtube.com/playlist?list=PLDsFO1NXFTIIYLXp2OZlxHiv7tSOJfTNf&si=VqQACR3bszgnXVYO',
    price: '$50',
    coverImg: 'stevierayvaughan.jpg',
    category: 'Blues Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.srvofficial.com/',
    youtube: 'https://www.youtube.com/channel/UCxPlXqVP-0GvvGG-WrE_6Iw',
    facebook: 'https://www.facebook.com/stevierayvaughan',
    instagram: 'https://www.instagram.com/stevierayvaughan_official/',
    twitter: 'https://twitter.com/SRVOfficial',
    spotify: 'https://open.spotify.com/artist/5fsDcuclIe8ZiBD5P787K1',
    deezer: 'https://www.deezer.com/mx/artist/12297',
    apple: 'https://music.apple.com/us/artist/stevie-ray-vaughan/484957',
    soundcloud: 'https://soundcloud.com/stevie-ray-vaughan-official',
    tidal: 'https://listen.tidal.com/artist/111',
    wiki: 'https://es.wikipedia.org/wiki/Stevie_Ray_Vaughan',
    ticketmaster:
      'https://www.ticketmaster.com/stevie-ray-vaughan-tribute-tickets/artist/834523',
    tiktok: 'https://www.tiktok.com/@stevie_ray_vaughan?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B00123XBKK/stevie-ray-vaughan',
  },

  {
    title: 'Joe Satriani',
    description:
      "Joe 'Satch' Satriani (Westbury, Nueva York, 15 de julio de 1956) es un virtuoso guitarrista de rock instrumental estadounidense. Fue nominado en 15 ocasiones a los Premios Grammy y ha vendido más de 10 millones de discos en todo el mundo.Según otros guitarristas ha conseguido dominar casi todas las técnicas de ejecución de su instrumento, incluyendo tapping a dos manos, sweep picking, volume swells y tap harmonics. Además es reconocido como un gran profesor de guitarra, entre sus alumnos se incluyen: Tom Morello, Alex Skolnick, Larry LaLonde, Andy Timmons, Reb Beach, Rick Hunolt, Charlie Hunter, Kirk Hammett y Steve Vai.",
    videourl:
      'https://youtube.com/playlist?list=PLY90ZuSuNbRhsWk4hLVadAvUC60_mtzPr',
    price: 36,
    coverImg: 'JoeSatriani.jpg',
    category: 'Rock ',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'http://www.satriani.com/',
    facebook: 'https://www.facebook.com/joesatriani/',
    instagram: 'https://www.instagram.com/joesatriani/',
    twitter: 'https://twitter.com/chickenfootjoe',
    youtube: 'https://www.youtube.com/channel/UCiynI3-MFYbiZ6FQqWNLh7w',
    spotify: 'https://open.spotify.com/artist/2yzxX2DI9LFK8VFTyW2zZ8',
    deezer: 'https://www.deezer.com/mx/artist/496',
    apple: 'https://music.apple.com/mx/artist/joe-satriani/466941',
    soundcloud: 'https://soundcloud.com/joesatrianiofficial',
    tidal: 'https://listen.tidal.com/artist/686',
    wiki: 'https://es.wikipedia.org/wiki/Joe_Satriani',
    ticketmaster:
      'https://www.ticketmaster.com/joe-satriani-tickets/artist/736048',
    tiktok: 'https://www.tiktok.com/@joesatriani?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0010VKX64/joe-satriani',
  },
  {
    title: 'Seal',
    description:
      'Seal Henry Olusegun Olumide Adeola Samuel (nacido el 19 de febrero de 1963), mejor conocido por su nombre artístico Seal, es un cantante, compositor y productor musical británico. Ha vendido más de 20 millones de discos en todo el mundo.Estos incluyen canciones exitosas como Crazy y Killer, esta última llegó al número uno en el Reino Unido, y su canción más celebrada, Kiss from a Rose, que fue lanzada en 1994. Seal es reconocido por su distintiva y emotiva voz.A lo largo de su carrera, Seal ha ganado múltiples premios, incluidos tres Brit Awards; obtuvo el premio al Mejor Artista Británico Masculino en 1992. También ha ganado cuatro premios Grammy y un Premio MTV Video Music.Como compositor, Seal recibió dos premios Ivor Novello a la Mejor Canción Musical y Líricamente de la Academia Británica de Compositores, Autores y Compositores por Killer (1990) y Crazy (1991).',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l9BlMERDcybcHbsuc1x9vtYVOhrvxvA_c&si=HCOu-LRUDl4KakDH',
    price: 36,
    coverImg: 'seal.jpg',
    category: 'Rock ',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.sealofficial.com/',
    facebook: 'https://www.facebook.com/Seal/',
    instagram: 'https://www.instagram.com/seal/',
    twitter: 'https://twitter.com/seal',
    youtube: 'https://www.youtube.com/channel/UCzLsQjn1nZPRGmRdg0TX15g',
    spotify: 'https://open.spotify.com/artist/5GtMEZEeFFsuHY8ad4kOxv',
    deezer: 'https://www.deezer.com/mx/artist/1444',
    apple: 'https://music.apple.com/mx/artist/seal/156209',
    soundcloud: 'https://soundcloud.com/seal',
    tidal: 'https://listen.tidal.com/artist/6889',
    wiki: 'https://en.wikipedia.org/wiki/Seal_(musician)',
    ticketmaster: 'https://www.ticketmaster.com/seal-tickets/artist/736073',
    tiktok: 'https://www.tiktok.com/@seal',
    napster: 'https://music.amazon.com.mx/artists/B000RSHBK2/seal',
  },
  {
    title: 'Michael Buble',
    description:
      "Michael Steven Bublé es un cantante, compositor y actor canadiense. Ha ganado diversos premios, incluyendo cuatro Grammy y varios Juno. Los logros obtenidos alcanzaron modestas posiciones en las listas de los Estados Unidos, pero su álbum homónimo estuvo entre los diez primeros del Líbano, del Reino Unido y en su propio país. Pero no fue hasta 2005 en donde se convirtió en todo un éxito comercial en los Estados Unidos con su álbum It's time, del que vendió 8 millones de copias. Está casado con la actriz argentina Luisana Lopilato. Michael también ha aparecido en la serie Rove en cuatro ocasiones..",
    videourl: 'https://youtube.com/playlist?list=PLC54F72FA80FCD5A3',
    price: '$50',
    coverImg: 'michaelbuble.jpeg',
    category: 'Rythm & Blues',
    location: 'Canada',
    locationImg: 'canada.jpeg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.michaelbuble.com/higher?ref=Typed/Bookmarked',
    youtube: 'https://www.youtube.com/channel/UCHqQruhGENdmWy_oeH1f8QA',
    facebook: 'https://www.facebook.com/MichaelBuble',
    instagram: 'https://www.instagram.com/michael.buble_official/',
    twitter: 'https://twitter.com/MichaelBuble',
    spotify: 'https://open.spotify.com/artist/1GxkXlMwML1oSg5eLPiAz3',
    deezer: 'https://www.deezer.com/mx/artist/1478',
    apple: 'https://music.apple.com/mx/artist/michael-bubl%C3%A9/799597',
    soundcloud: 'https://soundcloud.com/michaelbuble',
    tidal: 'https://listen.tidal.com/artist/12263',
    wiki: 'https://es.wikipedia.org/wiki/Michael_Bubl%C3%A9',
    ticketmaster:
      'https://www.ticketmaster.com/michael-buble-tickets/artist/869115',
    napster:
      'https://music.amazon.com.mx/artists/B0011Z76FK/michael-bubl%C3%A9',
    tiktok: 'https://www.tiktok.com/@michaelbuble?lang=es',
  },
  {
    title: 'Chet Atkins',
    description:
      'Chester Burton "Chet" Atkins (20 de junio de 1924 – 30 de junio de 2001) fue un influyente guitarrista y productor de country. Su estilo —inspirado por Merle Travis, Django Reinhardt, George Barnes y Les Paul— le trajo admiradores dentro y fuera de la escena de EE. UU. Atkins produjo discos para Eddy Arnold, Don Gibson, Jim Reeves, Connie Smith, y Waylon Jennings. Creó, junto a Owen Bradley, el estilo de música country más suave y tranquilizador conocido como sonido Nashville, que llevó el gusto por la música country a admiradores de la música pop para adultos..',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlWh3XECQmwAggRs2-WMnlr0&si=_ajc7XmACic8_bnW',
    price: 80,
    coverImg: 'chetatkins.jpeg',
    category: 'Country Guitar',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.misterguitar.us/',
    youtube: 'https://www.youtube.com/channel/UCBH-9udLbWZBO3gIrWnwIiw',
    facebook: 'https://www.facebook.com/chet.atkins.121',
    instagram: 'https://www.instagram.com/officialchetatkins/',
    twitter: 'https://twitter.com/chetatkins757',
    spotify: 'https://open.spotify.com/artist/4dZrt8Ong5t7YYpvbfp0RU',
    deezer: 'https://www.deezer.com/mx/artist/7635',
    apple: 'https://music.apple.com/mx/artist/chet-atkins/212083',
    soundcloud: 'https://soundcloud.com/chetatkinsofficial',
    tidal: 'https://listen.tidal.com/artist/137',
    wiki: 'https://es.wikipedia.org/wiki/Chet_Atkins',
    ticketmaster:
      'https://www.ticketmaster.com/chet-atkins-tickets/artist/732681',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Chet%20Atkins&t=1693371013916',
    napster: 'https://music.amazon.com.mx/artists/B000QJO81C/chet-atkins',
  },
  {
    title: 'John Mellencamp',
    description:
      'John Mellencamp, anteriormente conocido como Johnny Cougar, John Cougar y John Cougar Mellencamp (Seymour, Indiana; 7 de octubre de 1951), es un cantante, compositor y guitarrista de rock estadounidense.John Mellencamp comenzó su carrera a finales de los 70 (de 1976 es su primer disco, que resultó un fracaso comercial); su popularidad se fue acrecentando aprovechando el tirón de la música de Bruce Springsteen, a la que le unían muchos elementos en común; sin embargo, a medida que avanzaba su carrera, su música se volvía más personal, desarrollando una particular mezcla entre folk rock y hard rock...',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nfgfyECUR8V4YfR3YNf-EwDQrKPWNDcA0&si=oYTEQWu9-x-gJwbs',
    price: 80,
    coverImg: 'mellencamp.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.mellencamp.com/',
    youtube: 'https://www.youtube.com/channel/UCzYJCeitN60eTc9UZvvRDoA',
    facebook: 'https://www.facebook.com/JohnMellencamp/?ref=s',
    instagram: 'https://www.instagram.com/johnmellencamp/',
    twitter: 'https://twitter.com/johnmellencamp',
    spotify: 'https://open.spotify.com/artist/3lPQ2Fk5JOwGWAF3ORFCqH',
    deezer: 'https://www.deezer.com/mx/artist/5734',
    apple: 'https://music.apple.com/us/artist/john-mellencamp/79969',
    soundcloud: 'https://soundcloud.com/john-mellencamp',
    tidal: 'https://listen.tidal.com/artist/241',
    wiki: 'https://es.wikipedia.org/wiki/John_Mellencamp',
    ticketmaster:
      'https://www.ticketmaster.com/john-mellencamp-tickets/artist/770615',
    tiktok: 'https://www.tiktok.com/@johnmellencamp?lang=es',
    napster: '',
  },
  {
    title: 'Bryan Adams',
    description:
      'Bryan Guy Adams (Kingston, Ontario; 5 de noviembre de 1959) es un guitarrista, cantante, compositor, fotógrafo y filántropo canadiense.1​ En más de cuarenta años de carrera ha vendido más de 100 millones de copias, incluyendo álbumes y sencillos en todo el mundo..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nd7dqorN_hoxxIF1beWZ7S6f9j---lO7E&si=Pk6G0VfCE7TZ2XqG',
    price: 80,
    coverImg: 'bryanadams.jpg',
    category: 'Rock',
    location: 'Canada',
    locationImg: 'canada.jpeg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.bryanadams.com/',
    youtube: 'https://www.youtube.com/@bryanadams',
    facebook: 'https://www.facebook.com/bryanadamsofficial',
    instagram: 'https://www.instagram.com/bryanadams/',
    twitter: 'https://twitter.com/bryanadams',
    spotify: 'https://open.spotify.com/artist/3Z02hBLubJxuFJfhacLSDc',
    deezer: 'https://www.deezer.com/mx/artist/170',
    apple: 'https://music.apple.com/mx/artist/bryan-adams/85932',
    soundcloud: 'https://soundcloud.com/bryan-adams-official',
    tidal: 'https://listen.tidal.com/artist/7436',
    wiki: 'https://es.wikipedia.org/wiki/Bryan_Adams',
    ticketmaster:
      'https://www.ticketmaster.com/bryan-adams-tickets/artist/734390',
    tiktok: 'https://www.tiktok.com/@bryanadams?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSC7VA/bryan-adams',
  },
  {
    title: 'Bruce Springsteen',
    description:
      'Bruce Frederick Joseph Springsteen (Long Branch, Nueva Jersey, 23 de septiembre de 1949)1​ es un cantante, músico y compositor estadounidense. Apodado a menudo the Boss —en español: el Jefe—, Springsteen es ampliamente conocido por su trabajo con el grupo E Street Band y considerado uno de los artistas más exitosos de la música rock, con ventas que superan los 64,5 millones de álbumes en los Estados Unidos y más de 120 millones a nivel mundial,4​5​ y un total de diez discos números uno, un registro solo superado por The Beatles y Jay-Z..',
    videourl:
      'https://youtube.com/playlist?list=PLJ3gKh8Ty5pYsEs3X6-T-MGmg1Kq1qK23&si=6ca7AS2AdsZesSA4',
    price: 80,
    coverImg: 'brucespringsteen.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://brucespringsteen.net/',
    youtube: 'https://www.youtube.com/channel/UCcu7ANuD9J7hnTQCREqIc4Q',
    facebook: 'https://www.facebook.com/brucespringsteen/',
    instagram: 'https://www.instagram.com/springsteen/?hl=en',
    twitter: 'https://twitter.com/springsteen',
    spotify: 'https://open.spotify.com/album/4XJaXh57G3rZtAzqeVZSfn',
    deezer: 'https://www.deezer.com/en/artist/215563125',
    apple: 'https://music.apple.com/mx/artist/bruce-springsteen/178834',
    soundcloud: 'https://soundcloud.com/brucespringsteen',
    tidal: 'https://listen.tidal.com/artist/509',
    wiki: 'https://es.wikipedia.org/wiki/Bruce_Springsteen',
    ticketmaster:
      'https://www.ticketmaster.com/bruce-springsteen-tickets/artist/736179',
    tiktok: 'https://www.tiktok.com/@brucespringsteen180?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJJW2M/bruce-springsteen',
  },
  {
    title: 'Billy Ocean',
    description:
      'Leslie Sebastian Charles (Fyzabad, Trinidad y Tobago; 21 de enero de 1950), más conocido como Billy Ocean, es un cantautor trinitense nacionalizado británico que comenzó a hacerse famoso a partir de la década de los 70s. Entre sus éxitos, se pueden destacar las canciones When the Going Gets Tough, the Tough Gets Going (parte de la banda sonora de la película La joya del Nilo, de 1985), Suddenly, Get Outta of My Dreams, Get Into My Car (parte de la banda sonora del filme License to Drive), Caribbean Queen, Loverboy y Therell Be Sad Songs to Make You Cry...​',
    videourl:
      'https://youtube.com/playlist?list=PLJn3EUUdQu2KYujKLc24qS3neFXBsUINo&si=W5aN8icZCqDlo5QN',
    price: 36,
    coverImg: 'billieOcean.jpg',
    category: ' Soft Rock',
    location: 'USA',
    locationImg: 'trinidadytobago.png',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.billyocean.com/',
    youtube: 'https://www.youtube.com/channel/UCjoGX0EUeb9zXF_0zLIs5CA',
    facebook: 'https://www.facebook.com/billyoceanofficial/?fref=ts',
    instagram: 'https://www.instagram.com/billyoceanofficial/?hl=en',
    twitter: 'https://twitter.com/MrWongo',
    spotify: 'https://open.spotify.com/artist/5IDs1CK15HegSAhGEbSYXo',
    deezer: 'https://www.deezer.com/mx/artist/4700',
    apple: 'https://music.apple.com/us/artist/billy-ocean/2320137',
    soundcloud: 'https://soundcloud.com/billyocean-official',
    tidal: 'https://listen.tidal.com/artist/1138',
    wiki: 'https://es.wikipedia.org/wiki/Billy_Ocean',
    ticketmaster:
      'https://www.ticketmaster.com/billy-ocean-tickets/artist/1017332',
    tiktok: 'https://www.tiktok.com/@billyoceanofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSCFQM/billy-ocean',
  },
  {
    title: 'Billy Joel',
    description:
      'William Martin "Billy" Joel (El Bronx, Nueva York, 9 de mayo de 1949) es un cantante, compositor y pianista estadounidense. Billy ha grabado muchos éxitos populares y logrado más de 40 hits desde 1973 (empezando con el sencillo "Piano Man") hasta su retirada en 1993, aunque sigue haciendo tours. Es uno de los pocos artistas de rock y pop que consiguieron éxitos en el top ten de los 70, 80 y 90. Ganador del Premio Grammy en seis ocasiones, ha vendido más de 100 millones de discos en el mundo y es el sexto artista con más ventas en Estados Unidos, de acuerdo con la RIAA.2​ Ha realizado exitosas giras artísticas, algunas veces con Elton John3​ además de escribir y grabar música clásica. Fue incluido en el salón de la fama del rock and roll en 1999, así como también, en el salón de la fama de los compositores y cantantes en 1992...​',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mlQ3zoXxSutN4BOBkdiwEB5JWhuhck1KA&si=F02Fa5bmldM50TOS',
    price: 36,
    coverImg: 'billyjoel.jpg',
    category: ' Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.billyjoel.com/',
    youtube: 'https://www.youtube.com/@billyjoel',
    facebook: 'https://www.facebook.com/billyjoel',
    instagram: 'https://www.instagram.com/billyjoel/',
    twitter: 'https://twitter.com/billyjoel',
    spotify: 'https://open.spotify.com/artist/5IDs1CK15HegSAhGEbSYXo',
    deezer: 'https://www.deezer.com/en/artist/423',
    apple: 'https://music.apple.com/us/artist/billy-joel/485953',
    soundcloud: 'https://soundcloud.com/billyocean-official',
    tidal: 'https://listen.tidal.com/artist/59',
    wiki: 'https://es.wikipedia.org/wiki/Billy_Joel',
    ticketmaster:
      'https://www.ticketmaster.com/billy-joel-tickets/artist/735392',
    tiktok: 'https://www.tiktok.com/@official_billy__joel?lang=es',
    napster:
      'https://music.amazon.com/artists/B000QJPZLE/billy-joel?ref=dm_wcp_artist_link_pr_s',
  },
  {
    title: 'Lionel Richie',
    description:
      'Lionel Brockman Richie, Jr. (Tuskegee, Alabama; 20 de junio de 1949)1​ es un cantautor y actor estadounidense.En los años 1970 formó parte de la banda musical The Commodores, propiedad del famoso sello musical Motown de Detroit (Míchigan). Un grupo estadounidense, famoso por su estilo musical funk/soul, que destacó en las décadas de 1970 y 1980. Se formaron en Detroit en 1968, y al año firmaron contrato con Motown Records. El grupo es más conocido por baladas como "Easy", "Still2 y "Three Times A Lady", aunque grabó principalmente funk y música bailable en temas como "Brick house", "Say Yeah", "Fancy Dancer", "Too Hot Ta Trot", "Nightshift", entre otros..​',
    videourl:
      'https://youtube.com/playlist?list=PLv6gVwjfKeYSrFAGcsYRI5fksu6-v1k0p',
    price: 36,
    coverImg: 'lionelritchie.jpg',
    category: ' Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://lionelrichie.com/',
    youtube: 'https://www.youtube.com/channel/UCM3iG_kXUM_9HHVIDI7vEtg',
    facebook: 'https://www.facebook.com/LionelRichie',
    instagram: 'https://www.instagram.com/lionelrichie/',
    twitter: 'https://twitter.com/LionelRichie',
    spotify: 'https://open.spotify.com/artist/3gMaNLQm7D9MornNILzdSl',
    deezer: 'https://www.deezer.com/mx/artist/3445',
    apple: 'https://music.apple.com/us/artist/lionel-richie/48397',
    soundcloud: 'https://soundcloud.com/lionelrichieofficial',
    tidal: 'https://listen.tidal.com/artist/17277',
    wiki: 'https://es.wikipedia.org/wiki/Lionel_Richie',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@lionelrichie?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJP758/lionel-richie',
  },
  {
    title: 'Santana',
    description:
      'Carlos Humberto Santana Barragan (Autlán de Navarro, Jalisco; 20 de julio de 1947) es un guitarrista mexicano nacionalizado estadounidense.En 1966 fundó la banda Santana, pionera en fusionar la música latina con el rock. Santana ha vendido más de 100 millones de álbumes en todo el mundo (contando las ventas de su banda y su carrera en solitario). Ha ganado diez premios Grammy y tres premios Grammy Latino.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nFhKKg7Ch_9lnfxTogq0VhkZZdvB7wBg4',
    price: 50,
    coverImg: 'santana.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.santana.com/',
    youtube: 'https://www.youtube.com/channel/UCI7pNDDat9jR8TjgF48aBDA',
    facebook: 'https://www.facebook.com/carlossantana',
    instagram: 'https://twitter.com/santanacarlos',
    twitter: 'https://twitter.com/santanacarlos',
    spotify: 'https://open.spotify.com/artist/6GI52t8N5F02MxU0g5U69P',
    deezer: 'https://www.deezer.com/mx/artist/553',
    apple: 'https://music.apple.com/mx/artist/santana/217174',
    soundcloud: 'https://soundcloud.com/santana-official',
    tidal: 'https://listen.tidal.com/artist/74',
    wiki: 'https://es.wikipedia.org/wiki/Carlos_Santana',
    ticketmaster: 'https://www.ticketmaster.com/santana-tickets/artist/741473',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Santana%20music&t=1654465241940',
    napster: 'https://app.napster.com/artist/santana',
  },{
    title: 'Mikel Erentxun',
    description:
      'Mikel Erentxun Acosta (Caracas, 23 de febrero de 1965) es un cantante y escritor Español, nacido en Venezuela, donde residió desde su primer año de vida para posteriormente, con sus padres, mudarse a San Sebastián, donde reside actualmente.1​ Conocido por ser el líder de la banda española Duncan Dhu. Actualmente continúa destacándose como cantante solista.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_ll8p97KNMcVAgmqOhopNdCio_gW_Jo-0s&si=em6M3fDaE2ArIkfD',
    price: '$50',
    coverImg: 'mikelerenxtun.jpg',
    category: 'Rock Pop',
    location: 'Venezuela',
    locationImg: 'venezuelaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.mikelerentxun.ws/',
    youtube: 'https://www.youtube.com/@mikelerentxun_oficial',
    facebook: 'https://www.facebook.com/mikelerentxun',
    instagram: 'https://www.instagram.com/erentxun/',
    twitter: 'https://x.com/MikelErentxun',
    spotify: 'https://open.spotify.com/artist/7thnnayFyJnVOAJrpe5wMC',
    deezer: 'https://www.deezer.com/mx/artist/13508',
    apple: 'https://music.apple.com/mx/artist/mikel-erentxun/725328',
    soundcloud: 'https://soundcloud.com/mikelerentxunlasmalasinfluencias',
    tidal: 'https://listen.tidal.com/artist/12190',
    wiki: 'https://es.wikipedia.org/wiki/Mikel_Erentxun',
    ticketmaster:
      'https://www.ticketmaster.com.mx/mikel-erentxun-tour-2024-guadalajara-19-11-2024/event/3D00610FE3913C13',
    tiktok: 'https://www.tiktok.com/@mikelerentxun?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0013CPN3S/mikel-erentxun',
  },{
    title: 'Ricardo Arjona',
    description:'Ricardo Arjona es un cantautor guatemalteco nacido el 19 de enero de 1964 en Jocotenango, Guatemala. Conocido por su estilo único que mezcla géneros como el pop, el rock y la música latina, Arjona ha sido uno de los artistas más influyentes y exitosos de la música en español desde los años 90.Comenzó su carrera musical a una edad temprana, pero su verdadero despegue llegó con el álbum "Déjame llorar" en 1993, que incluyó éxitos como “Te Conozco” y “Como Olvidar.” Desde entonces, ha lanzado numerosos álbumes, muchos de los cuales han alcanzado altos niveles de ventas y han sido bien recibidos por la crítica. Entre sus discos más destacados se encuentran Historias, ¿Quién diría? y Viaje al centro del corazón. Las letras de Arjona suelen abordar temas profundos y emocionales, incluyendo el amor, la soledad, y la crítica social, lo que le ha ganado una base de fanáticos leales a lo largo de los años. Además, ha colaborado con varios artistas y ha participado en proyectos musicales que trascienden fronteras.Ricardo Arjona ha recibido numerosos premios a lo largo de su carrera, incluyendo varios Grammy Latinos, y su influencia en la música latina sigue siendo notable. Con su estilo poético y su capacidad para conectar con el público, se ha consolidado como uno de los grandes referentes de la música en español.',
    videourl:
      'https://www.youtube.com/playlist?list=PLanI2KVB7PrDfqxarJEfYBUeicYBQKK4z',
    price: 50,
    coverImg: 'ricardoarjona.jpg',
    category: 'Baladas',
    location: 'Guatemala',
    locationImg: 'guatemalaflag.jpeg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.ricardoarjona.com/',
    youtube: 'https://www.youtube.com/channel/UCmDWOcRAkUX-05hhopOnUDQ',
    facebook: 'https://www.facebook.com/arjonaoficial',
    instagram: 'https://www.instagram.com/ricardoarjona/',
    twitter: 'https://x.com/Ricardo_Arjona',
    spotify:
      'https://open.spotify.com/artist/0X8PwlFMDK85fIadwq3cA5?si=wQEx4ri9Q_yhkyqLdv1t5w&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/4938',
    apple: 'https://music.apple.com/mx/artist/ricardo-arjona/88944',
    soundcloud: 'https://soundcloud.com/ricardo-arjona-official',
    tidal: 'https://listen.tidal.com/artist/8293',
    wiki:
      'https://es.wikipedia.org/wiki/Ricardo_Arjona',
    ticketmaster: 'https://www.ticketmaster.com.mx/ricardo-arjona-boletos/artist/759981',
    tiktok: 'https://www.tiktok.com/@ricardoarjona?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKIQCI/ricardo-arjona',
  },
  {
    title: 'Alejandro Fernandez',
    description:
      'Alejandro Fernández Abarca es un cantante de música ranchera y pop latino mexicano, hijo del fallecido cantante ranchero Vicente Fernández. En un principio se especializó en tipos tradicionales de música regional mexicana como mariachi. Posteriormente se ha diversificado hacia el pop latino con toques urbanos, la balada y el bolero.',

    videourl: 'http://youtube.com/playlist?list=PLEB89B6DF1EE964B5',
    price: '$50',
    coverImg: 'AlejandroFernandez.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://alejandrofernandez.com/',
    youtube: 'https://www.youtube.com/channel/UC0K-1_31jfkcA4Nd294gMyA',
    facebook: 'https://www.facebook.com/alejandrofernandezoficial',
    instagram: 'https://www.instagram.com/alexoficial/',
    twitter: 'https://twitter.com/alexoficial',
    spotify:
      'https://open.spotify.com/artist/6sq1yF0OZEWA4xoXVKW1L9?si=Nbmjb1PvTiGojQaSs1z1cQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/8794',
    apple: 'https://music.apple.com/mx/artist/alejandro-fern%C3%A1ndez/893583',
    soundcloud: 'https://soundcloud.com/alejandro-fernandez-official',
    tidal: 'https://listen.tidal.com/artist/1410',
    wiki: 'https://en.wikipedia.org/wiki/Alejandro_Fern%C3%A1ndez',
    ticketmaster:
      'https://www.ticketmaster.com/alejandro-fernandez-tickets/artist/761444',
    tiktok: 'https://www.tiktok.com/@alejandrofernandezfans',
    napster: 'https://web.napster.com/artist/alejandro-fernandez',
  },
  {
    title: 'Pepe Aguilar',
    description:
      'José Antonio Aguilar Jiménez, más conocido como Pepe Aguilar, es un cantante, compositor, músico, productor y empresario mexicano. Desde muy joven, Aguilar acompañó de gira a sus padres, los cantantes y actores mexicanos Antonio Aguilar y Flor Silvestre. Ahora hace lo mismo con sus propios hijos, Leonardo Aguilar y Ángela Aguilar, quienes también han seguido los pasos de su padre y abuelos..',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_km79fnJX9-enVlkDxmmUczWzgFBYo-mbY',
    price: '$50',
    coverImg: 'pepeaguilar.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://pepeaguilar.com/',
    youtube: 'https://www.youtube.com/c/pepeaguilaroficial',
    facebook: 'https://www.facebook.com/pepeaguilaroficial',
    instagram: 'https://www.instagram.com/pepeaguilar_oficial/',
    twitter: 'https://twitter.com/PepeAguilar',
    spotify: 'https://open.spotify.com/artist/03Yb3iBy9GCifXiATEFcit',
    deezer: 'https://www.deezer.com/mx/artist/8793',
    apple: 'https://music.apple.com/mx/artist/pepe-aguilar/21421637',
    soundcloud: 'https://soundcloud.com/search?q=Pepe%20Aguilar',
    tidal: 'https://listen.tidal.com/artist/3506026',
    wiki: 'https://en.wikipedia.org/wiki/Pepe_Aguilar',
    ticketmaster:
      'https://www.ticketmaster.com/pepe-aguilar-tickets/artist/754764',

    tiktok: 'https://www.tiktok.com/@pepeaguilar_oficial',
    napster: 'https://web.napster.com/artist/pepe-aguilar',
  },
  {
    title: 'Luis Miguel',
    description:
      'Luis Miguel Gallego Basteri (San Juan, 19 de abril de 1970),conocido como Luis Miguel y apodado en ocasiones como «El Sol de México», es un cantante y productor musical mexicano.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUMRA7E0-zntqkMrhNKwYri',
    price: 80,
    coverImg: 'luismiguel.jpg',
    category: 'Romantica',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'http://luismigueloficial.com/',
    youtube: 'https://www.youtube.com/c/LuisMiguelOfficial',
    facebook: 'https://www.facebook.com/luismiguelofficial',
    instagram: 'https://www.instagram.com/lmxlm/',
    twitter: 'https://twitter.com/LMXLM',
    spotify: 'https://open.spotify.com/artist/2nszmSgqreHSdJA3zWPyrW',
    deezer: 'https://www.deezer.com/mx/artist/6123',
    apple: 'https://music.apple.com/mx/artist/luis-miguel/336904',
    soundcloud: 'https://soundcloud.com/luismiguel_oficial',
    tidal: 'https://listen.tidal.com/artist/11993',
    wiki: 'https://en.wikipedia.org/wiki/Luis_Miguel',
    ticketmaster:
      'https://www.ticketmaster.com/luis-miguel-tickets/artist/762874',

    tiktok: 'https://www.tiktok.com/@lmxlm',
    napster: 'https://web.napster.com/artist/luis-miguel',
  },
  {
    title: 'Björk',
    description:
      'Björk Guðmundsdóttir Reikiavik, 21 de noviembre de 1965, conocida simplemente como Björk, es una cantante, compositora, multiinstrumentista, actriz, escritora, DJ y productora islandesa. Es reconocida por hacer música experimental, alternativa y vanguardista con la cual ha obtenido gran reconocimiento a nivel internacional, tanto de crítica como de público. Varios de sus álbumes han alcanzado el top 10 en la lista Billboard 200: el más reciente es Fossora (2022).Björk fue incluida en la lista de los 100 artistas más influyentes del siglo. Así mismo, también fue incluida por la revista Rolling Stone como una de las cantantes y compositoras más influyentes de la época actual, incluyendo su inclusión en la lista de los 200 artistas más influyentes de los últimos 25 años por Pitchfork en 2021.3​ Björk también ha sido nominada 16 veces a los Premios Grammy.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_neQVQ7L8dH5xV5QKsopBwMASWeFR0eSl8&si=exfbr8Vk6bUigsMq',
    price: '$50',
    coverImg: 'bjork.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bjorktour.com/',
    youtube: 'https://www.youtube.com/channel/UCFbRdRGijPR4oBjQ0fVCSmw',
    facebook: 'https://www.facebook.com/bjork',
    instagram: 'https://www.instagram.com/bjork/',
    twitter: 'https://twitter.com/bjork',
    spotify: 'https://open.spotify.com/artist/7w29UYBi0qsHi5RTcv3lmA',
    deezer: 'https://www.deezer.com/mx/artist/630',
    apple: 'https://music.apple.com/mx/artist/bj%C3%B6rk/295015',
    soundcloud: 'https://soundcloud.com/bjork',
    tidal: 'https://listen.tidal.com/artist/16992',
    wiki: 'https://es.wikipedia.org/wiki/Bj%C3%B6rk',
    ticketmaster: 'https://www.ticketmaster.com/bjork-tickets/artist/753508',
    tiktok: 'https://www.tiktok.com/@bjorkofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJK03W/bj%C3%B6rk',
  },
  {
    title: 'Britney Spears',
    description:
      'Britney Jean Spears (McComb, Misisipi; 2 de diciembre de 1981) es una cantante, bailarina, compositora, modelo, actriz, diseñadora de modas y empresaria estadounidense. Comenzó a actuar desde niña, a través de papeles en producciones teatrales. Después adquirió fama al participar en el programa de televisión The Mickey Mouse Club.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l8FKUB87QCDXkCddEw2uzuxL6PDp-vFCg',
    price: '$50',
    coverImg: 'britneyspears.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://britneyspears.com/',
    youtube: 'https://www.youtube.com/channel/UCgffc95YDBlkGrBAJUHUmXQ',
    facebook: 'https://www.facebook.com/britneyspears',
    instagram: 'https://www.instagram.com/britneyspears/',
    twitter: 'https://twitter.com/britneyspears',
    spotify: 'https://open.spotify.com/artist/26dSoYclwsYLMAKD3tpOr4',
    deezer: 'https://www.deezer.com/mx/artist/483',
    apple: 'https://music.apple.com/mx/artist/britney-spears/217005',
    soundcloud: 'https://soundcloud.com/britneyspears',
    tidal: 'https://listen.tidal.com/artist/1532',
    wiki: 'https://es.wikipedia.org/wiki/Britney_Spears',
    ticketmaster:
      'https://www.ticketmaster.com/britney-spears-tickets/artist/723427',
    tiktok: 'https://www.tiktok.com/@britneyspears?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJO2TU/britney-spears',
  },
  {
    title: 'Madonna',
    description:
      'Madonna Louise Ciccone, conocida simplemente como Madonna, es una cantante, bailarina, compositora, actriz, empresaria e icono estadounidense. Madonna pasó su infancia en Bay City y en 1978 se mudó a la ciudad de Nueva York para realizar una carrera de danza contemporánea. Después de participar en dos grupos musicales, Breakfast Club y Emmy, en 1982 firmó con Sire Records y lanzó su álbum debut Madonna al año siguiente.',
    videourl:
      'https://youtube.com/playlist?list=PLvHf4SnA7f8uK47aAgk6EzgbNcRxK98LM',
    price: '$50',
    coverImg: 'madonna.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.madonna.com/',
    youtube: 'https://www.youtube.com/c/madonna',
    facebook: 'https://www.facebook.com/madonna',
    instagram: 'https://www.instagram.com/madonna',
    twitter: 'https://twitter.com/madonna',
    spotify: 'https://open.spotify.com/artist/6tbjWDEIzxoDsBA1FuhfPW',
    deezer: 'https://www.deezer.com/mx/artist/290',
    apple: 'https://music.apple.com/mx/artist/madonna/20044',
    soundcloud: 'https://soundcloud.com/madonna',
    tidal: 'https://listen.tidal.com/artist/15545',
    wiki: 'https://es.wikipedia.org/wiki/Madonna',
    ticketmaster: 'https://www.ticketmaster.com/madonna-tickets/artist/768011',
    tiktok: 'https://www.tiktok.com/@madonna?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJP7VW/madonna',
  },

  {
    title: 'Sade',
    description:
      'Helen Folasade Adu Ibadán, 16 de enero de 1959), más conocida como Sade, es una cantante y compositora nigeriana nacionalizada británica Durante su adolescencia no paraba de escuchar discos de Nina Simone, Peggy Lee y Astrud Gilberto.Sus estudios de diseño la llevaron a abrir una boutique, pero su amor por la música la llevó a cantar en un grupo funky de cierto carácter latino que respondía al nombre de Arriba. Desde ese momento descubrió un extraño placer al escribir letras. Más tarde, esta banda pasó a llamarse Pride, y luego cambió a Sade, que es una abreviatura de Folasade. Y con Sade comenzó a conocerse también a su vocalista..',

    videourl:
      'https://youtube.com/playlist?list=PL7A67YeJT9U7oMK2xMU5JJcEj6vwQHcEt',
    price: '$50',
    coverImg: 'sade.jpg',
    category: 'Soul',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.sade.com/',
    youtube: 'https://www.youtube.com/channel/UCegKDBFMUSQNeWe4FAB7aig',
    facebook: 'https://www.facebook.com/sadeofficial',
    instagram: 'https://www.instagram.com/sade/',
    twitter: 'https://twitter.com/SadeOfficial',
    spotify: 'https://open.spotify.com/artist/47zz7sob9NUcODy0BTDvKx',
    deezer: 'https://www.deezer.com/mx/artist/202',
    apple: 'https://music.apple.com/mx/artist/sade/475591',
    soundcloud: 'https://soundcloud.com/sadeofficial',
    tidal: 'https://listen.tidal.com/artist/485',
    wiki: 'https://es.wikipedia.org/wiki/Sade_Adu',
    ticketmaster: 'https://www.ticketmaster.com/sade-tickets/artist/736033',
    napster: 'https://music.amazon.com.mx/artists/B000QKKF3G/sade',
    tiktok: 'https://www.tiktok.com/@sadeaduwife',
  },
  {
    title: 'Selena',
    description:
      'Selena Quintanilla Pérez (Lake Jackson, Texas, 16 de abril de 1971-Corpus Christi, Texas, 31 de marzo de 1995), conocida monónimamente como Selena, fue una cantante estadounidense. Llamada la «Reina de la música tejana», sus contribuciones a la música y la moda la convirtieron en una de las artistas mexicanoestadounidenses más célebres de finales del siglo xx. En 2020, la revista Billboard la colocó en el tercer lugar de su lista de «Mejores artistas latinos de todos los tiempos», basado en la lista de álbumes latinos y canciones latinas.3​ Los medios de comunicación la llamaron la «Madonna tejana» por sus elecciones de ropa.A​ También se encuentra entre las artistas latinas más influyentes de todos los tiempos y se le atribuye haber catapultado el género tejano al mercado principal..',

    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nZ2PDxHpWHOslsCoBGMvmh9mmRB8ZHCZo',
    price: '$50',
    coverImg: 'selena.jpg',
    category: 'Regional',
    location: 'UK',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://selena-official.com/',
    youtube: 'https://www.youtube.com/@SelenaQOfficial',
    facebook: 'https://www.facebook.com/selenalaleyenda',
    instagram: 'https://www.instagram.com/selenaqofficial/',
    twitter: 'https://twitter.com/SelenaLaLeyenda',
    spotify: 'https://open.spotify.com/artist/6IE6z7DcZIT4Ml3Fh5Ivch',
    deezer: 'https://www.deezer.com/mx/artist/5644',
    apple: 'https://music.apple.com/mx/artist/selena/574870',
    soundcloud: 'https://soundcloud.com/selenaquintanillanewsongs',
    tidal: 'https://listen.tidal.com/artist/9541',
    wiki: 'https://es.wikipedia.org/wiki/Selena',
    ticketmaster:
      'https://www.ticketmaster.com/almost-selena-tickets/artist/2622145',
    napster: 'https://music.amazon.com.mx/artists/B000RHRQTY/selena',
    tiktok: 'https://www.tiktok.com/@selenalaleyenda',
  },
  {
    title: 'Rocio Durcal',
    description:
      'María de los Ángeles de las Heras Ortiz, conocida por su nombre artístico Rocío Dúrcal, fue una actriz y cantante española. Dúrcal es considerada como la «reina de las rancheras» y es una de las mujeres de habla hispana con más ventas en la industria. En 2005, un año antes de su muerte, recibió el Grammy Latino a la excelencia musical.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lMv8ZIewIbDR8z4ckj4pbzhXV8GtrWh58',
    price: '$50',
    coverImg: 'rociodurcal.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Roc%C3%ADo_D%C3%BArcal',
    youtube: 'https://www.youtube.com/channel/UCBgAZZ95M0pSBo69Qb6EuTQ',
    facebook: 'https://www.facebook.com/RocioDurcalMusic',
    instagram:
      'https://www.instagram.com/rociodurcal.music/?fbclid=IwAR1j7EblxeIYpgKWQfpesMeVe2l-6TfNhzfv7Hterp7PVJPLcWjCSPR6n_I',
    twitter:
      'https://twitter.com/rocio_durcal?fbclid=IwAR0QUd4K5u9dWS3S72dobhOD_ktY5dPbr-386AiKlv411DTQPTAhsnOY-vA',
    spotify: 'https://open.spotify.com/artist/2uyweLa0mvPZH6eRzDddeB',
    deezer: 'https://www.deezer.com/mx/artist/75284',
    apple: 'https://music.apple.com/mx/artist/roc%C3%ADo-d%C3%BArcal/6742584',
    soundcloud: 'https://soundcloud.com/rocio-durcal-official',
    tidal: 'https://listen.tidal.com/artist/3660925',
    wiki: 'https://en.wikipedia.org/wiki/Roc%C3%ADo_D%C3%BArcal',
    ticketmaster:
      'https://www.ticketmaster.com/rocio-durcal-tickets/artist/754908',
    napster:
      'https://music.amazon.com.mx/artists/B00386NT7O/roc%C3%ADo-d%C3%BArcal',
    tiktok: 'https://www.tiktok.com/@rociodurcal.oficial',
  },
  {
    title: 'Tina Turner',
    description:
      'Anna Mae Bullock, más conocida como Tina Turner, es una cantante, compositora, bailarina, actriz y coreógrafa de nacionalidad suiza y origen estadounidense, cuya carrera se desarrolló durante más de cincuenta años, además de ser una de las principales y mayores componentes de rock siendo considerada como la «Reina del Rock». Se retiró del escenario y la música en 2013 a la edad de setenta y tres años después de una carrera musical de cincuenta y cuatro años.',
    videourl:
      'https://youtube.com/playlist?list=PLGRnTVgjhDFKabSWJfU4s-pkLwTz6jVJP',
    price: '$50',
    coverImg: 'TinaTurner.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.tinaturnerofficial.com/',
    youtube: 'https://www.youtube.com/channel/UCfBkblXPcSNt784AZz5J6AQ',
    facebook: 'https://www.facebook.com/TinaTurner/',
    instagram: 'https://www.instagram.com/tinaturner/?hl=en',
    twitter: 'https://twitter.com/LoveTinaTurner',
    spotify:
      'https://open.spotify.com/artist/1zuJe6b1roixEKMOtyrEak?si=vmbXj7mUSjWUXe6AujvE8g&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/1454',
    apple: 'https://music.apple.com/mx/artist/tina-turner/488075',
    soundcloud: 'https://soundcloud.com/tina-turner-official',
    tidal: 'https://listen.tidal.com/artist/9214',
    wiki: 'https://es.wikipedia.org/wiki/Tina_Turner',
    ticketmaster:
      'https://www.ticketmaster.com/tina-turner-tickets/artist/736358',
    tiktok: 'https://www.tiktok.com/@tina.turner.love?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJNPQG/tina-turner',
  },
  {
    title: 'Lauryn Hill',
    description:
      'Lauryn Hill (East Orange, Nueva Jersey, 26 de mayo de 1975) es una rapera, cantante, actriz y productora discográfica estadounidense galardonada 8 veces con el premio Grammy. Fue miembro de The Fugees antes de empezar una carrera en solitario.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_m0PVFfyOJH8trqZucyaODwytdicqitwGQ',
    price: '$50',
    coverImg: 'laurynHill.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://mslaurynhill.com/',
    youtube: 'https://www.youtube.com/results?search_query=lauryn+hill',
    facebook: 'https://www.facebook.com/mslaurynhill',
    instagram: 'https://www.instagram.com/mslaurynhill/',
    twitter: 'https://twitter.com/MsLaurynHill',
    spotify: 'https://open.spotify.com/artist/2Mu5NfyYm8n5iTomuKAEHl',
    deezer: 'https://www.deezer.com/mx/artist/1117',
    apple: 'https://music.apple.com/mx/artist/lauryn-hill/21434',
    soundcloud: 'https://soundcloud.com/lauryn-hill-official',
    tidal: 'https://listen.tidal.com/artist/8201663',
    wiki: 'https://es.wikipedia.org/wiki/Lauryn_Hill',
    ticketmaster:
      'https://www.ticketmaster.com/ms-lauryn-hill-tickets/artist/795402',
    tiktok: 'https://www.tiktok.com/@laurynhillfannn?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSHLF2/lauryn-hill',
  },
  {
    title: 'Mariah Carey',
    description:
      'Mariah Carey, Huntington, Nueva York; 27 de marzo de 1969 es una cantante, compositora, productora musical y actriz estadounidense. Referida como el «Ave Cantora Suprema Songbird Supreme por el Libro Guinness de los récords,3​45​ es reconocida por su registro vocal de cinco octavas, su estilo melismático y por el uso del registro de silbido.Bajo la dirección del productor ejecutivo del sello Columbia Records, Tommy Mottola, Carey lanzó su álbum debut homónimo, Mariah Carey (1990). De este se desprendieron cuatro sencillos que llegaron al primer puesto en la lista Billboard Hot 100. Después de su matrimonio con Mottola y el éxito de sus álbumes posteriores, Music Box (1993) y Merry Christmas (1994), Carey se estableció como la artista con mayores ventas de Columbia.',
    videourl:
      'https://youtube.com/playlist?list=PLMmBcihs-L-CP-vbRp3uPyGOgmCr51FU3&si=xoD1FW0asBcqh1DZ',
    price: '$50',
    coverImg: 'mariahcarey.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://mariahcarey.store/',
    youtube: 'https://www.youtube.com/channel/UCurpiDXSkcUbgdMwHNZkrCg',
    facebook: 'https://www.facebook.com/mariahcarey',
    instagram: 'https://www.instagram.com/mariahcarey',
    twitter: 'https://twitter.com/mariahcarey',
    spotify: 'https://open.spotify.com/artist/4iHNK0tOyZPYnBU7nGAgpQ',
    deezer: 'https://www.deezer.com/mx/artist/65',
    apple: 'https://music.apple.com/mx/artist/mariah-carey/91853',
    soundcloud: 'https://soundcloud.com/mariahcarey',
    tidal: 'https://listen.tidal.com/artist/518',
    wiki: 'https://es.wikipedia.org/wiki/Mariah_Carey',
    ticketmaster:
      'https://www.ticketmaster.com/mariah-carey-tickets/artist/767870',
    tiktok: 'https://www.tiktok.com/@mariahcarey?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSDIBI/mariah-carey',
  },
  {
    title: 'Beyonce',
    description:
      "Beyoncé Giselle Knowles-Carter (Houston, 4 de septiembre de 1981),​ conocida simplemente como Beyoncé, es una cantautora, productora, actriz, directora, diseñadora y empresaria estadounidense.En su ciudad natal se presentó a diversos concursos de canto y baile cuando era niña y saltó a la fama a finales de los años 1990 como vocalista principal del grupo femenino de R&B, Destiny's Child. Dirigido por su padre, Mathew Knowles, se convirtió en uno de los grupos femeninos con mayores ventas de la historia.",
    videourl:
      'https://youtube.com/playlist?list=PLHFeMk_LSwG5WMlOMrny0r3SQyFYx7W2M',
    price: '$50',
    coverImg: 'beyonce.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.beyonce.com/',
    youtube: 'https://www.youtube.com/channel/UCuHzBCaKmtaLcRAOoazhCPA',
    facebook: 'https://www.facebook.com/beyonce',
    instagram: 'https://www.instagram.com/beyonce/',
    twitter: 'https://twitter.com/Beyonce',
    spotify: 'https://open.spotify.com/artist/6vWDO969PvNqNYHIOW5v0m',
    deezer: 'https://www.deezer.com/mx/artist/145',
    apple: 'https://music.apple.com/mx/artist/beyonc%C3%A9/1419227',
    soundcloud: 'https://soundcloud.com/beyonce',
    tidal: 'https://listen.tidal.com/artist/1566',
    wiki: 'https://es.wikipedia.org/wiki/Beyonc%C3%A9',
    ticketmaster: 'https://www.ticketmaster.com/beyonce-tickets/artist/894191',
    tiktok: 'https://www.tiktok.com/@beyonce?lang=en',
    napster: 'https://music.amazon.com.mx/artists/B000T2D81C/beyonc%C3%A9',
  },
  {
    title: 'Alejandra Guzman',
    description:
      'Gabriela Alejandra Guzmán Pinal (Ciudad de México; 9 de febrero de 1968), es una cantante mexicana. Ha incursionado en la actuación de telenovelas, series y programas de televisión.Hija del cantante de rock and roll y actor venezolano Enrique Guzmán y de la actriz mexicana Silvia Pinal, es reconocida en Latinoamérica, Estados Unidos y Europa. Se estima que Alejandra Guzmán ha vendido más de treinta millones de discos desde su debut, lo que la convierte en una de las cantantes latinas con mayores ventas..',
    videourl: 'https://youtube.com/playlist?list=UULFuHEt7VkfYYY0_ucAuMJ3dg',
    price: 50,
    coverImg: 'alejandraguzman.jpg',
    category: 'Pop',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.aleguzman.com/',
    youtube: 'https://www.youtube.com/channel/UCuHEt7VkfYYY0_ucAuMJ3dg',
    facebook: 'https://www.facebook.com/laguzmanoficial',
    instagram: 'https://www.instagram.com/laguzmanmx/',
    twitter: 'https://twitter.com/Al3jandraGuzman',
    spotify: 'https://open.spotify.com/artist/0EmeFodog0BfCgMzAIvKQp',
    deezer: 'https://www.deezer.com/mx/artist/9033',
    apple: 'https://music.apple.com/mx/artist/alejandra-guzm%C3%A1n/248791',
    soundcloud: 'https://soundcloud.com/alejandraguzman-music',
    tidal: 'https://listen.tidal.com/artist/5463',
    wiki: 'https://es.wikipedia.org/wiki/Alejandra_Guzm%C3%A1n',
    ticketmaster:
      'https://www.ticketmaster.com./alejandra-guzman-tickets/artist/761876',
    tiktok: 'https://www.tiktok.com/@laguzmanoficial?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B000YPV0T6/alejandra-guzm%C3%A1n',
  },
  {
    title: 'Thalia',
    description:
      'Ariadna Thalía Sodi Miranda (Ciudad de México, 26 de agosto de 1971),nota 1​ conocida como Thalía, es una cantante, actriz y empresaria mexicana.Inició su carrera musical a principios de los años 1980 como vocalista de un grupo infantil llamado Din-Din y en 1986 se integró a la banda Timbiriche, con la que grabó tres álbumes de estudio,3​ y de la que se separó en 1989.4​ Poco después viajó a Los Ángeles (California) para prepararse como solista.5​ Regresó a México en 1990 y publicó su primer álbum como solista titulado Thalía, bajo el sello discográfico Fonovisa, al que siguieron Mundo de cristal en 1991 y Love en 1992, los cuales lograron éxito en ventas en territorio mexicano',
    videourl:
      'https://youtube.com/playlist?list=PL1Xix0gugm8tJnlJklE9F78MVWWvB6mCS',
    price: 50,
    coverImg: 'thalia.jpg',
    category: 'Pop',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://thalia.com/',
    youtube: 'https://www.youtube.com/channel/UCgyFSSvdNv3MC_atQPqM_XA',
    facebook: 'https://www.facebook.com/Thalia',
    instagram: 'https://www.instagram.com/thalia/',
    twitter: 'https://twitter.com/thalia',
    spotify: 'https://open.spotify.com/artist/23wEWD21D4TPYiJugoXmYb',
    deezer: 'https://www.deezer.com/mx/artist/710',
    apple: 'https://music.apple.com/mx/artist/thalia/88612122',
    soundcloud: 'https://soundcloud.com/thalia-54826',
    tidal: 'https://listen.tidal.com/artist/3505335',
    wiki: 'https://es.wikipedia.org/wiki/Thal%C3%ADa',
    ticketmaster: 'https://www.ticketmaster.com./thalia-tickets/artist/910999',
    tiktok: 'https://www.tiktok.com/@thalia?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B001JQEVBI/thalia',
  },
  {
    title: 'Paulina Rubio',
    description:
      'Paulina Susana Rubio Dosamantes1​ (Ciudad de México, 17 de junio de 1971),2​ ​conocida artísticamente como Paulina Rubio y apodada «La Chica Dorada», es una cantante, compositora, actriz, presentadora de televisión, modelo y empresaria mexicana.Paulina Rubio es hija de la actriz Susana Dosamantes, cuya proyección en el cine mexicano la influenció desde su infancia para perseguir la misma aspiración de convertirse en famosa. Logró el reconocimiento en la industria de la música hispana como miembro fundadora de la banda Timbiriche en la década de los años de 1980, destacándose como una de las principales integrantes de la agrupación. ',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lYa9h96c5vG2jb0GyfCDV6B2GKKyAFbBI',
    price: 50,
    coverImg: 'paulinarubio.jpg',
    category: 'Pop',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://paulinarubio.com/',
    youtube: 'https://www.youtube.com/channel/UCuxn1zFiLGD-T43TSk9YqSg',
    facebook: 'https://www.facebook.com/paulinarubio/',
    instagram: 'https://www.instagram.com/paulinarubio/',
    twitter: 'https://twitter.com/paulinarubio',
    spotify: 'https://open.spotify.com/artist/1d6dwipPrsFSJVmFTTdFSS',
    deezer: 'https://www.deezer.com/mx/artist/1464',
    apple: 'https://music.apple.com/mx/artist/paulina-rubio/264450',
    soundcloud: 'https://soundcloud.com/paulina-rubio-official',
    tidal: 'https://listen.tidal.com/artist/24900',
    wiki: 'https://es.wikipedia.org/wiki/Paulina_Rubio',
    ticketmaster:
      'https://www.ticketmaster.com./paulina-rubio-tickets/artist/759442',
    tiktok: 'https://www.tiktok.com/@thalia?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKKI2E/paulina-rubio',
  },

  {
    title: 'Mary J Blige',
    description:
      'Mary Jane Blige (El Bronx, Nueva York, 11 de enero de 1971)​ es una actriz y cantante estadounidense de R&B contemporáneo, de soul y hip-hop, influida por Chaka Khan, Aretha Franklin y Anita Baker entre otras muchas figuras de la música soul.Nació en Nueva York y pasó parte de su infancia en Savannah, para en su juventud volver a Yonkers en su ciudad natal.3​ Cuando vivía en White Plains (Nueva York) se grabó en un karaoke cantando el tema de Anita Baker Caught Up in the Rapture. La grabación resultante fue enviada a Andre Harrel, gerente de Uptown Records, por el padrastro de Mary. Harrel se quedó impresionado por su voz y la contrató para que hiciera los coros a Father MC..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mL4V3aGUV7dbQ6an2fL0Q4DpjlYeN6OKI',
    price: '$50',
    coverImg: 'maryjblige.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.maryjblige.com/',
    youtube: 'https://www.youtube.com/channel/UCj5CgwX_iJEqJsqUJyYjg4A',
    facebook: 'https://www.facebook.com/maryjblige',
    instagram: 'https://www.instagram.com/therealmaryjblige/',
    twitter: 'https://twitter.com/maryjblige',
    spotify: 'https://open.spotify.com/artist/1XkoF8ryArs86LZvFOkbyr',
    deezer: 'https://www.deezer.com/mx/artist/152',
    apple: 'https://music.apple.com/mx/artist/mary-j-blige/1392280',
    soundcloud: 'https://soundcloud.com/officialmaryjblige',
    tidal: 'https://listen.tidal.com/artist/7036',
    wiki: 'https://es.wikipedia.org/wiki/Mary_J._Blige',
    ticketmaster:
      'https://www.ticketmaster.com/mary-j-blige-tickets/artist/767544',
    tiktok: 'https://www.tiktok.com/@maryjblige?lang=en',
    napster: 'https://music.amazon.com.mx/artists/B000RW2KGS/mary-j-blige',
  },
  {
    title: 'Whitney Houston',
    description:
      'Whitney Elizabeth Houston (Newark, Nueva Jersey, 9 de agosto de 1963-Beverly Hills, California, 11 de febrero de 2012) fue una cantante, compositora, productora discográfica, actriz, empresaria y modelo estadounidense. Apodada «la Voz», es una de las artistas musicales más vendidas de todos los tiempos, con más de doscientos veinte millones de discos vendidos en todo el mundo.2​3​ En 2023, Rolling Stone la nombró la segunda mejor cantante de todos los tiempos.4​ Houston influyó en muchos cantantes de música popular y era conocida por su voz poderosa y conmovedora, sus habilidades de improvisación vocal y el uso de técnicas de canto góspel en la música pop.5​6​ Tuvo once sencillos número uno en el Billboard Hot 100 y es la única artista que tiene siete sencillos número uno consecutivos en la lista. Houston logró una mayor popularidad al ingresar a la industria del cine.',

    videourl:
      'https://youtube.com/playlist?list=PLyxWCv_X0MiSCXJDwRIsvmRzpdUUJ_v9L&si=140dBMSP-CJx8PV2',
    price: '$50',
    coverImg: 'whitneyhouston.jpg',
    category: 'Baladas',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.whitneyhouston.com/',
    youtube: 'https://www.youtube.com/channel/UC7fzrpTArAqDHuB3Hbmd_CQ',
    facebook: 'https://www.facebook.com/WhitneyHouston',
    instagram: 'https://www.instagram.com/whitneyhouston/',
    twitter: 'https://twitter.com/RealWhitney',
    spotify:
      'https://open.spotify.com/artist/6XpaIBNiVzIetEPCWDvAFP?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/5284515',
    apple: 'https://music.apple.com/mx/artist/whitney-houston/13952',
    soundcloud: 'https://soundcloud.com/whitneyhouston',
    tidal: 'https://listen.tidal.com/artist/1728',
    wiki: 'https://es.wikipedia.org/wiki/Whitney_Houston',
    ticketmaster:
      'https://www.ticketmaster.com/the-greatest-love-of-all-a-tickets/artist/1870905',
    tiktok: 'https://www.tiktok.com/@whitneyhouston',
    napster: 'https://music.amazon.com.mx/artists/B000RSFQJU/whitney-houston',
  },
  {
    title: 'Natalia Lafourcade',
    description:
      'María Natalia Lafourcade Silva (Ciudad de México, 26 de febrero de 1984), conocida como Natalia Lafourcade, es una cantante,​ compositora,​actriz,​productora musical,​ arreglista,​ diseñadora​ y activista mexicana.En su trayectoria artística ha ganado numerosos reconocimientos nacionales e internacionales, entre ellos tres Premios Grammy,​ quince premios Grammy Latino,​ cinco premios MTV Video Music Awards Latinoamérica,​ entre otros.Natalia fue la primera persona educada musicalmente bajo el Método Macarsi desarrollado por su madre para ayudarla en la rehabilitación tras un accidente con un caballo que sufrió a los 6 años.​ Sus éxitos iniciales llegaron en la década de los 2000 con apariciones sonoras en películas como Amar te duele y sus primeros sencillos, además de su álbum debut, que llegaron a los primeros lugares de popularidad en México y son considerados como algunas de las canciones más influyentes de esa época.',

    videourl: 'https://youtube.com/playlist?list=PL5F92A5550F076B41',
    price: '$50',
    coverImg: 'natalialafourcade.jpg',
    category: 'Baladas',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.lafourcade.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCKw8oP0rF37jTEq_3R8O5Mg',
    facebook: 'https://www.facebook.com/NataliaLafourcadeMx',
    instagram: 'https://www.instagram.com/natalialafourcade/',
    twitter: 'https://twitter.com/lafourcade',
    spotify: 'https://open.spotify.com/artist/1hcdI2N1023RvSwLzTtdsp',
    deezer: 'https://www.deezer.com/mx/artist/6722',
    apple: 'https://music.apple.com/mx/artist/natalia-lafourcade/14937569',
    soundcloud: 'https://soundcloud.com/natalialafourcade',
    tidal: 'https://listen.tidal.com/artist/3618024',
    wiki: 'https://es.wikipedia.org/wiki/Natalia_Lafourcade',
    ticketmaster:
      'https://www.ticketmaster.com/natalia-lafourcade-tickets/artist/1492041',
    tiktok: 'https://www.tiktok.com/@natalialafourcadeoficial',
    napster:
      'https://music.amazon.com.mx/artists/B000WXQ8L0/natalia-lafourcade',
  },

  {
    title: 'Gloria Trevi',
    description:
      ' Gloria de los Ángeles Treviño Ruiz (Monterrey, Nuevo León, 15 de febrero de 1968), conocida artísticamente como Gloria Trevi, es una cantante, actriz, productora, compositora y empresaria mexicana.En 1985 se mudó a la Ciudad de México para comenzar su carrera artística. Allí conoció al productor y arreglista Sergio Andrade, quien formó el grupo musical "Boquitas Pintadas". Cuatro años más tarde, de la mano de Andrade, publicó su álbum debut ...Qué hago aquí? , distribuido por BMG Ariola. En los 90, debutó en el cine con tres películas: Pelo suelto (1991), Zapatos viejos (1993) y Una papa sin catsup (1995). Terminó la década con cinco álbumes que sumaban casi 5 millones de ventas.',
    videourl:
      'https://youtube.com/playlist?list=PLszZ23pLzkUvIXPmh_x5TGeVpTazENODe',
    price: '$50',
    coverImg: 'gloriatrevi.jpg',
    category: 'Regional Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://gloriatrevi.lnk.to/IslaDivinaAlbum!YTD',
    youtube: 'https://www.youtube.com/channel/UCNZazBoobtvziOGR65QQVog',
    facebook: 'https://www.facebook.com/GloriaTreviOficial',
    instagram: 'https://www.instagram.com/gloriatrevi/',
    twitter: 'https://twitter.com/GloriaTrevi',
    spotify: 'https://open.spotify.com/artist/1Db5GsIoVWYktPoD2nnPZZ',
    deezer: 'https://www.deezer.com/mx/artist/7218',
    apple: 'https://music.apple.com/mx/artist/gloria-trevi/6711586',
    soundcloud: 'https://soundcloud.com/gloria-trevi-official',
    tidal: 'https://listen.tidal.com/artist/3752902',
    wiki: 'https://es.wikipedia.org/wiki/Gloria_Trevi',
    ticketmaster:
      'https://www.ticketmaster.com/gloria-trevi-tickets/artist/956252',
    tiktok: 'https://www.tiktok.com/@gloriatrevioficial',
    napster: 'https://music.amazon.com.mx/artists/B00130DI78/gloria-trevi',
  },

  {
    title: 'Shakira',
    description:
      'Shakira Isabel Mebarak Ripoll (Barranquilla, Atlántico, Colombia; 2 de febrero de 1977), conocida simplemente como Shakira, es una cantautora, bailarina, actriz, embajadora de buena voluntad de UNICEF y empresaria colombiana.',
    videourl: 'https://youtube.com/playlist?list=PL4324C6BD2E9BA7F8',
    price: 50,
    coverImg: 'Shakira.jpg',
    category: 'Pop',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.shakira.com/',
    youtube: 'https://www.youtube.com/results?search_query=Shakira',
    facebook: 'https://www.facebook.com/shakira',
    instagram: 'https://www.instagram.com/shakira/',
    twitter: 'https://www.facebook.com/shakira',
    spotify: 'https://open.spotify.com/artist/0EmeFodog0BfCgMzAIvKQp',
    deezer: 'https://www.deezer.com/mx/artist/160',
    apple: 'https://music.apple.com/mx/artist/shakira/889327',
    soundcloud: 'https://soundcloud.com/shakira',
    tidal: 'https://listen.tidal.com/artist/1087',
    wiki: 'https://es.wikipedia.org/wiki/Shakira',
    ticketmaster: 'https://www.ticketmaster.com./shakira-tickets/artist/779049',
    tiktok: 'https://www.tiktok.com/@shakira?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKEIZM/shakira',
  },
  {
    title: 'Karol G',
    description:
      'Carolina Giraldo Navarro Medellín, Colombia; 14 de febrero de 1991, conocida artísticamente como Karol G, es una cantante y compositora colombiana de reguetón, pop y trap latino.Se lanzó internacionalmente con la canción «301» junto con el cantante Reykon el líder en 2012, posicionado en el top de varias estaciones de radios y canales de televisión,3​ y le permitió realizar conciertos en países como Aruba, Colombia, Ecuador y Estados Unidos.4​ Obtuvo mayor reconocimiento en 2013, cuando lanzó la canción «Amor de dos» junto con Nicky Jam.5​ En 2016, luego de que lanzó varios sencillos, firmó un contrato con la discográfica Universal Music Latin Entertainment, y hubo un ascenso en su carrera..',
    videourl:
      'https://youtube.com/playlist?list=PLpTxN0bsTsj5GSZ4xME8tbhxez793WTob',
    price: '$50',
    coverImg: 'karolG.jpeg',
    category: 'Bachatta',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.karolgmusic.com/',
    youtube: 'https://www.youtube.com/channel/UCZuPJZ2kGFdlbQu1qotZaHw',
    facebook: 'https://www.facebook.com/KarolGOficial',
    instagram: 'https://www.instagram.com/karolg/',
    twitter: 'https://twitter.com/KarolGOnStats',
    spotify: 'https://open.spotify.com/artist/790FomKkXshlbRYZFtlgla',
    deezer: 'https://www.deezer.com/mx/artist/5297021',
    apple: 'https://music.apple.com/us/artist/karol-g/290814601',
    soundcloud: 'https://soundcloud.com/karolgofficial',
    tidal: 'https://listen.tidal.com/artist/5237820',
    wiki: 'https://es.wikipedia.org/wiki/Karol_G',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Karol+G',
    tiktok: 'https://www.tiktok.com/@karolg',
    napster: 'https://music.amazon.com.mx/artists/B00FN5IY46',
  },
  {
    title: 'Becky G',
    description:
      'Rebbeca Marie Gómez1​ (Inglewood, California; 2 de marzo de 1997),​ conocida artísticamente como Becky G, es una cantante y actriz estadounidense de origen mexicano. Obtuvo reconocimiento por primera vez en 2011 cuando comenzó a publicar videos de ella misma interpretando canciones populares en YouTube. Uno de sus videos llamó la atención del productor Dr. Luke, quien posteriormente le ofreció un contrato discográfico conjunto con Kemosabe Records y RCA Records. Mientras trabajaba en su debut, Gómez colaboró ​​​​con los artistas will.i.am, Cody Simpson y Cher Lloyd. Su sencillo debut oficial, «Becky from the Block» (2013), recibió una recepción positiva tras su lanzamiento. Lanzó su EP debut, Play It Again (2013), más tarde ese mismo año. Su segundo sencillo, Cant Get Enough» (2014), contó con la voz invitada de Pitbull y llegó a lo más alto de la lista Latin Rhythm Airplay en los Estados Unidos..',
    videourl:
      'https://youtube.com/playlist?list=PLAE2PW67Xm8biSTHd94VEUmvu7pbAnRDe',
    price: '$50',
    coverImg: 'beckyg.jpg',
    category: 'Pop Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.iambeckyg.com/',
    youtube: 'https://www.youtube.com/channel/UCgtNC51EUSgcZ6kKyVoPxKA',
    facebook: 'https://www.facebook.com/iambeckyg',
    instagram: 'https://www.instagram.com/iambeckyg/',
    twitter: 'https://twitter.com/iambeckyg',
    spotify:
      'https://open.spotify.com/artist/4obzFoKoKRHIphyHzJ35G3?si=3aYrWCo9RIemtKGtcwyDWA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/7218',
    apple: 'https://music.apple.com/us/artist/becky-g/550411604',
    soundcloud: 'https://soundcloud.com/iambeckyg',
    tidal: 'https://listen.tidal.com/artist/4903321',
    wiki: 'https://es.wikipedia.org/wiki/Becky_G',
    ticketmaster:
      'https://www.ticketmaster.com.mx/becky-g-boletos/artist/1896954',
    tiktok: 'https://www.tiktok.com/@iambeckyg',
    napster: 'https://music.amazon.com.mx/artists/B0088V0L5W/becky-g',
  },
  {
    title: 'Yuridia',
    description:
      'Yuridia Francisca Gaxiola Flores (Hermosillo, 4 de octubre de 1986), conocida como Yuridia, es una cantante y compositora mexicana. Saltó a la fama en 2005 como participante de la cuarta generación del reality show mexicano La Academia, donde obtuvo el segundo lugar.Cuenta con seis álbumes de estudio: La voz de un ángel, (2005), Habla el corazón (2006), Entre mariposas (2007), Nada es color de rosa (2009), Para mí (2011) y 6. (2015) y un álbum en vivo Primera fila: Yuridia(2017). Es una de las intérpretes de origen mexicano con gran logro comercial en la industria musical, registrando más de 2 millones de álbumes vendidos...',
    videourl:
      'https://youtube.com/playlist?list=PLyzN_J2WwjznyWCf3y9LQrG1Kz132CBFk',
    price: '$50',
    coverImg: 'Yuridia.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://yuritaflowers.com/',
    youtube: 'https://www.youtube.com/channel/UCrkMQGSWGoNokFc0vKfciKA',
    facebook: 'https://www.facebook.com/yuritaflowers',
    instagram: 'https://www.instagram.com/yuritaflowers/',
    twitter: 'https://twitter.com/yuritaflowers',
    spotify:
      'https://open.spotify.com/artist/5B8ApeENp4bE4EE3LI8jK2?autoplay=true',
    deezer: 'https://www.deezer.com/en/artist/274393',
    apple: 'https://music.apple.com/mx/artist/yuridia/119903555',
    soundcloud: 'https://soundcloud.com/yuridiamusic',
    tidal: 'https://listen.tidal.com/artist/3633725',
    wiki: 'https://es.wikipedia.org/wiki/Yuridia',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@iambeckyg',
    napster: 'https://music.amazon.com.mx/artists/B00136B3EC/yuridia',
  },
  {
    title: 'Belinda',
    description:
      'Belinda Peregrín Schüll (Madrid; 15 de agosto de 1989​), conocida monónimamente como Belinda, es una cantante y actriz hispanomexicana.Inició su carrera como actriz infantil cuando tenía apenas diez años de edad. En 2002, firmó con BMG y dos años más tarde lanzó su álbum debut, Belinda. Durante su primera década en la industria pop mexicana, Belinda se convirtió en una figura prominente de la música pop latina y de la cultura popular, con una vida personal muy publicitada, lo que la llevó a ser considerada «la princesa del pop latino».Canciones como «Boba niña nice» y «Ángel» se convirtieron en éxitos internacionales. En esta época colaboró con el grupo Moderatto para el tema «Muriendo lento». ',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mdDHjusH-QjBJqHRXdDPiOwwiCVlkPKXs',
    price: 50,
    coverImg: 'belinda.jpg',
    category: 'Pop',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://belinda.com/',
    youtube: 'https://www.youtube.com/channel/UCUi-2UQydc8Ws5NgsnwkhFQ',
    facebook: 'https://www.facebook.com/belindapop',
    instagram: 'https://www.instagram.com/belindapop/',
    twitter: 'https://twitter.com/belindapop',
    spotify: 'https://open.spotify.com/artist/5LeiVcEnsZcwc133TUhJNW',
    deezer: 'https://www.deezer.com/mx/artist/6329',
    apple: 'https://music.apple.com/mx/artist/belinda/6489626',
    soundcloud: 'https://soundcloud.com/belinda-official',
    tidal: 'https://listen.tidal.com/artist/56270',
    wiki: 'https://es.wikipedia.org/wiki/Belinda_(cantante)',
    ticketmaster:
      'https://www.ticketmaster.com.mx/belinda-tickets/artist/1170935',
    tiktok: 'https://www.tiktok.com/@belindatok?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000T17X9Q/belinda',
  },
  
]
export default CardData33


const CardData23 = [
  {
    title: 'Bone Thugs-N-Harmony',
    description:
      ' Bone Thugs-N-Harmony es un grupo de rap formado en Cleveland (Ohio) en 1991, conocido por su peculiar estilo a la hora de rapear y sus voces armónicas. Bone Thugs-N-Harmony es uno de los grupos de rap que más ventas ha tenido en toda la historia. Tuvieron un gran éxito en 1996 con el sencillo "Tha Crossroads", con el que incluso ganaron un Grammy. Es el único grupo que ha trabajado con 2pac, The Notorious B.I.G., Big Pun y Eazy-E en vida.El grupo se formó alrededor de 1990, se llamaba Bone Enterprise y editaron un disco (Faces of Death). El lanzamiento de Bone Thugs-N-Harmony Ruthless Records fue en 1993 cuando Bone fue a Los Ángeles y que llamaron por teléfono a Eazy-E, en este momento él se negó a firmarlos. Los Bone volvieron a Cleveland cuando Eazy-E daba un concierto ahí. Bizzy y Krayzie entraron a su habitación y cantaron, Eazy-E no entendía lo que ellos estaban cantando pero se dio cuenta de que era algo nuevo y bueno, después volvió a Los Ángeles con los Bone Thugs. Con Creepin On A Come Up (EP) que vio la luz en junio del 1994. Después de un lento comienzo que vio el éxito del álbum limitado dentro de la escena del Gangsta rap, rompiendo con la corriente principal con el lanzamiento de los sencillos "Thuggish Ruggish Bone" y "Foe Tha Love of $", este último con una exitosa colaboración por parte del rapero Eazy-E grabada durante "La Edad Dorada" del rap de la Costa Oeste. La producción del álbum contó con una pesada impresión en él, con beats provistos por Rhythm D, DJ Yella y el recién llegado DJ U-Neek (quien más adelante llegaría a ser socio en Bone´s productions). Este disco llegaría a conseguir 4 discos de platino de la RIAA.',
    videourl: 'https://youtube.com/playlist?list=OLAK5uy_m9FMC79W84x7GtHJXeWBkMajNZCrpHD6A&si=EHaBDnS76f1nO_yk',
    price: '$50',
    coverImg: 'bone.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://myspace.com/bonethugsnharmony',
    youtube: 'https://www.youtube.com/channel/UCjSkdzVdUS1crC89RI2cNWw',
    facebook: 'https://www.facebook.com/OfficialBoneThugs/',
    instagram: 'https://www.instagram.com/btnhlive/',
    spotify: 'https://open.spotify.com/artist/5spEJXLwD1sKUdC2bnOHPg?autoplay=true',
    apple: 'https://music.apple.com/mx/artist/bone-thugs-n-harmony/1493325',
    wiki:
      'https://es.wikipedia.org/wiki/Bone_Thugs-n-Harmony',
    ticketmaster:
      'https://www.ticketmaster.com/bone-thugs-n-harmony-tickets/artist/821228',
    tiktok: 'https://www.tiktok.com/tag/dubc',
    napster: 'https://music.amazon.com.mx/artists/B009PU2MPM/krayzie-bone-bone-thugs-n-harmony?do=play&agent=googleAssistant&ref=dmm_seo_google_gkp_artists',
  },
  {
    title: 'Dub-C',
    description:
      ' William LaShawn Calhoun Jr. (nacido el 3 de febrero de 1970), mejor conocido como WC (pronunciado "dub-c"), es un rapero y actor estadounidense. Nacido en Houston, Texas, se mudó a South Central, Los Ángeles, durante su infancia y es el hermano mayor del DJ Crazy Toones. Comenzó su carrera como miembro del grupo Low Profile y luego formó WC and the Maad Circle, logrando éxito con el sencillo "Aint A Damn Thang Changed". Más tarde inició una carrera como solista, lanzando cuatro álbumes, y es reconocido por ser parte del supergrupo de rap Westside Connection junto a Ice Cube y Mack 10. Su nombre artístico, WC, proviene de sus iniciales y no está relacionado con West Coast ni con Westside Connection, como suele pensarse. Además, trabajó como entrenador de dialecto de Los Ángeles para el actor Damson Idris en la serie Snowfall de FX.',
    videourl: 'https://youtube.com/playlist?list=PL27BA28E1F273F296&si=cPoR8iGnT6u86Pn7',
    price: '$50',
    coverImg: 'dub-c.jpeg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://rap.fandom.com/wiki/WC',
    youtube: 'https://www.youtube.com/channel/UCTPETLis5DMhPSu-GQVLGlg',
    facebook: 'https://www.facebook.com/profile.php?id=100063602475736',
    instagram: 'https://www.instagram.com/_westside_connection_/',
    twitter: 'https://x.com/TherealDubWC',
    spotify: 'https://open.spotify.com/artist/3NgwMzys77S7sgfh2W5k0V',
    deezer: 'https://www.deezer.com/mx/artist/2540',
    apple: 'https://music.apple.com/mx/artist/wc/7305531',
    soundcloud: 'https://soundcloud.com/wc-official',
    tidal: 'https://listen.tidal.com/artist/3629521',
    wiki:
      'https://es.wikipedia.org/wiki/WC_(rapero)',
    ticketmaster:
      'https://www.ticketmaster.com/wc-tickets/artist/772562',
    tiktok: 'https://www.tiktok.com/tag/dubc',
    napster: 'https://www.deezer.com/mx/artist/2540',
  },{
    title: 'South Central Cartel',
    description:
      ' Otra Compilacion Musical de Rap Americano con Integrantes como Snoop Dogg, el productor Dr. Dre. B-Real, DMX, Xzibit,Method Man, NateDogg, Eminem y Ice Cube',
    videourl: 'http://youtube.com/playlist?list=UUMZgyyLhyU0df6ascNEU38w',
    price: '$50',
    coverImg: 'SouthCentral.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.hiphop90s.com/',
    youtube: 'https://www.youtube.com/channel/UC-OO324clObi3H-U0bP77dw',
    facebook: 'https://www.facebook.com/90sHipHopEvolution/',
    instagram: 'https://www.instagram.com/westcoast.90s/',
    twitter: 'https://twitter.com/snoopdogg',
    spotify: 'https://open.spotify.com/playlist/5BoMzGlwqsF88ndf0SpTgg',
    deezer: 'https://www.deezer.com/mx/artist/15557',
    apple: 'https://music.apple.com/mx/artist/south-central-cartel/1971959',
    soundcloud: 'https://soundcloud.com/south-central-cartel-official',
    tidal: 'https://listen.tidal.com/artist/3815634',
    wiki:
      'https://es.wikipedia.org/wiki/Barrios_y_regiones_de_Los_%C3%81ngeles',
    ticketmaster:
      'https://www.ticketmaster.com/search?q=Los+Angeles+Rap+Events',
    tiktok: 'https://www.tiktok.com/@hood.rapz',
    napster: 'https://music.amazon.com.mx/artists/B000RHOCFK/south-central-cartel',
  },
  {
    title: 'Snoop Dogg',
    description:
      'Calvin Cordozar Broadus Jr., conocido artísticamente como Snoop Dogg, y en raras ocasiones como Snoop Lion, es un cantante, productor y actor estadounidense. Es uno de los artistas de hip-hop con más éxito en el Gangsta rap. Además, es uno de los más notables amigos del productor Dr. Dre.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUzfmPRUQOBPUSRaXJSw3s2',
    price: '$50',
    coverImg: 'SnoopDogg.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://snoopdogg.com/',
    youtube: 'https://www.youtube.com/channel/UC-OO324clObi3H-U0bP77dw',
    facebook: 'https://www.facebook.com/snoopdogg',
    instagram: 'https://www.instagram.com/snoopdogg/',
    twitter: 'https://twitter.com/snoopdogg',
    spotify: 'https://open.spotify.com/artist/7hJcb9fa4alzcOq3EaNPoG',
    deezer: 'https://www.deezer.com/mx/artist/3',
    apple: 'https://music.apple.com/mx/artist/snoop-dogg/21769',
    soundcloud: 'https://soundcloud.com/snoopdogg',
    tidal: 'https://listen.tidal.com/artist/8914',
    wiki: 'https://es.wikipedia.org/wiki/Snoop_Dogg',
    ticketmaster:
      'https://www.ticketmaster.com/snoop-dogg-tickets/artist/763942',
    tiktok: 'https://www.tiktok.com/@snoopdogg?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QK7JQW/snoop-dogg',
  },

  {
    title: 'Jay Z',
    description:
      'Shawn Corey Carter (Brooklyn, Nueva York, 4 de diciembre de 1969), más conocido por su nombre artístico Jay-Z (estilizado como JAY-Z), es un rapero, productor, empresario y actor estadounidense. Es uno de los artistas de hip-hop más exitosos económicamente en Estados Unidos y mundialmente, y posee un patrimonio neto de más de US$2.500 millones en 2024.Ha vendido aproximadamente 75 millones de álbumes en todo el mundo, y ha recibido 83 nominaciones a los premios Grammy por su trabajo musical, convirtiéndolo en el músico más nominado en la historia de esos premios, de las cuales ha ganado 23. Es considerado como uno de los mejores raperos de todos los tiempos. Clasificó como número uno de la controvertida lista de The Greatest MCs of All-Time elaborada por la cadena de televisión MTV en 2006. Tres de sus álbumes, Reasonable Doubt (1996), The Blueprint (2001) y The Black Album (2003), se consideran hitos en el género y los tres clasifican en la lista de los 500 mejores álbumes de todos los tiempos de la revista Rolling Stone, quien también lo calificó como el 88.º mejor artista de los 100 mejores artistas de todos los tiempos',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nnGAnvGYiX88LAahgDAbBAZy_z0R1Cdro&si=7AzVfMq2EuppU7nx',
    price: 36,
    coverImg: 'jay-z.jpg',
    category: 'Hip Hop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.rocnation.com/',
    youtube: 'https://www.youtube.com/channel/UCN-sc1xJr-QQNj_uNIM9wTA',
    facebook: 'https://www.facebook.com/JayZ/',
    instagram: 'https://www.instagram.com/jayz/',
    twitter: 'https://x.com/sc',
    spotify: 'https://open.spotify.com/artist/3nFkdlSjzX9mRTtwJOzDYB',
    deezer: 'https://www.deezer.com/mx/artist/1309',
    apple: 'https://music.apple.com/mx/artist/jay-z/1352449404',
    soundcloud: 'https://soundcloud.com/thecarters',
    tidal: 'https://listen.tidal.com/artist/8963',
    wiki: 'https://es.wikipedia.org/wiki/Jay-Z',
    ticketmaster:
      'https://www.ticketmaster.com/jayz-tickets/artist/781009',
    tiktok: 'https://www.tiktok.com/@jay_zofficial01?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJHOWW/jay-z',
  },
  {
    title: 'Diddy',
    description:
      'Sean John Combs (Harlem, 4 de noviembre de 1969) conocido artísticamente como Diddy o Puff Daddy, es un rapero, compositor, productor discográfico, actor y empresario estadounidense, ganador de cuatro Premios Grammy.Fue mánager y amigo de Biggie Smalls (Notorious B.I.G.) y Wavy Squad, uno de sus descubridores, participó en todos sus videoclips y sin duda fue su empuje para dejar el mundo de las drogas. Es conocido también por su enemistad con el rapero Tupac Shakur y el productor Suge Knight.Combs ha sido objeto de una serie de acusaciones de conducta sexual inapropiada, que comenzaron a fines de 2023 después de que Cassie Ventura, con quien Combs tuvo una relación a largo plazo, presentó una demanda multimillonaria en su contra por agresión sexual, que se resolvió fuera de los tribunales. En los días siguientes, se presentaron tres demandas más por demandantes adicionales, con acusaciones similares de mala conducta que van desde 1991 hasta 2003. A raíz de las acusaciones, varias empresas y marcas se desvincularon de Combs y se revocó un doctorado honorario de la Universidad de Howard. En marzo de 2024, el Departamento de Seguridad Nacional allanó varias propiedades vinculadas a Combs como parte de una investigación en curso. En mayo de 2024, se publicaron imágenes de vigilancia de Combs agrediendo físicamente a Ventura en un hotel en 2016, por lo que Combs emitió una disculpa pública',
    videourl:
      'https://youtube.com/playlist?list=PLO_91L9g7dwNDhhZg-sjIhjSd2OKkjMrk&si=rCkcnes7meWXbo4Q',
    price: 36,
    coverImg: 'diddy.jpg',
    category: 'Hip Hop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://diddy.com/',
    youtube: 'https://www.youtube.com/channel/UCPlVQrTbx0UxYtilJ3CVu0Q',
    facebook: 'https://www.facebook.com/Diddy/',
    instagram: 'https://www.instagram.com/diddy',
    twitter: 'https://x.com/diddy',
    spotify: 'https://open.spotify.com/artist/59wfkuBoNyhDMQGCljbUbA',
    deezer: 'https://www.deezer.com/mx/artist/173581',
    apple: 'https://music.apple.com/mx/artist/beastie-boys/1971863',
    soundcloud: 'https://soundcloud.com/diddy8',
    tidal: 'https://tidal.com/browse/artist/3503485',
    wiki: 'https://es.wikipedia.org/wiki/Sean_Combs',
    ticketmaster:
      'https://www.ticketmaster.com/diddy-tickets/artist/828609',
    tiktok: 'https://www.tiktok.com/@diddy?lang=es',
    napster: 'https://music.amazon.com/artists/B09LVMXZBV/diddy',
  },
  {
    title: 'Lil Nas x',
    description:
      'Montero Lamar Hill (Lithia Springs, Georgia; 9 de abril de 1999), más conocido por su nombre artístico Lil Nas X, es un rapero, cantante y compositor estadounidense. Ganó reconocimiento con el éxito de su tema «Old Town Road», el cual se hizo viral en TikTok y alcanzó el número 1 en al menos veinte países, entre estos Canadá y los Estados Unidos, donde también fue certificado con disco de diamante por sus ventas.3​4​ Con 18.4 millones de unidades, fue la segunda canción más vendida del 2019 a nivel mundial.5​ Además de ello, «Old Town Road» se alzó con un galardón en los American Music Awards y con dos en los premios Grammy y los MTV Video Music Awards.6​7​8​ Lil Nas X volvió a alcanzar el #1 en la mayoría de listas mundiales con su nuevo sencillo MONTERO (Call Me By Your Name). Sin embargo, esta vez fue objeto de una gran polémica en torno al vídeo oficial de la canción.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kXbw-RP4zqFhCiqfSkUyKHxMb8bTM3Qnw',
    price: 50,
    coverImg: 'lilnasx.jpg',
    category: 'Hip Hop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.welcometomontero.com/',
    youtube: 'https://www.youtube.com/channel/UC_uMv3bNXwapHl8Dzf2p01Q',
    facebook: 'https://www.facebook.com/LilNasX',
    instagram: 'https://www.instagram.com/lilnasx/',
    twitter: 'https://twitter.com/lilnasxfp',
    spotify: 'https://open.spotify.com/artist/7jVv8c5Fj3E9VhNjxT4snq',
    deezer: 'https://www.deezer.com/mx/artist/15166511',
    apple: 'https://music.apple.com/mx/artist/lil-nas-x/1400730578',
    soundcloud: 'https://soundcloud.com/secret-service-862007284',
    tidal: 'https://listen.tidal.com/artist/9996466',
    wiki: 'https://es.wikipedia.org/wiki/Lil_Nas_X',
    ticketmaster:
      'https://www.ticketmaster.com/lil-nas-x-tickets/artist/2669155',
    tiktok: 'https://www.tiktok.com/@lilnasx?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B07FFZTKDN/lil-nas-x',
  },
  {
    title: 'Drake',
    description:
      'Aubrey Drake Graham (Toronto, 24 de octubre de 1986), conocido simplemente como Drake, es un rapero, cantante, compositor, productor discográfico y actor canadiense. Originalmente se hizo conocido por interpretar al personaje de Jimmy Brooks de la serie de televisión Degrassi: The Next Generation. Más tarde saltó a la fama como cantante de rap, lanzando varios mixtapes antes de firmar con Lil Wayne en la compañía discográfica estadounidense Young Money Entertainment en junio de 2006',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kVksJcxJUO5_vh6-O0iVzs6m9BxAiAFSo',
    price: 50,
    coverImg: 'drake.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://drakerelated.com/#front',
    youtube: 'https://www.youtube.com/channel/UCByOQJjav0CUDwxCk-jVNRQ',
    facebook: 'https://www.facebook.com/Drake',
    instagram: 'https://www.instagram.com/drake/',
    twitter: 'https://twitter.com/Drake',
    spotify: 'https://open.spotify.com/artist/3TVXtAsR1Inumwj472S9r4',
    deezer: 'https://www.deezer.com/mx/artist/246791',
    apple: 'https://music.apple.com/mx/artist/drake/271256',
    soundcloud: 'https://soundcloud.com/octobersveryown',
    tidal: 'https://listen.tidal.com/artist/3632070',
    wiki: 'https://es.wikipedia.org/wiki/Drake_(m%C3%BAsico)',
    ticketmaster: 'https://www.ticketmaster.com/Drake-tickets/artist/1319371',
    tiktok: 'https://www.tiktok.com/@drakee.ita?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJRIHS/drake',
  },
  {
    title: 'Eminem',
    description:
      'Marshall Bruce Mathers III, conocido artísticamente como Eminem o como Slim Shady, es un rapero, productor discográfico y actor estadounidense. Su nombre artístico proviene de la unión de las iniciales de Marshall Mathers: M&M, mientras que su segundo nombre artístico se hizo conocer por primera vez en 1997 con su primer EP titulado The Slim Shady EP..',
    videourl:
      'http://youtube.com/playlist?list=PLN1mxegxWPd0F2qVdZUBJ7mwWaHONMf6N',
    price: '$50',
    coverImg: 'Eminem.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.eminem.com/',
    youtube: 'https://www.youtube.com/channel/UCmHhviensDlGQeU8Yo80zdg',
    facebook: 'https://www.facebook.com/eminem',
    instagram: 'https://www.instagram.com/eminem/',
    twitter: 'https://twitter.com/Eminem',
    spotify: 'https://open.spotify.com/artist/7dGJo4pcD2V6oG8kP0tJRR',
    deezer: 'https://www.deezer.com/mx/artist/13',
    apple: 'https://music.apple.com/mx/artist/eminem/111051',
    soundcloud: 'https://soundcloud.com/eminemofficial',
    tidal: 'https://listen.tidal.com/artist/17275',
    wiki: 'https://es.wikipedia.org/wiki/Eminem',
    ticketmaster: 'https://www.ticketmaster.com/eminem-tickets/artist/722998',
    tiktok: 'https://www.tiktok.com/@eminem?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000S2JA4W/eminem',
  },
  {
    title: 'Daddy Yankee',
    description:
      'Ramón Luis Ayala Rodriguez (San Juan, 3 de febrero de 1977), mejor conocido como Daddy Yankee, es un cantante, rapero, compositor, actor, productor discográfico, locutor de radio y empresario puertorriqueño. Reconocido como el máximo exponente del reggaeton y del género urbano latino.Diversos sectores de la crítica le consideran el Rey del Reguetón,por ser uno de los pioneros del nacimiento del género durante la década de 1990 y haberlo popularizado en América Latina y el resto del mundo, tras haberse consolidado como uno de los artistas más respetados e influyentes del circuito urbano latino.De la misma manera, el portal estadounidense AllMusic afirma que el puertorriqueño «ayudó a establecer el reguetón como un estilo de música durante el siglo XXI',
    videourl:
      'http://youtube.com/playlist?list=PLQsoGpd-IvXDsEZpwiMkm0gy8LNY2SZMG',
    price: 50,
    coverImg: 'daddyankee.jpg',
    category: 'Reggaeton',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://dy.daddyyankee.com/',
    youtube: 'https://www.youtube.com/c/daddyyankee',
    facebook: 'https://www.facebook.com/daddyyankee',
    instagram: 'https://www.instagram.com/daddyyankee/',
    twitter: 'https://twitter.com/DYFANPAGE',
    spotify: 'https://open.spotify.com/artist/4VMYDCV2IEDYJArk749S6m',
    deezer: 'https://www.deezer.com/mx/artist/3839',
    apple: 'https://music.apple.com/mx/artist/daddy-yankee/25514958',
    soundcloud: 'https://soundcloud.com/daddy-yankee-official',
    tidal: 'https://listen.tidal.com/artist/9502',
    wiki: 'https://es.wikipedia.org/wiki/Daddy_Yankee',
    ticketmaster:
      'https://www.ticketmaster.com./daddy-yankee-tickets/artist/935043',
    tiktok: 'https://www.tiktok.com/@daddyyankee?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKA31U/daddy-yankee',
  },
  {
    title: 'Long Beach Finest Hits',
    description:
      'Compilacion Musical de Rap Americano con Integrantes como Snoop Dogg, el productor Dr. Dre. B-Real, DMX, Xzibit,Method Man, NateDogg, Eminem y Ice Cube',
    videourl: 'http://youtube.com/playlist?list=UUIdSwuzRlWb3AlA8uWPvbaA',
    price: '$50',
    coverImg: 'LongBeachFinest.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.hiphop90s.com/',
    youtube: 'https://www.youtube.com/channel/UC-OO324clObi3H-U0bP77dw',
    facebook: 'https://www.facebook.com/90sHipHopEvolution/',
    instagram: 'https://www.instagram.com/westcoast.90s/',
    twitter: 'https://twitter.com/snoopdogg',
    spotify: 'https://open.spotify.com/playlist/5BoMzGlwqsF88ndf0SpTgg',
    deezer: 'https://www.deezer.com/search/WEstcoast%20rap/playlist',
    apple:
      'https://music.apple.com/mx/playlist/west-coast-hip-hop-imprescindibles/pl.b553cd52fbf249d1b9b0bfe3e0cff543',
    soundcloud: 'https://soundcloud.com/longbeachfinest',
    tidal: 'https://listen.tidal.com/album/105295116/track/105295117',
    wiki: 'https://es.wikipedia.org/wiki/Long_Beach_(California)',
    ticketmaster:
      'https://www.ticketmaster.com/vanilla-ice-tickets/artist/702531',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=long%20beach%20rap&t=1660899147648',
    napster: 'https://music.amazon.com.mx/playlists/B09YK1YC6P',
  },

  {
    title: 'Mack 10',
    description:
      "David Rolison o D'Mon Rolison, más conocido como Mack 10, es un cantante de gangsta rap y actor de origen afroestadounidense y descendiente de padres mexicanos, nacido en Inglewood, California, el 9 de agosto de 1971. Es el exmiembro del trío Westside Connection, junto a Ice Cube y WC. A la edad de 24 lanzó su álbum debut, apodado del mismo nombre que él. Hasta la fecha se ha convertido en un personaje conocido en el mundo de la música y el cine. Aparte ha colaborado en numerosos temas de conocidos raperos como Ice Cube, Warren G, o Snoop Dogg. En 2005 publicó su último álbum hasta la fecha, Hustla's Handbook, junto con el conocido sencillo 'Like This', con Nate Dogg. Mack 10 se casó con Tionne Watkins 'T-Boz de TLC' en 2000 y tienen una hija, Chase Rolison, nacida ese mismo año. Es amigo íntimo de Snoop Dogg.",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lxtvSvM2HlF79mQ7kYKMN-4NMAUEebKxI',
    price: 50,
    coverImg: 'mack10.jpeg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://genius.com/artists/Mack-10',
    youtube: 'https://www.youtube.com/channel/UCSPk05HPOctMkcndtOvJfnw',
    facebook: 'https://www.facebook.com/watch/mack10.music/',
    instagram: 'https://www.instagram.com/westsidemack10/',
    twitter: 'https://twitter.com/OfficialMack10',
    spotify: 'https://open.spotify.com/artist/3USpNaxpX1iNqNnQWqg9ob',
    deezer: 'https://www.deezer.com/mx/artist/14353',
    apple: 'https://music.apple.com/mx/artist/mack-10/471389',
    soundcloud: 'https://soundcloud.com/mack-10-official',
    tidal: 'https://listen.tidal.com/artist/10858',
    wiki: 'https://es.wikipedia.org/wiki/Mack_10',
    ticketmaster: 'https://www.ticketmaster.com/mack-10-tickets/artist/775484',
    tiktok: 'https://www.tiktok.com/search/video?q=mack%2010&t=1661799721412',
    napster: 'https://music.amazon.com.mx/artists/B000RSFUBO/ice-cube',
  },
  {
    title: 'Westside Connection',
    description:
      'Westside Connection fue un grupo de west coast rap formado por Ice Cube, Mack 10 y WC. Su primer álbum, Bow Down, alcanzó el segundo puesto en Billboard 200 en el año 1996.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nZvP2YCin83o6-qemtdaGfdM6HZXyuhHA',
    price: 50,
    coverImg: 'westsideconnection.jpeg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://genius.com/artists/Westside-connection',
    youtube: 'https://www.youtube.com/channel/UCKufI_BH1qyYJiUwnEw5qMg',
    facebook: 'https://www.facebook.com/westsideconnection.music',
    instagram: 'https://www.instagram.com/westside_connection__/',
    twitter: 'https://twitter.com/WestSideLif3',
    spotify: 'https://open.spotify.com/artist/3zNM2tRfTX6LI1lN2PlrTt',
    deezer: 'https://www.deezer.com/mx/artist/9139',
    apple: 'https://music.apple.com/mx/artist/westside-connection/471394',
    soundcloud: 'https://soundcloud.com/westside-connection-official',
    tidal: 'https://listen.tidal.com/artist/10857',
    wiki: 'https://es.wikipedia.org/wiki/Westside_Connection',
    ticketmaster:
      'https://www.ticketmaster.com/westside-connection-tickets/artist/780763',
    tiktok:
      'https://www.tiktok.com/search/video?q=Westside%20Connection&t=1661798282509',
    napster: 'https://music.amazon.com.mx/artists/B000QKG6YS/westside-connection',
  },
  {
    title: 'Ice Cube',
    description:
      "O'Shea Jackson (Los Ángeles, California, 15 de junio de 1969), conocido artísticamente como Ice Cube, es un rapero y actor estadounidense. Comenzó su carrera a finales de los años 80 como miembro del polémico grupo de gangsta rap, N.W.A., y posteriormente lanzó su exitosa carrera en solitario en la música y en el cine.",
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_keoFGioICvjaGg6rPUp13EzvwStWuCtYc',
    price: '$50',
    coverImg: 'IceCube.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://icecube.com/',
    youtube: 'https://www.youtube.com/user/Cubevision',
    facebook: 'https://www.facebook.com/IceCube',
    instagram: 'https://www.instagram.com/icecube/',
    twitter: 'https://twitter.com/icecube',
    spotify: 'https://open.spotify.com/artist/3Mcii5XWf6E0lrY3Uky4cA',
    deezer: 'https://www.deezer.com/mx/artist/2353',
    apple: 'https://music.apple.com/mx/artist/ice-cube/471370',
    soundcloud: 'https://soundcloud.com/ice-cube',
    tidal: 'https://listen.tidal.com/artist/9126',
    wiki: 'https://es.wikipedia.org/wiki/Ice_Cube',
    ticketmaster: 'https://www.ticketmaster.com/ice-cube-tickets/artist/702485',
    tiktok: 'https://www.tiktok.com/@icecubeofficialll?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSFUBO/ice-cube',
  },
  {
    title: 'Dr Dre',
    description:
      'Andre Romelle Young (Compton, California; 18 de febrero de 1965), más conocido por su nombre artístico Dr. Dre, es un rapero, productor y empresario estadounidense. Inspirado por la canción de Grandmaster Flash The Adventures Of Grandmaster Flash On The Wheels Of Steel, Young asistía a menudo a un club llamado The Eve After Dark para ver cómo tocaban los DJs y raperos en directo. Posteriormente se convertiría en DJ del club, inicialmente bajo el nombre Dr. J, basado en el apodo de Julius Erving, su jugador de baloncesto favorito. En el club conoció al aspirante a rapero Antoine Carraby, que más tarde pasaría a ser miembro de N.W.A. como DJ Yella. Poco después adoptó el apodo de Dr. Dre',
    videourl: 'http://youtube.com/playlist?list=UUx4Lxn5CzeG99X01__YsJpw',
    price: '$50',
    coverImg: 'DrDre.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.drdre.com/',
    youtube: 'https://www.youtube.com/user/DrDreVEVO',
    facebook: 'https://www.facebook.com/DrDre',
    instagram: 'https://www.instagram.com/drdre/',
    twitter: 'https://twitter.com/drdre',
    spotify: 'https://open.spotify.com/artist/6DPYiyq5kWVQS4RGwxzPC7',
    deezer: 'https://www.deezer.com/mx/artist/763',
    apple: 'https://music.apple.com/mx/artist/dr-dre/35315',
    soundcloud: 'https://soundcloud.com/dr-dre-music',
    tidal: 'https://listen.tidal.com/artist/3963798',
    wiki: 'https://es.wikipedia.org/wiki/Dr._Dre',
    ticketmaster:
      'https://www.ticketmaster.com/snoop-dogg-tickets/artist/763942',
    tiktok: 'https://www.tiktok.com/@dr_dre39?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKKBL2/dr-dre',
  },
  {
    title: 'TRU',
    description:
      " Tru 2 da Game is the fourth studio album released by New Orleans hip-hop group, TRU. It was released February 18, 1997 on No Limit Records and was produced by Beats By the Pound . The album found a large amount of success, peaking at #8 on the Billboard 200 and #2 on the Top R&B/Hip-Hop Albums selling 200,000 copies in its first week. A single from the album entitled 'I Always Feel Like...' also found some success, peaking at #71 on the Billboard Hot 100, #11 on the Hot Rap Singles and #42 on the Hot R&B/Hip-Hop Singles & Tracks. The same year the album was re-released with the song 'Smoking Green' removed. The re-released version included three new songs, a club mix version of 'No Limit Soldiers', a C-Murder solo song 'Eyes Of A Killa' and a Silkk The Shocker solo song 'Pimp Shit.' 'FEDz' samples Aaliyah's hit single 'If Your Girl Only Knew'. The album was certified 2× Platinum by the RIAA, on October 2, 1997, and remains the group's most successful album",
    videourl:
      'https://youtube.com/playlist?list=PL7aarnVNQgM5EcJr9pjLc2NfJ8Rqa05E2',
    price: 50,
    coverImg: 'tru.jpeg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://genius.com/Tru-no-limit-soldiers-lyrics',
    youtube: 'https://www.youtube.com/channel/UCAJOY_M0Q8GSXoySK9XRAyA',
    facebook: 'https://www.facebook.com/search/top?q=no%20limit%20soldiers',
    instagram: 'https://www.instagram.com/no.limit_soldier/',
    twitter: 'https://twitter.com/TruRAPPresents',
    spotify: 'https://open.spotify.com/album/2tey8jUfcRDCcu6NuK6YA6',
    deezer: 'https://www.deezer.com/mx/album/305766',
    apple: 'https://music.apple.com/mx/artist/tru/54831496',
    soundcloud: 'https://soundcloud.com/tru-official',
    tidal: 'https://listen.tidal.com/album/1350709',
    wiki: 'https://en.wikipedia.org/wiki/Tru_2_da_Game',
    ticketmaster:
      'https://www.ticketmaster.com/no-limit-soldiers-tickets/artist/2853776',
    tiktok: 'https://www.tiktok.com/@nolimit_soldiers_fan',
    napster: 'https://music.amazon.com/artists/B0C7BP92DQ/tru',
  },
  {
    title: 'Master P',
    description:
      " Percy Robert Miller Sr. (born April 29, 1967), known by his stage name Master P, is an American rapper, record executive, actor, and entrepreneur. He is the founder of the record label No Limit Records, which was relaunched as New No Limit Records through Universal Records and Koch Records, and again as Guttar Music Entertainment, and currently, No Limit Forever Records. He is also the founder and CEO of P. Miller Enterprises and Better Black Television, which was a short-lived online television network. Miller initially gained fame in the mid-1990s with the success of his hip hop group TRU as well as his fifth solo rap album Ice Cream Man, which contained his first single- Mr. Ice Cream Man. Miller gained further popularity in 1997 after the success of his Platinum single Make 'Em Say Uhh!'. In total, Miller has released 15 studio albums.",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nILbqlQdV0rCe65-zJXJ9WbOwX3pV-hr0',
    price: 50,
    coverImg: 'masterp.jpeg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.trutanksoldiers.com/',
    youtube: 'https://www.youtube.com/channel/UCThBa6GGBRlqKLkWc9CVZMA',
    facebook: 'https://www.facebook.com/MasterP',
    instagram: 'https://www.instagram.com/MasterP/',
    twitter: 'https://twitter.com/MasterPMiller',
    spotify: 'https://open.spotify.com/artist/7zICaxnDB9ZprDSiFpvbbW',
    deezer: 'https://www.deezer.com/mx/artist/9250',
    apple: 'https://music.apple.com/mx/artist/master-p/1185894',
    soundcloud: 'https://soundcloud.com/master-p-official',
    tidal: 'https://listen.tidal.com/artist/10851',
    wiki: 'https://en.wikipedia.org/wiki/Master_P',
    ticketmaster: 'https://www.ticketmaster.com/master-p-tickets/artist/849545',
    tiktok: 'https://www.tiktok.com/@masterpofficial',
    napster: 'https://music.amazon.com/artists/B000QJI6CO/master-p',
  },
  {
    description:
      "Silkk the Shocker rose to fame in the mid-late 1990s. After striking a deal with Priority, No Limit Records released Silkk's debut album The Shocker in August 1996, produced by Beats By The Pound, DJ Daryl, K-Lou and T-Bone.",
    title: 'Silk the Shocker',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kaoVHiKUIpbxUK7ovuEP8bsm-jFlactBM',
    price: 50,
    coverImg: 'silk.jpeg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://genius.com/Tru-no-limit-soldiers-lyrics',
    youtube: 'https://www.youtube.com/channel/UCq6sFbOtae6epzplQ7o6OUg',
    facebook: 'https://www.facebook.com/silkk.shocker',
    instagram: 'https://www.instagram.com/silkktheshocker/',
    twitter: 'https://twitter.com/cmonrock',
    spotify: 'https://open.spotify.com/artist/0iEVT8dLNSSmipPOrDGu2z',
    deezer: 'https://www.deezer.com/mx/artist/6452',
    apple: 'https://music.apple.com/mx/artist/silkk-the-shocker/555442',
    soundcloud: 'https://soundcloud.com/silktheshocker-music',
    tidal: 'https://listen.tidal.com/artist/28926',
    wiki: 'https://en.wikipedia.org/wiki/Silkk_the_Shocker',
    ticketmaster:
      'https://www.ticketmaster.com/silkk-the-shocker-tickets/artist/12683',
    tiktok:
      'https://www.tiktok.com/search/video?q=silk%20the%20shocker&t=1661803217402',
    napster: 'https://music.amazon.com/albums/B0031R9BOK',
  },
  {
    title: 'Too Short',
    description:
      ' Todd Anthony Shaw (nacido el 28 de abril de 1966), más conocido por el nombre artístico Too Short (estilizado como Too $hort), es un rapero y productor discográfico estadounidense. Se hizo famoso en la escena hip hop de la costa oeste a fines de la década de 1980, con letras a menudo basadas en el proxenetismo y la promiscuidad, pero también en la cultura de las drogas y la supervivencia callejera; [6] este último en canciones como The Ghetto y el primero en canciones como Blow the Whistle',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lmQuBpP1hrqVsu6b2CW3vWIz8KT95mLJM',
    price: 50,
    coverImg: 'TooShort.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://tooshortstore.com/',
    youtube: 'https://www.youtube.com/channel/UCT5WSvzLbxkwTvTwpPVkUFw',
    facebook: 'https://www.facebook.com/tooshort',
    instagram: 'https://www.instagram.com/tooshort/',
    twitter: 'https://twitter.com/tooshort',
    spotify: 'https://open.spotify.com/artist/4sb7rZNN93BSS6Gqgepo4v',
    deezer: 'https://www.deezer.com/mx/artist/504125',
    apple: 'https://music.apple.com/mx/artist/too-$hort/145173964',
    soundcloud: 'https://soundcloud.com/therealtooshort',
    tidal: 'https://listen.tidal.com/artist/6408',
    wiki: 'https://es.wikipedia.org/wiki/Too_$hort',
    ticketmaster:
      'https://www.ticketmaster.com/too-short-tickets/artist/840782',
    tiktok: 'https://www.tiktok.com/@tooshortdog?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJKCA8/too-hort',
  },
  {
    title: 'Suga Free',
    description:
      " Suga Free was born in Gardena and raised in Oakland, California,later becoming based in Pomona, California.He began his professional rapping career working with DJ Quik, with Quik serving as the producer on his debut album, Street Gospel, released in 1997, which reached number 37 on the Billboard Top R&B/Hip-Hop Albums chart.He made guest appearances on Xzibit's Restless and Snoop Dogg's Tha Last Meal in 2000, and released a second album in 2004, The New Testament, which peaked at number 72 on the Billboard 200.[4][5] Just Add Water followed in 2006, described by AllMusic: 'Just Add Water may not be the complete handbook to being a pimp, but it certainly is a kind of missive on the life of Suga Free'. The album saw Suga described as 'charismatic and funny, with a rapid- fire-yet-conversational style' by the Chicago Tribune.After a ten-year break Suga Free released the album The Resurrection on May 5, 2019.",
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_kS8CzzfNBdQS9IUp1qEg7hKyDlwQR_eN4',
    price: 50,
    coverImg: 'sugafree.jpeg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://genius.com/artists/Suga-free',
    youtube: 'https://www.youtube.com/channel/UCuwelhuzVSdFaAgOKJ_MPmg',
    facebook: 'https://www.facebook.com/staysugafree',
    instagram: 'https://www.instagram.com/explore/tags/sugafree/',
    twitter: 'https://twitter.com/SugaFreeWisdom',
    spotify: 'https://open.spotify.com/artist/7AjokpCIqf0gHMZJlodw2k',
    deezer: 'https://www.deezer.com/mx/artist/72509',
    apple: 'https://music.apple.com/mx/artist/suga-free/21838',
    soundcloud: 'https://soundcloud.com/suga-free',
    tidal: 'https://listen.tidal.com/artist/3534135',
    wiki: 'https://en.wikipedia.org/wiki/Suga_Free',
    ticketmaster:
      'https://www.ticketmaster.com/suga-free-tickets/artist/777374',
    tiktok: 'https://www.tiktok.com/search?q=sugafreethapimp&t=1661804342069',
    napster: 'https://music.amazon.com/artists/B000QKAUFE/suga-free',
  },
  {
    title: 'DJ Quick',
    description:
      "David Marvin Blake (born January 18, 1970), better known by his stage name DJ Quik, is an American rapper, songwriter, DJ, and record-producer,[3] known for his production in the G-funk style of West Coast hip-hop. Blake has collaborated with Snoop Dogg, Kurupt, Tupac, Erick Sermon, R.Kelly and Shaquille O'Neal, among others. Blake's stage name reflects his ability to produce songs in a short period of time.[4] Some of his top songs include 'Dollaz + Sense', 'Tonite', 'Born and Raised in Compton' and 'Jus Lyke Compton'.",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lU-yWJ9wEUVFwod8KaYeJwLSqFkYgYZCA',
    price: 50,
    coverImg: 'djquick.jpeg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://genius.com/artists/Dj-quik',
    youtube: 'https://www.youtube.com/channel/UCpurrvAebi8IPa57rXilrrQ',
    facebook: 'https://www.facebook.com/DJQuik',
    instagram: 'https://www.instagram.com/djq_uik/',
    twitter: 'https://twitter.com/djquik',
    spotify: 'https://open.spotify.com/artist/0b2XeWDPeBiLeskT6RFqMb',
    deezer: 'https://www.deezer.com/mx/artist/6658',
    apple: 'https://music.apple.com/mx/artist/dj-quik/184839847',
    soundcloud: 'https://soundcloud.com/djquikofficial',
    tidal: 'https://listen.tidal.com/artist/4475',
    wiki: 'https://en.wikipedia.org/wiki/DJ_Quik',
    ticketmaster: 'https://www.ticketmaster.com/dj-quik-tickets/artist/768266',
    tiktok: 'https://www.tiktok.com/search/video?q=dj%20quik&t=1661805469485',
    napster: 'https://music.amazon.com/artists/B000QJPQPO/dj-quik',
  },

  {
    title: 'Tupac',
    description:
      " Tupac Amaru Shakur born Lesane Parish Crooks, June 16, 1971 September 13, 1996), better known by his stage name 2Pac and later by his alias Makaveli, was an American rapper and actor. Considered one of the most influential rappers of all time, Shakur is among the best-selling music artists, having sold more than 75 million records worldwide. Much of Shakur's music has been noted for addressing contemporary social issues that plagued inner cities, and he is considered a symbol of activism against inequality.",
    videourl:
      'http://youtube.com/playlist?list=PL7E0ZBsP4bVllSIHvNTkSSIzrMcNr-VPX',
    price: 50,
    coverImg: 'tupac.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://2pac.com/us',
    youtube: 'https://www.youtube.com/channel/UCMIdeeBjp_60Jv7ROpRxK6Q',
    facebook: 'https://www.facebook.com/tupacshakur',
    instagram: 'https://www.instagram.com/2pac/',
    twitter:
      'https://twitter.com/Twitter.com/2pac?fbclid=IwAR1GL_5TpEQlaLwxEEzbv-y2gBTs13I6MQwx55yM3DwvFZONpiO8n02DW_k',
    spotify: 'https://open.spotify.com/artist/1ZwdS5xdxEREPySFridCfh',
    deezer: 'https://www.deezer.com/mx/artist/167095',
    apple: 'https://music.apple.com/mx/artist/2pac/105998',
    soundcloud: 'https://soundcloud.com/2pac',
    tidal: 'https://listen.tidal.com/artist/5192',
    wiki: 'https://es.wikipedia.org/wiki/Tupac_Shakur',
    ticketmaster: 'https://www.ticketmaster.com/2pac-tickets/artist/1115630',
    tiktok: 'https://www.tiktok.com/@thebest.2pac?lang=es',
    napster: 'https://music.amazon.com/artists/B000QKJMGW/2pac',
  },
  {
    title: '50 Cent',
    description:
      'Curtis James Jackson III (born July 6, 1975),[3] known professionally as 50 Cent, is an American rapper, actor, and businessman. Known for his impact in the hip hop industry, he has been described as a master of the nuanced art of lyrical brevity',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_n9MKCYPMnLA8rRoZ-Uo-O5015qhdV1qsQ',
    price: '$50',
    coverImg: '50cent.jpeg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://50cent.com/',
    youtube: 'https://www.youtube.com/50%2520cent',
    facebook:
      'https://www.facebook.com/50-Cent-The-Money-and-The-Power-31615082043',
    instagram: 'https://www.instagram.com/50centnewsdaily/',
    twitter: 'https://twitter.com/50cent',
    spotify: 'https://open.spotify.com/artist/3q7HBObVc0L8jNeTe5Gofh',
    deezer: 'https://www.deezer.com/mx/artist/66',
    apple: 'https://music.apple.com/mx/artist/50-cent/112058',
    soundcloud: 'https://soundcloud.com/50_cent',
    tidal: 'https://listen.tidal.com/artist/17548',
    wiki: 'https://en.wikipedia.org/wiki/50_Cent',
    ticketmaster: 'https://www.ticketmaster.com/50-cent-tickets/artist/713910',
    tiktok: 'https://www.tiktok.com/@50cent',
    napster: 'https://music.amazon.com/artists/B000QJO3TY/50-cent',
  },
  {
    title: 'Notorious B.I.G',
    description:
      'Christopher George Latore Wallace (Brooklyn, Nueva York; 21 de mayo de 1972 - Los Ángeles, California; 9 de marzo de 1997), más conocido por su nombre artístico The Notorious B.I.G., Biggie Smalls o simplemente Biggie, fue un rapero y productor estadounidense. Es considerado como uno de los raperos más importantes de todos los tiempos, y de los más influyentes de la historia del rap. ',
    videourl:
      'http://youtube.com/playlist?list=PLhWNDVRwYT0fW95BhmX0nENF232YNxRFI',
    price: '$50',
    coverImg: 'Biggie.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/The_Notorious_B.I.G.',
    youtube: 'https://www.youtube.com/channel/UCIZGqIYkST36dLp0uf9o9nw',
    facebook: 'https://www.facebook.com/NotoriousBIG/',
    instagram: 'https://www.instagram.com/thenotoriousbig/',
    twitter: 'https://twitter.com/BIGmemorial',
    spotify: 'https://open.spotify.com/artist/5me0Irg2ANcsgc93uaYrpb',
    deezer: 'https://www.deezer.com/mx/artist/2519',
    apple: 'https://music.apple.com/mx/artist/the-notorious-b-i-g/5499810',
    soundcloud: 'https://soundcloud.com/notoriousbig',
    tidal: 'https://listen.tidal.com/artist/3565368',
    wiki: 'https://es.wikipedia.org/wiki/The_Notorious_B.I.G.',
    ticketmaster:
      'https://www.ticketmaster.com/the-notorious-big-tickets/artist/2736',
    tiktok: 'https://www.tiktok.com/@thenotoriousbig?lang=es',
    napster: 'https://music.amazon.com/artists/B000QKN2BI/the-notorious-b-i-g',
  },
  {
    title: 'NWA',
    description:
      'N.W.A., fue un grupo estadounidense de hip hop de Compton, California, y está considerado de modo generalizado como el grupo pionero en la formación del subgénero musical gangsta rap..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nWOaU5oATownc2q3iPyUGH97FUdmnLa6M',
    price: '$50',
    coverImg: 'NWA.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.nwaworld.com/',
    youtube: 'https://www.youtube.com/channel/UC0o9KdLGWAu_-DUzorebz_A',
    facebook: 'https://www.facebook.com/NWAmusic',
    instagram: 'https://www.instagram.com/djspeednwa/',
    twitter: 'https://twitter.com/NwaRaps',
    spotify: 'https://open.spotify.com/artist/4EnEZVjo3w1cwcQYePccay',
    deezer: 'https://www.deezer.com/mx/artist/1004',
    apple: 'https://music.apple.com/mx/artist/n-w-a/569363',
    soundcloud: 'https://soundcloud.com/nwaofficial',
    tidal: 'https://listen.tidal.com/artist/9127',
    wiki: 'https://es.wikipedia.org/wiki/N.W.A.',
    ticketmaster: 'https://www.ticketmaster.com/nwa-tickets/artist/1153',
    tiktok: 'https://www.tiktok.com/@nwa.fans?lang=es',
    napster: 'https://music.amazon.com/artists/B000TD0DR8/n-w-a',
  },
  {
    title: 'Eazy E',
    description:
      'Eric Lynn Wright, más conocido por su nombre artístico Eazy-E, fue un cantante, productor y mánager estadounidense. Saltó a la fama con el grupo de gangsta rap N.W.A a finales de los 80. El estilo lírico y vocal de Eazy-E es reconocido al instante por su voz relativamente aguda y sus letras centradas en las armas, drogas, policía, actos violentos y sexo. Eazy-E es uno de los pioneros del gangsta rap y en la actualidad permanece como uno de los iconos más distintivos e influyentes del hip hop junto a 2pac y Notorious B.I.G..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_m-5NV2vIh2bwENXLHp5wsSNKtQJZJNc_Y',
    price: '$50',
    coverImg: 'EazyE.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.nwaworld.com/',
    youtube: 'https://www.youtube.com/channel/UC0o9KdLGWAu_-DUzorebz_A',
    facebook: 'https://www.facebook.com/EazyEMusic',
    instagram: 'https://www.instagram.com/eazye/',
    twitter: 'https://twitter.com/hashtag/EazyE?src=hashtag_click',
    spotify: 'https://open.spotify.com/artist/7B4hKK0S9QYnaoqa9OuwgX',
    deezer: 'https://www.deezer.com/mx/artist/12316',
    apple: 'https://music.apple.com/mx/artist/eazy-e/14852588',
    soundcloud: 'https://soundcloud.com/eazy-e-official',
    tidal: 'https://listen.tidal.com/artist/10854',
    wiki: 'https://es.wikipedia.org/wiki/Eazy-E',
    ticketmaster: 'https://www.ticketmaster.com/eazy-e-tickets/artist/898478',
    tiktok: 'https://www.tiktok.com/@king.eazye?lang=es',
    napster: 'https://music.amazon.com/artists/B000TD2BBY/eazy-e',
  },
  {
    title: 'Lil Wayne',
    description:
      "Dwayne Michael Carter Jr. (Nueva Orleans, Luisiana; 27 de septiembre de 1982), más conocido como Lil Wayne, es un rapero, cantante, compositor, productor y ejecutivo discográfico, actor y empresario estadounidense, ganador de cinco Grammys.A la temprana edad de nueve años, fue contratado por el sello discográfico Cash Money Records siendo el miembro más joven, para formar parte del dúo, The BG'z, junto con B.G, el rapero dice haberse sentido muy querido y que los considera sus hermanos mayores.",
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUeoGprkLGrwXGSzyW8WscD',
    price: '$50',
    coverImg: 'lilwayne.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.youngmoney.com/',
    youtube: 'https://www.youtube.com/channel/UCO9zJy7HWrIS3ojB4Lr7Yqw',
    facebook: 'https://www.facebook.com/LilWayne',
    instagram: 'https://www.instagram.com/liltunechi/',
    twitter: 'https://twitter.com/LilTunechi',
    spotify: 'https://open.spotify.com/artist/55Aa2cqylxrFIXC767Z865',
    deezer: 'https://www.deezer.com/mx/artist/74309',
    apple: 'https://music.apple.com/mx/artist/lil-wayne/5869117',
    soundcloud: 'https://soundcloud.com/lilwayne',
    tidal: 'https://listen.tidal.com/artist/27518',
    wiki: 'https://es.wikipedia.org/wiki/Lil_Wayne',
    ticketmaster:
      'https://www.ticketmaster.com/lil-wayne-tickets/artist/712214',
    tiktok: 'https://www.tiktok.com/@lilwayne?lang=es',
    napster: 'https://music.amazon.com/artists/B000S2BWRK/lil-wayne',
  },
  {
    title: 'Birdman',
    description:
      "Bryan Christopher Williams (nacido Brooks; 15 de febrero de 1969), más conocido por sus nombres artísticos Birdman o Baby, es un rapero y ejecutivo discográfico estadounidense. Es la cara pública del sello discográfico Cash Money Records, que cofundó con su hermano mayor Ronald Slim Williams en 1991. Birdman lanzó su álbum debut homónimo en 2002 y publicó tres álbumes posteriores—Fast Money (2005), 5 * Stunna (2007) y Priceless (2009)—con un éxito comercial moderado y una recepción crítica mixta; en 2020, anunció que el último sería su final. Junto con su carrera en solitario, es miembro del dúo de hip hop Big Tymers con el productor Mannie Fresh, así como del supergrupo Cash Money Millionaires..",
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_mp-Bu6oeeK__TzlDbjnM7TG006ki58mkc',
    price: '$50',
    coverImg: 'birdman.jpg',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.birdmanstunna.com/',
    youtube: 'https://www.youtube.com/channel/UCejvAdvNEvs8_w2XS0Wjntg',
    facebook: 'https://www.facebook.com/Birdman/',
    instagram: 'https://www.instagram.com/birdman/?hl=en',
    twitter: 'https://x.com/birdman5star',
    spotify: 'https://open.spotify.com/artist/35sCXuy5gN6Or69rZ9vqBs',
    deezer: 'https://www.deezer.com/mx/artist/14919',
    apple: 'https://music.apple.com/mx/artist/birdman/72812522',
    soundcloud: 'https://soundcloud.com/birdman-official',
    tidal: 'https://listen.tidal.com/artist/3502576',
    wiki: 'https://es.wikipedia.org/wiki/Birdman_(rapero)',
    ticketmaster:
      'https://www.ticketmaster.com/birdman-tickets/artist/851710',
    tiktok: 'https://www.tiktok.com/@burdma18?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000W0AFQC/birdman',
  },
  {
    title: 'Three 6 Mafia',
    description:
      "Three 6 Mafia (anteriormente conocido como Triple Six Mafia) es la primera agrupación musical de rap de Memphis, Tennessee , en ser platino así como nominado y ganador de un Óscar. La agrupación la conforman principalmente cuatro miembros: Paul Beauregard (DJ Paul), Jordan Houston (Juicy J), Lord Infamous y Crunchy Black..",
    videourl:
      'https://youtube.com/playlist?list=PLAwiZyNbH28X2o4-8fCGjljV2T6a5RwGo&si=F30--H3AATYRJnes',
    price: '$50',
    coverImg: 'three6.gif',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.three6mafiamerch.com/',
    youtube: 'https://www.youtube.com/channel/UCa-MexmcInkyWUzyyA3WUTw',
    facebook: 'https://www.facebook.com/three6mafia/',
    instagram: 'https://www.instagram.com/three6mafia/',
    twitter: 'https://x.com/three6mafialife',
    spotify: 'https://open.spotify.com/artist/26s8LSolLfCIY88ysQbIuT',
    deezer: 'https://www.deezer.com/mx/artist/291',
    apple: 'https://music.apple.com/mx/artist/three-6-mafia/1889116',
    soundcloud: 'https://soundcloud.com/three-6-mafia',
    tidal: 'https://listen.tidal.com/artist/6868',
    wiki: 'https://es.wikipedia.org/wiki/Three_6_Mafia',
    ticketmaster:
      'https://www.ticketmaster.com/three-6-mafia-tickets/artist/777957',
    tiktok: 'https://www.tiktok.com/@three6mafia?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJOGJ6/three-6-mafia',
  },
  {
    title: 'Juvenile',
    description:
      "Juvenile (Terius Gray, 25 de marzo de 1977 en Nueva Orleans, Luisiana) es un rapero estadounidense que a los 19 años fue de los pioneros del southern rap con su álbum Being Myself, en 1995. Con el éxito Bounce for the Juvenile dio nombre al estilo del sur conocido como Bounce",
    videourl:
      'https://youtube.com/playlist?list=PLs8_sLMd5PB9WrF3DhywVA-jtsFRklVt9&si=Yf6_KGWhmbfy6AyO',
    price: '$50',
    coverImg: 'juvi.png',
    category: 'Rap Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.juvenileofficial.com/',
    youtube: 'https://www.youtube.com/channel/UCSXRhf5E93q5bjELatiwk_w',
    facebook: 'https://www.facebook.com/juviethegreat/',
    instagram: 'https://www.instagram.com/juviethegreat',
    twitter: 'https://x.com/juviethegreat',
    spotify: 'https://open.spotify.com/artist/0rG0AZBscc8S8q1ahIsasI',
    deezer: 'https://www.deezer.com/mx/artist/3504',
    apple: 'https://music.apple.com/mx/artist/juvenile/1464606',
    soundcloud: 'https://soundcloud.com/juvenileofficial',
    tidal: 'https://listen.tidal.com/artist/15035',
    wiki: 'https://es.wikipedia.org/wiki/Juvenile',
    ticketmaster:
      'https://www.ticketmaster.com/juvenile-tickets/artist/769787',
    tiktok: 'https://www.tiktok.com/search?lang=es&q=Juvenile&t=1726285220649',
    napster: 'https://music.amazon.com.mx/artists/B000QJI4TO/juvenile',
  },
  {
    title: 'Baby Bash',
    description:
      'Baby Bash es el nombre artístico de Ronnie Ray Bryant, un rapero y cantante estadounidense. Es conocido por sus contribuciones a los géneros de hip-hop y rap latino. Baby Bash nació el 18 de octubre de 1975 en Vallejo, California. Ganó reconocimiento a principios de los años 2000 con éxitos como "Suga Suga" y "Cyclone.',
    videourl: 'https://youtube.com/playlist?list=OLAK5uy_lrPr95cVcuabXCSo9gOcTEzyyHxwa-OOM&si=Pf99gm1fd5_A__BC',
    price: '$50',
    coverImg: 'babybash.png',
    category: 'Hip Hop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://solo.to/babybash',
    youtube: 'https://www.youtube.com/channel/UC8Zk6x3hswEM4woanLVT7BA',
    facebook: 'https://www.facebook.com/babybash/',
    instagram: 'https://www.instagram.com/babybash/?hl=en',
    twitter: 'https://twitter.com/BabyBash?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
    spotify: 'https://open.spotify.com/artist/12PSlydMSjEHzSCj9X5qv7',
    deezer: 'https://www.deezer.com/mx/artist/5099',
    apple: 'https://music.apple.com/mx/artist/baby-bash/2013484',
    soundcloud: 'https://soundcloud.com/bash-town',
    tidal: 'https://listen.tidal.com/artist/32024',
    wiki:
      'https://es.wikipedia.org/wiki/Baby_Bash',
    ticketmaster:
      'https://www.ticketmaster.com/baby-bash-tickets/artist/895168',
    tiktok: 'https://www.tiktok.com/@babybashofficial?lang=en',
    napster: 'https://music.amazon.com.mx/artists/B000QK63G4/baby-bash',
  },
]
export default CardData23
